import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Breadcrumb,Button, message,Input,Select,DatePicker} from 'antd';
import AddOrEditRestrictedRuleDialog from './AddOrEditRestrictedRuleDialog'
import { Message } from 'semantic-ui-react'
import OperationRecordDetail from '../OperationRecordDetail';
const approvalOptions = [
  {code: 'Excellent', label: 'Excellent'},
  {code: 'Good', label: 'Good'},
  {code: 'Normal', label: 'Normal'},
  {code: 'Need Improve', label: 'Need Improve'},
]
class RestrictedSecurityRuleGrid extends Component {


  constructor(props) {
    super(props);
    const that = this;
    this.state = {
      columnDefs: [
        {
          field: 'title',
          headerName: "Title*",
          cellClass: 'non-number',
          width: 300,

          enableRowGroup: true,
          rowGroup: true,
          hide: true,
      },
        {
          field: 'coach',
          headerName: "Coach",
          cellClass: 'non-number',
          width: 200,
         
      },
      {
          field: 'participant',
          headerName: "Participant",
          cellClass: 'non-number',
          width: 150,
        
         
      },
      {
          field: 'initiation',
          headerName: "Initiation",
          cellClass: 'non-number',
          width: 150,
           editable: true,
          cellEditor: 'agRichSelectCellEditor',

          cellEditorParams: {
            values: approvalOptions.map(x => x.code),
          },
          valueFormatter: (params) => {
            
            if (typeof params.data == 'undefined' ) return '-'
            // if (!(String(params.data.transType) == '1' && params.data.diffAmount > 1) && !(String(params.data.transType) == '2' && params.data.diffShare > 1)) return '-'
            // return approvalOptions.find(x => x.code == (params.value ? params.value : '0')).label
          },
      },
      {
          field: 'communicationAndPersonality',
          headerName: "Communication&Personality",
          cellClass: 'non-number',
          width: 100,
          editable: true,
          cellEditor: 'agRichSelectCellEditor',

          cellEditorParams: {
            values: approvalOptions.map(x => x.code),
          },
          valueFormatter: (params) => {
            
            if (typeof params.data == 'undefined' ) return ''
            // if (!(String(params.data.transType) == '1' && params.data.diffAmount > 1) && !(String(params.data.transType) == '2' && params.data.diffShare > 1)) return '-'
            // return approvalOptions.find(x => x.code == (params.value ? params.value : '0')).label
          },
      },
      {
          field: 'attendence',
          headerName: "Attendence",
          cellClass: 'non-number',
          width: 100,
          editable: true,
          cellEditor: 'agRichSelectCellEditor',

          cellEditorParams: {
            values: approvalOptions.map(x => x.code),
          },
          valueFormatter: (params) => {
            
            if (typeof params.data == 'undefined' ) return ''
            // if (!(String(params.data.transType) == '1' && params.data.diffAmount > 1) && !(String(params.data.transType) == '2' && params.data.diffShare > 1)) return '-'
            // return approvalOptions.find(x => x.code == (params.value ? params.value : '0')).label
          },
      },
      {
        field: 'workResult',
        headerName: "WorkResult",
        cellClass: 'non-number',
        width: 200,
        editable: true,
        cellEditor: 'agRichSelectCellEditor',

        cellEditorParams: {
          values: approvalOptions.map(x => x.code),
        },
        valueFormatter: (params) => {
          
          if (typeof params.data == 'undefined' ) return ''
          // if (!(String(params.data.transType) == '1' && params.data.diffAmount > 1) && !(String(params.data.transType) == '2' && params.data.diffShare > 1)) return '-'
          // return approvalOptions.find(x => x.code == (params.value ? params.value : '0')).label
        },
      },
      {
          field: 'overallRanking',
          headerName: "OverallRanking",
          cellClass: 'non-number',
          width: 100,
          editable: true,

      },
      {
        field: 'remarks',
        headerName: "Remarks",
        cellClass: 'non-number',
        width: 200,
        editable: true,
      },

      
      
      ],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },

    
    selectedRow: null,
    groupDefaultExpanded: 1,
    rowGroupPanelShow: 'always',
    pivotPanelShow: 'always',
    sideBar: {
      toolPanels: ['columns', 'filters']
    },
    };
    
  }

  sendHistoryAttachementCellRender = (node) => {
    const attachement = node.data.filepath;
    if(!attachement) {
        return '';
    }
    const attArray = attachement.split(';');
    const retElement = document.createElement('div');
    let first = true;
    attArray.forEach(e => {
        if(!first) {
            retElement.append(' | ');
        }
        first = false;

        const url = this.props.rotationReviewDownloadUrl + '/' + e;
        const link = document.createElement('a');
        link.innerText = e;
        link.target = '_blank';
        link.href = url;
        retElement.appendChild(link);

    });
    return retElement;
}
  componentDidMount(){
    const {
      title,
      participant,
      state
      
      
  } = this.props.rotationReviewQueryFields;
  const person = participant;
  this.props.loadRotationScoreList({title,person,classType:'Rotation'})
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    // this.columnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  refreshData = () => {
    const {
      title,
      participant,
      type,
      startTime,
      endTime,
      speaker,

  } = this.props.rotationReviewQueryFields;
  const person = participant;
  this.props.loadRotationScoreList({title,person,classType:'Rotation'})
    // this.props.loadRestrictedRuleList({});
  }

  addBtnClick = () => {
    this.props.openRestrictedRuleDialog('NEW', {
      isFundBookMatched: 0
    });
  }

  updateBtnClick = () => {
    const {
      selectedRow
    } = this.state;
    if(selectedRow){
      this.props.openRestrictedRuleDialog('EDIT', selectedRow);
    }
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(rowNode => rowNode.data);

    this.setState({
      selectedRow: restrictedSecurityRequestArray[0]
    });
  }
  getRScore =(array) =>{
    if(array.length>0){
    let rArray = array.filter(r=>r.required==='M').reduce(function(prev, cur) {
      return parseInt(cur.score) + prev;
      }, 0);
      return rArray;
    }else{
      return 0;
    }
  
  };

  getOScore =(array) =>{
    if(array.length>0){
      let rArray = array.filter(r=>r.required==='O').reduce(function(prev, cur) {
        return parseInt(cur.score) + prev;
        }, 0);
        return rArray;
      }else{
        return 0;
      }
};
  onInputChange = ({ name, value }) => {
    this.props.onQueryInputChange({ name, value });
};
// 修改数据
onCellValueChanged = params => {
  const colId = params.column.getId();

  let trades = []
  trades.push(params.data)



  this.props.upRotationScore({scoreRecordList:trades});

  // this.props.loadRotationScoreList({title,person,type,startTime,endTime,speaker,classType:'Rotation'})
  // if (colId == 'remark1' && params.oldValue !== params.newValue) {
  //   this.props.batchUpsertPreliminaryTrades(trades);
  // }

}

  render() {
    const {
      restrictedRuleList,
      rotationScoreList,
      typeOptions,
      instituteUserAccountOptions,
      className = 'grid-wrapper'
    } = this.props;
    const {

      participant,

    } = this.props.rotationReviewQueryFields;
    const name = participant;
    
    const isShow = name !== null && name !== '';
    
    let filterRestrictedSecArray =restrictedRuleList.filter(rel=>rel.participant.includes(name));
    const detailShow = filterRestrictedSecArray.length > 0 ? true :false; 
    const rScore = this.getRScore(filterRestrictedSecArray);
    const oScore = this.getOScore(filterRestrictedSecArray);
    //style={{height: '100px'}}
    return (
      <div className='RotationReviewGrid'>
        <div className='operationBar'>
          <div className='breadcrumbWrapper'>   
            <Breadcrumb>
            <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Input 
              allowClear={true}
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              placeholder="Title"
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'title', value: event.target.value })
              }} 
          />
          <Select
                allowClear
                showSearch
                style={{ width: 200, marginLeft: 10 }}
                onChange={(val) => {
                    this.onInputChange({ name: 'participant', value: val })
                }} 
                placeholder="Select participant">
            {instituteUserAccountOptions}
        </Select>
          {/* <Select
              allowClear
              size='small'
              style={{ width: 150, marginLeft: 10 }}
              onChange={(val) => {
                  this.onInputChange({ name: 'type', value: val })
              }} 
              placeholder="type">
              {typeOptions}
          </Select>

          <DatePicker 
            allowClear={true}
            size='small'
            style={{ width: 150, marginLeft: 10 }}
            placeholder=" Date Start"
            onChange={(startTime, dateString) => {
              this.onInputChange({ name: 'startTime', value: dateString });
            }}
          />
          <DatePicker 
            allowClear={true}
            size='small'
            style={{ width: 150, marginLeft: 10 }}
            placeholder="Date End"
            onChange={(endTime, dateString) => {
              this.onInputChange({ name: 'endTime', value: dateString });
            }}
          /> 
          <Input 
              allowClear={true}
              placeholder="Speaker"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'speaker', value: event.target.value })
              }} 
          /> */}
          <Button 
              onClick={this.refreshData} 
              // loading={refreshBtnIsLoading}
              size='small'
              style={{ marginLeft: 10 }}
              type='primary'
              icon="search" 
          />
        </div>

        <div className='operationBar' >
          {/* {
            detailShow &&  */}
            {/* <Message negative>
              <Message.Header>{name} Mandatory Score- {rScore}, Optional Score- {oScore}.</Message.Header>
            </Message> */}
          {/* // } */}
            
        </div>

        <div className={`ag-theme-balham ${className}`} style={{height: '85%'}}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={rotationScoreList}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            onSelectionChanged={this.onSelectionChanged}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            groupDefaultExpanded={this.state.groupDefaultExpanded}
            onCellValueChanged={this.onCellValueChanged.bind(this)}
            onGridReady={this.onGridReady}
          />
        </div> 
        <OperationRecordDetail {...this.props} /> 
      </div>
    );
  }
}

export default RestrictedSecurityRuleGrid;
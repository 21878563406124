import React, { Component } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { Breadcrumb, Button, message, Input, Select, DatePicker } from "antd";
import { Loader } from "semantic-ui-react";
import { ACTION_STATUS } from "../../../../../utils/ActionStatus";
import moment from "moment";
class BrokerReviewSummary extends Component {
  constructor(props) {
    super(props);
    const that = this;
    this.state = {
      columnDefs: [
        {
          headerName: "Basic Data",
          children: [
            {
              field: "broker",
              headerName: "Broker*",
              cellClass: "non-number",
              width: 150,
              cellRenderer: "agGroupCellRenderer",
            },
            {
              field: "totalRecords",
              headerName: "Total Records",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "bespokeRecords",
              headerName: "Bespoke Records",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentBespoke",
              headerName: "% Bespoke",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "oneRecords",
              headerName: "OneRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentOne",
              headerName: "% One",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "groupRecords",
              headerName: "GroupRecords",
              hide: true,
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "percentGroup",
              headerName: "% Group",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "conferenceRecords",
              headerName: "conferenceRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentConference",
              headerName: "% Conference",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "othersRecords",
              headerName: "OthersRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentOthers",
              headerName: "% Others",
              cellClass: "non-number",
              width: 150,
            },
          ],
        },
        {
          headerName: "Weighted Summary Data.",
          children: [
            {
              field: "totalRecordsCoe",
              headerName: "WSD TotalRecords",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "bespokeRecordsCoe",
              headerName: "WSD BespokeRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentBespokeCoe",
              headerName: "%WSD Bespoke",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "oneRecordsCoe",
              headerName: "WSD OneRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentOneCoe",
              headerName: "%WSD One",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "groupRecords",
              headerName: "WSD GroupRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentGroupCoe",
              headerName: "% WSDGroup",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "conferenceRecordsCoe",
              headerName: "WSD conferenceRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentConferenceCoe",
              headerName: "% WSDConference",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "othersRecordsCoe",
              headerName: "WSD OthersRecords",
              cellClass: "non-number",
              hide: true,
              width: 150,
            },
            {
              field: "percentOthersCoe",
              headerName: "% WSDOthers",
              cellClass: "non-number",
              width: 150,
            },
          ],
        },
      ],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100,
      },
      rowSelection: "single",
      rowClassRules: {
        MarkBackgroundRede: function (params) {
          // if(params.data.status === 'New') {
          return (
            params.data.required === "M" &&
            params.data.progress.split("/")[0] === "0"
          );
          // } alertFlag  limitFlag  1 表示出警
        },
      },
      context: { componentParent: this },
      frameworkComponents: {},
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: "Basic Data",
              children: [
                {
                  field: "pinpointContact",
                  headerName: "Name*",
                  cellClass: "non-number",
                  width: 150,
                  cellRenderer: "agGroupCellRenderer",
                },
                {
                  field: "totalRecords",
                  headerName: "Total Records",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "bespokeRecords",
                  headerName: "Bespoke Records",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentBespoke",
                  headerName: "PercentBespoke",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "oneRecords",
                  headerName: "OneRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentOne",
                  headerName: "PercentOne",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "groupRecords",
                  headerName: "GroupRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentGroup",
                  headerName: "PercentGroup",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "conferenceRecords",
                  headerName: "conferenceRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentConference",
                  headerName: "PercentConference",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "othersRecords",
                  headerName: "OthersRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentOthers",
                  headerName: "PercentOthers",
                  cellClass: "non-number",
                  width: 150,
                },
              ],
            },
            {
              headerName: "Weighted Summary Data.",
              children: [
                {
                  field: "totalRecordsCoe",
                  headerName: "WSD TotalRecords",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "bespokeRecordsCoe",
                  headerName: "WSD BespokeRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentBespokeCoe",
                  headerName: "% WSDBespoke",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "oneRecordsCoe",
                  headerName: "WSD OneRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentOneCoe",
                  headerName: "% WSDOne",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "groupRecords",
                  headerName: "WSD GroupRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentGroupCoe",
                  headerName: "% WSDGroup",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "conferenceRecordsCoe",
                  headerName: "WSD conferenceRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentConferenceCoe",
                  headerName: "% WSDConference",
                  cellClass: "non-number",
                  width: 150,
                },
                {
                  field: "othersRecordsCoe",
                  headerName: "WSD OthersRecords",
                  cellClass: "non-number",
                  hide: true,
                  width: 150,
                },
                {
                  field: "percentOthersCoe",
                  headerName: "% WSDOthers",
                  cellClass: "non-number",
                  width: 150,
                },
              ],
            },
          ],
          suppressRowClickSelection: true,
          suppressAggFuncInHeader: true,
          enableColResize: true,
          enableSorting: true,
          enableFilter: true,
          animateRows: true,
          deltaRowDataMode: false,
          onSelectionChanged(event) {
            let rowNodeArray = event.api.getSelectedNodes();
            let cptRcdDataArray = rowNodeArray.map((rowNode) => rowNode.data);
            that.props.selectRotationReviewInfo(cptRcdDataArray);
          },
          onRowClicked(event) {
            const selectedRcd = event.data;
            that.props.doOperationRecordAction({
              action: "SHOW_DETAIL",
              detail: selectedRcd,
            });
          },
        },

        getDetailRowData: function (params) {
          params.successCallback(params.data.userList);
        },
        getRowNodeId: function (data) {
          return data.id;
        },
        template:
          '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
          '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">  Detail</div>' +
          '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
          "</div>",
      },
      selectedRow: null,
    };
  }

  sendHistoryAttachementCellRender = (node) => {
    const attachement = node.data.filepath;
    if (!attachement) {
      return "";
    }
    const attArray = attachement.split(";");
    const retElement = document.createElement("div");
    let first = true;
    attArray.forEach((e) => {
      if (!first) {
        retElement.append(" | ");
      }
      first = false;

      const url = this.props.rotationReviewDownloadUrl + "/" + e;
      const link = document.createElement("a");
      link.innerText = e;
      link.target = "_blank";
      link.href = url;
      retElement.appendChild(link);
    });
    return retElement;
  };
  componentDidMount() {
    const {
      pinPointContact,
      interactionPurpose,
      startTime,
      endTime,
      brokerCode,
    } = this.props.brokerReviewQueryFields;
    this.props.loadBrokerReviewIndividualList({
      selectType: "InteractionType",
      beginDate: startTime,
      endDate: endTime,
      brokerCode: undefined,
    });
  }

  getRowNodeId = (data) => {
    return data.id;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  refreshData = () => {
    const {
      pinPointContact,
      interactionPurpose,
      startTime,
      endTime,
      brokerCode,
    } = this.props.brokerReviewQueryFields;
    this.props.loadBrokerReviewIndividualList({
      selectType: "InteractionType",
      beginDate: startTime,
      endDate: endTime,
      brokerCode: undefined,
    });
  };

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(
      (rowNode) => rowNode.data
    );

    this.setState({
      selectedRow: restrictedSecurityRequestArray[0],
    });
  };

  onInputChange = ({ name, value }) => {
    if (name === "startTime" || name === "endTime") {
      value = moment(value);
    }
    this.props.onQueryInputChange({ name, value });
  };

  render() {
    const {
      loadBrokerReviewIndividualActionStatus,
      brokerReviewIndividualList,
      typeOptions,
      instituteUserAccountOptions,
      className = "grid-wrapper",
    } = this.props;
    const { startTime, endTime } = this.props.brokerReviewQueryFields;
    const isLoading = loadBrokerReviewIndividualActionStatus === ACTION_STATUS.LOGINING;

    //style={{height: '100px'}}
    return (
      <div className="RotationReviewGrid">
        <div className="operationBar">
          <div className="breadcrumbWrapper">
            <Breadcrumb>
              <Breadcrumb.Item> Search by:</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <Select
              allowClear
              showSearch
              size='small'

              style={{marginLeft: 10, width: 150}}
              onChange={(val) => {
                  this.onInputChange({ name: 'pinPointContact', value: val })
              }} 
              placeholder="UserName">
              {instituteUserAccountOptions}
          </Select>  */}
          <DatePicker
            allowClear={true}
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            placeholder=" Date Start"
            value={startTime}
            onChange={(startTime, dateString) => {
              this.onInputChange({ name: "startTime", value: dateString });
            }}
          />
          <DatePicker
            allowClear={true}
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            placeholder="Date End"
            value={endTime}
            onChange={(endTime, dateString) => {
              this.onInputChange({ name: "endTime", value: dateString });
            }}
          />

          <Button
            onClick={this.refreshData}
            loading={isLoading}
            size="small"
            style={{ marginLeft: 10 }}
            type="primary"
            icon="search"
          />
        </div>

        <div
          className={`ag-theme-balham ${className}`}
          style={{ height: "85%" }}
        >
        {isLoading ? (
          <Loader active content="Loading..." />
        ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={brokerReviewIndividualList}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
        </div>
      </div>
    );
  }
}

export default BrokerReviewSummary;

import React from "react";
import { Select } from "antd";
import { createReducer } from "../../../../utils/reducerUtil";
import { ACTION_STATUS } from "../../../../utils/ActionStatus";
import GridColumnMap from "./GridColumnMap";
import BaseUrlConfig from "../../../../net/BaseUrlConfig";
import UrlConfig from "../../../../net/UrlConfig";
import moment from "moment";
import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_USER_ACCESS_INFO,
  LOAD_USER_ACCESS_INFO_SUCCESS,
  LOAD_USER_ACCESS_INFO_FAILURE,
  INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
  // select
  SELECT_USER_ACCESS_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
  OPEN_ADD_OR_EDIT_EVENT_MODAL,
  ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
  START_ADD_OR_EDIT_USER_ACCESS,
  ADD_OR_EDIT_USER_ACCESS_SUCCESS,
  ADD_OR_EDIT_USER_ACCESS_FAILURE,
  INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
  RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_USER_ACCESS_MODAL,
  DEL_USER_ACCESS_CHANGE_INPUT,
  START_DEL_USER_ACCESS,
  DEL_USER_ACCESS_SUCCESS,
  DEL_USER_ACCESS_FAILURE,
  INIT_DEL_USER_ACCESS_ACTION_STATUS,
  RESET_DEL_USER_ACCESS_MODAL,

  // del
  OPEN_COP_USER_ACCESS_MODAL,
  COP_USER_ACCESS_CHANGE_INPUT,
  START_COP_USER_ACCESS,
  COP_USER_ACCESS_SUCCESS,
  COP_USER_ACCESS_FAILURE,
  INIT_COP_USER_ACCESS_ACTION_STATUS,
  RESET_COP_USER_ACCESS_MODAL,
  LOAD_TRAINING_SUB_INDIVIDUAL_SUCCESS,
  LOAD_TRAINING_SUB_INDIVIDUAL_FAILURE,
  LOAD_RESTRICTED_SECURITIES_PINPOINT_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_PINPOINT_FAILURE,
  LOAD_RESTRICTED_SECURITIES_PERSONAL_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_PERSONAL_FAILURE,
  OPEN_FINISH_TASK_MODAL,
  OPEN_OPERATION_RECORD_DIALOG,
  START_QUERY_OPERATION_RECORD,
  QUERY_OPERATION_RECORD_SUCESS,
  QUERY_OPERATION_RECORD_FAILURE,
  INIT_QUERY_OPERATION_RECORD_STATUS,
  RESET_OPERATION_RECORD_DIALOG,
  // detail
  SHOW_DETAIL_RCD,
  RESET_DETAIL_RCD,
  OPEN_RESTRICTED_RULE_DIALOG,
  CLOSE_RESTRICTED_RULE_DIALOG,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE
} from "./MeetingAndTrainingCalenderConstants";

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from "../../home/homeConstants";
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={"New"}>{"New"}</Option>);
statusOptions.push(<Option key={"Confirm"}>{"Confirm"}</Option>);
statusOptions.push(<Option key={"Feedback"}>{"Feedback"}</Option>);

const typeOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
typeOptions.push(<Option key={"Expert call"}>{"Expert call"}</Option>);
typeOptions.push(<Option key={"Analyst Meeting"}>{"Analyst Meeting"}</Option>);
typeOptions.push(
  <Option key={"Corporate Access"}>{"Corporate Access"}</Option>
);
typeOptions.push(<Option key={"Luncheon"}>{"Luncheon"}</Option>);

const optonalOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
optonalOptions.push(<Option key={"required"}>{"required"}</Option>);
optonalOptions.push(<Option key={"optonal"}>{"optonal"}</Option>);
// Expert call/ Analyst Meeting/ Corporate Access/ Luncheon

const brokerOptions = [];
brokerOptions.push(<Option key={"ACF PB"}>{"ACF PB"}</Option>);
brokerOptions.push(<Option key={"BCP"}>{"BCP"}</Option>);
brokerOptions.push(<Option key={"BINANCE"}>{"BINANCE"}</Option>);
brokerOptions.push(<Option key={"BMTB"}>{"BMTB"}</Option>);
brokerOptions.push(<Option key={"BNPI"}>{"BNPI"}</Option>);
brokerOptions.push(<Option key={"CACF PB"}>{"CACF PB"}</Option>);
brokerOptions.push(<Option key={"CCHL"}>{"CCHL"}</Option>);
brokerOptions.push(
  <Option key={"CHINA RENAISSANCE"}>{"CHINA RENAISSANCE"}</Option>
);
brokerOptions.push(<Option key={"CICC"}>{"CICC"}</Option>);
brokerOptions.push(<Option key={"CITI"}>{"CITI"}</Option>);
brokerOptions.push(<Option key={"CITIC"}>{"CITIC"}</Option>);
brokerOptions.push(<Option key={"CLSA"}>{"CLSA"}</Option>);
brokerOptions.push(<Option key={"CMS"}>{"CMS"}</Option>);
brokerOptions.push(<Option key={"COIN"}>{"COIN"}</Option>);
brokerOptions.push(<Option key={"CPTF PB"}>{"CPTF PB"}</Option>);
brokerOptions.push(<Option key={"CS"}>{"CS"}</Option>);
brokerOptions.push(<Option key={"DAIWA"}>{"DAIWA"}</Option>);
brokerOptions.push(<Option key={"DB LDN"}>{"DB LDN"}</Option>);
brokerOptions.push(<Option key={"DFZQ"}>{"DFZQ"}</Option>);
brokerOptions.push(<Option key={"DONGWU"}>{"DONGWU"}</Option>);
brokerOptions.push(<Option key={"DUMMY CPTY"}>{"DUMMY CPTY"}</Option>);
brokerOptions.push(<Option key={"DUMY"}>{"DUMY"}</Option>);
brokerOptions.push(<Option key={"EB"}>{"EB"}</Option>);
brokerOptions.push(<Option key={"EDEL"}>{"EDEL"}</Option>);
brokerOptions.push(<Option key={"EMKAY"}>{"EMKAY"}</Option>);
brokerOptions.push(<Option key={"ESHK"}>{"ESHK"}</Option>);
brokerOptions.push(<Option key={"FA_PCF"}>{"FA_PCF"}</Option>);
brokerOptions.push(<Option key={"FA_PLUS"}>{"FA_PLUS"}</Option>);
brokerOptions.push(<Option key={"FA_PMSF"}>{"FA_PMSF"}</Option>);
brokerOptions.push(<Option key={"FURONG"}>{"FURONG"}</Option>);
brokerOptions.push(<Option key={"GSIL"}>{"GSIL"}</Option>);
brokerOptions.push(<Option key={"GTJA"}>{"GTJA"}</Option>);
brokerOptions.push(<Option key={"GUANG FA"}>{"GUANG FA"}</Option>);
brokerOptions.push(<Option key={"GUOXIN"}>{"GUOXIN"}</Option>);
brokerOptions.push(<Option key={"HAITONG"}>{"HAITONG"}</Option>);
brokerOptions.push(<Option key={"HSBC FA"}>{"HSBC FA"}</Option>);
brokerOptions.push(<Option key={"HUATAI"}>{"HUATAI"}</Option>);
brokerOptions.push(<Option key={"IB"}>{"IB"}</Option>);
brokerOptions.push(<Option key={"INCA HK"}>{"INCA HK"}</Option>);
brokerOptions.push(<Option key={"ITG"}>{"ITG"}</Option>);
brokerOptions.push(<Option key={"JARDEN"}>{"JARDEN"}</Option>);
brokerOptions.push(<Option key={"JEFFERIES"}>{"JEFFERIES"}</Option>);
brokerOptions.push(<Option key={"JIAOYIN"}>{"JIAOYIN"}</Option>);
brokerOptions.push(<Option key={"JPM"}>{"JPM"}</Option>);
brokerOptions.push(<Option key={"KGI"}>{"KGI"}</Option>);
brokerOptions.push(<Option key={"KOTAK"}>{"KOTAK"}</Option>);
brokerOptions.push(<Option key={"LIQUIDNET"}>{"LIQUIDNET"}</Option>);
brokerOptions.push(<Option key={"MARL"}>{"MARL"}</Option>);
brokerOptions.push(<Option key={"MIZUHO"}>{"MIZUHO"}</Option>);
brokerOptions.push(<Option key={"ML"}>{"ML"}</Option>);
brokerOptions.push(<Option key={"ML EC 92707"}>{"ML EC 92707"}</Option>);
brokerOptions.push(<Option key={"MOTILAL"}>{"MOTILAL"}</Option>);
brokerOptions.push(<Option key={"MQ"}>{"MQ"}</Option>);
brokerOptions.push(<Option key={"MS"}>{"MS"}</Option>);
brokerOptions.push(<Option key={"MUFG"}>{"MUFG"}</Option>);
brokerOptions.push(<Option key={"NOMURA EC90997"}>{"NOMURA EC90997"}</Option>);
brokerOptions.push(
  <Option key={"NOMURA INTERNATIONAL PLC"}>{"NOMURA INTERNATIONAL PLC"}</Option>
);
brokerOptions.push(<Option key={"OPPENHEIMER"}>{"OPPENHEIMER"}</Option>);
brokerOptions.push(<Option key={"PB CLIENT MOVE"}>{"PB CLIENT MOVE"}</Option>);
brokerOptions.push(<Option key={"PCIL"}>{"PCIL"}</Option>);
brokerOptions.push(<Option key={"PTF PB"}>{"PTF PB"}</Option>);
brokerOptions.push(<Option key={"RELIGARE"}>{"RELIGARE"}</Option>);
brokerOptions.push(<Option key={"SC LOWY"}>{"SC LOWY"}</Option>);
brokerOptions.push(
  <Option key={"SEAPORT EC 96565"}>{"SEAPORT EC 96565"}</Option>
);
brokerOptions.push(<Option key={"SGEN"}>{"SGEN"}</Option>);
brokerOptions.push(<Option key={"SMBC"}>{"SMBC"}</Option>);
brokerOptions.push(<Option key={"SPARK"}>{"SPARK"}</Option>);
brokerOptions.push(<Option key={"SWHY"}>{"SWHY"}</Option>);
brokerOptions.push(<Option key={"TFIS"}>{"TFIS"}</Option>);
brokerOptions.push(<Option key={"TFS"}>{"TFS"}</Option>);
brokerOptions.push(<Option key={"UBS"}>{"UBS"}</Option>);
brokerOptions.push(<Option key={"VCM"}>{"VCM"}</Option>);
brokerOptions.push(<Option key={"YONGAN"}>{"YONGAN"}</Option>);
brokerOptions.push(<Option key={"Meeting"}>{"Meeting"}</Option>);

const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {
  queryParams: {
    start: moment().clone().add("month", 0).format("YYYY-MM"),
    monthTo: moment().format("YYYY-MM"),
    currentSelected: null,
  },
  meetingAndTrainingCalenderUploadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.USER_ACCESS_UPLOAD_URL,
  meetingAndTrainingCalenderDownloadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,
  meetingAndTrainingCalenderGridColumns:
    GridColumnMap.meetingAndTrainingCalenderGridColumns,

  statusOptions: statusOptions,
  typeOptions: typeOptions,
  brokerOptions: brokerOptions,
  fundFirmRelatedOptions: fundFirmRelatedOptions,
  ccyOptions: ccyOptions,
  optonalOptions: optonalOptions,

  meetingAndTrainingCalenderQueryFields: {
    userName: "",
    status: "",
    start: moment().add(-1, "month").startOf("month")
  },
  selectedMeetingAndTrainingCalenderInfoArray: [],
  addOrEditMeetingAndTrainingCalenderModal: {
    isOpened: false,
    mode: "ADD",
    respErrMsg: "",
    fields: {
      id: null,
      guid: "",
      category: "",
      firm: "",
      fundFirmRelated: "",
      invDate: "",
      invNo: "",
      ccy: "",
      amount: "",
      fx: "",
      amtUSD: "",
      payDate: "",
      nature: "",
      remarks: "",
      payMethod: "",
      comments: "",
    },
  },
  delMeetingAndTrainingCalenderModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
    },
  },
  copMeetingAndTrainingCalenderModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
      employee: null,
      comments: "",
    },
  },
  // Load User Account
  userAccountArray: [],
  //   userAccountOptions: [],

  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

  trainingSubIndividualList: [],
  restrictedPinPointList: [],
  restrictedPersonalList: [],
  selectedRestrictedRule: {},
  restrictedRuleEditControlModal: {
    isOpened: false,
    respErrMsg: null,
    isAddMode: null,
    isFundBookOpened: false,
    fields: {},
    fundBookFields: {},
  },

  finishTaskModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: undefined,
      remarks: undefined,
      comments: undefined,
    },
  },

  operationRecordDialogModal: {
    isOpened: false,
    queryFields: {
      module: "",
      dataId: "",
      operateTimeStart: "",
      operateTimeEnd: "",
    },
    customColumns: [],
    // detail
    isDetailOpened: false,
    detail: null,
  },
};

// query
function onQueryInputChange(state, { name, value }) {
  const upatedFields = {
    ...state.meetingAndTrainingCalenderQueryFields,
    [name]: value,
  };
  return {
    ...state,
    meetingAndTrainingCalenderQueryFields: upatedFields,
  };
}
function startLoadMeetingAndTrainingCalenderInfo(state) {
  return {
    ...state,
    loadMeetingAndTrainingCalenderInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}
function loadMeetingAndTrainingCalenderInfoSuccess(state, resp) {
  return {
    ...state,
    loadMeetingAndTrainingCalenderInfoActionStatus: ACTION_STATUS.SUCCESS,
    loadMeetingAndTrainingCalenderInfoResp: resp,
  };
}
function loadMeetingAndTrainingCalenderInfoFailure(state, err) {
  return {
    ...state,
    loadMeetingAndTrainingCalenderInfoActionStatus: ACTION_STATUS.ERROR,
    loadMeetingAndTrainingCalenderInfoResp: err,
  };
}
function initLoadMeetingAndTrainingCalenderInfoActionStatus(state) {
  return {
    ...state,
    loadMeetingAndTrainingCalenderInfoActionStatus: ACTION_STATUS.READY,
    selectedMeetingAndTrainingCalenderInfoArray: [],
  };
}

// select
function selectMeetingAndTrainingCalenderInfo(state, array) {
  return {
    ...state,
    selectedMeetingAndTrainingCalenderInfoArray: array,
  };
}

// add or edit
function openAddOrEditMeetingAndTrainingCalenderModal(state, { mode, data }) {
  if (mode === "ADD") {
    return {
      ...state,
      addOrEditMeetingAndTrainingCalenderModal: {
        ...state.addOrEditMeetingAndTrainingCalenderModal,
        isOpened: true,
        mode,
      },
    };
  } else {
    return {
      ...state,
      addOrEditMeetingAndTrainingCalenderModal: {
        ...state.addOrEditMeetingAndTrainingCalenderModal,
        isOpened: true,
        mode,
        fields: {
          ...state.addOrEditMeetingAndTrainingCalenderModal.fields,
          id: data.id,
          analyst: data.analyst,
          type: data.type,
          researchdepth: data.researchdepth,
          stockpitch: data.stockpitch,
          note: data.note,
        },
      },
    };
  }
}
function addOrEditMeetingAndTrainingCalenderChangeInput(
  state,
  { name, value }
) {
  const updatedFields = {
    ...state.addOrEditMeetingAndTrainingCalenderModal.fields,
    [name]: value,
  };
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderModal: {
      ...state.addOrEditMeetingAndTrainingCalenderModal,
      fields: updatedFields,
    },
  };
}
function startAddOrEditMeetingAndTrainingCalender(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderStatus: ACTION_STATUS.LOGINING,
  };
}
function addOrEditMeetingAndTrainingCalenderSuccess(state, resp) {
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderStatus: ACTION_STATUS.SUCCESS,
  };
}
function addOrEditMeetingAndTrainingCalenderFailure(state, err) {
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderStatus: ACTION_STATUS.ERROR,
    addOrEditMeetingAndTrainingCalenderModal: {
      ...state.addOrEditMeetingAndTrainingCalenderModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initAddOrEditMeetingAndTrainingCalenderActionStatus(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderStatus: ACTION_STATUS.READY,
  };
}
function resetAddOrEditMeetingAndTrainingCalenderModal(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderModal:
      initialState.addOrEditMeetingAndTrainingCalenderModal,
  };
}

// refresh fx and amtUSD
function startRefreshFx(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.LOGINING,
  };
}
function refreshFxSuccess(state, resp) {
  const fx = !!resp && !!resp.data && !!resp.data.last ? resp.data.last : "";
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.SUCCESS,
    addOrEditMeetingAndTrainingCalenderModal: {
      ...state.addOrEditMeetingAndTrainingCalenderModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingCalenderModal.fields,
        fx: fx,
      },
    },
  };
}
function refreshFxFailure(state, err) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.ERROR,
    refreshFxResp: err,
    addOrEditMeetingAndTrainingCalenderModal: {
      ...state.addOrEditMeetingAndTrainingCalenderModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingCalenderModal.fields,
        fx: "",
      },
    },
  };
}
function initRefreshFxActionStatus(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.READY,
  };
}
function refreshAmtUSD(state, amtUSD) {
  return {
    ...state,
    addOrEditMeetingAndTrainingCalenderModal: {
      ...state.addOrEditMeetingAndTrainingCalenderModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingCalenderModal.fields,
        amtUSD: amtUSD,
      },
    },
  };
}

// del
function openDelMeetingAndTrainingCalenderModal(state, { info, mode }) {
  return {
    ...state,
    delMeetingAndTrainingCalenderModal: {
      ...state.delMeetingAndTrainingCalenderModal,
      isOpened: true,
      mode: mode,
      fields: {
        ...state.delMeetingAndTrainingCalenderModal.fields,
        id: info.id,
      },
    },
  };
}
function delMeetingAndTrainingCalenderChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.delMeetingAndTrainingCalenderModal.fields,
    [name]: value,
  };
  return {
    ...state,
    delMeetingAndTrainingCalenderModal: {
      ...state.delMeetingAndTrainingCalenderModal,
      fields: updatedFields,
    },
  };
}
function startDelMeetingAndTrainingCalender(state) {
  return {
    ...state,
    delMeetingAndTrainingCalenderStatus: ACTION_STATUS.LOGINING,
  };
}
function delMeetingAndTrainingCalenderSuccess(state, resp) {
  return {
    ...state,
    delMeetingAndTrainingCalenderStatus: ACTION_STATUS.SUCCESS,
  };
}
function delMeetingAndTrainingCalenderFailure(state, err) {
  return {
    ...state,
    delMeetingAndTrainingCalenderStatus: ACTION_STATUS.ERROR,
    delMeetingAndTrainingCalenderModal: {
      ...state.delMeetingAndTrainingCalenderModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initDelMeetingAndTrainingCalenderActionStatus(state) {
  return {
    ...state,
    delMeetingAndTrainingCalenderStatus: ACTION_STATUS.READY,
  };
}
function resetDelMeetingAndTrainingCalenderModal(state) {
  return {
    ...state,
    delMeetingAndTrainingCalenderModal:
      initialState.delMeetingAndTrainingCalenderModal,
  };
}

// cop
function openCopMeetingAndTrainingCalenderModal(state, payload) {
  return {
    ...state,
    copMeetingAndTrainingCalenderModal: {
      ...state.copMeetingAndTrainingCalenderModal,
      isOpened: true,
      fields: {
        ...state.copMeetingAndTrainingCalenderModal.fields,
        id: payload.id,
      },
    },
  };
}
function copMeetingAndTrainingCalenderChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.copMeetingAndTrainingCalenderModal.fields,
    [name]: value,
  };
  return {
    ...state,
    copMeetingAndTrainingCalenderModal: {
      ...state.copMeetingAndTrainingCalenderModal,
      fields: updatedFields,
    },
  };
}
function startCopMeetingAndTrainingCalender(state) {
  return {
    ...state,
    copMeetingAndTrainingCalenderStatus: ACTION_STATUS.LOGINING,
  };
}
function copMeetingAndTrainingCalenderSuccess(state, resp) {
  return {
    ...state,
    copMeetingAndTrainingCalenderStatus: ACTION_STATUS.SUCCESS,
  };
}
function copMeetingAndTrainingCalenderFailure(state, err) {
  return {
    ...state,
    copMeetingAndTrainingCalenderStatus: ACTION_STATUS.ERROR,
    copMeetingAndTrainingCalenderModal: {
      ...state.copMeetingAndTrainingCalenderModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initCopMeetingAndTrainingCalenderActionStatus(state) {
  return {
    ...state,
    copMeetingAndTrainingCalenderStatus: ACTION_STATUS.READY,
  };
}
function resetCopMeetingAndTrainingCalenderModal(state) {
  return {
    ...state,
    copMeetingAndTrainingCalenderModal:
      initialState.copMeetingAndTrainingCalenderModal,
  };
}

// Load User Account
// function loadUserAccountsSuccess(state, resp) {
//     const userAccountArray = resp.data;
//     const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

//     return {
//       ...state,
//       userAccountArray,
//       userAccountOptions,
//     };
//   }

function loadTrainingSubIndividualListSuccess(state, resp) {
  return {
    ...state,
    trainingSubIndividualList: resp.data,
  };
}

function loadTrainingSubIndividualListFailure(state, err) {
  return {
    ...state,
  };
}

function loadRestrictedPinPointListSuccess(state, resp) {
  return {
    ...state,
    restrictedPinPointList: resp.data,
  };
}

function loadRestrictedPinPointListFailure(state, err) {
  return {
    ...state,
  };
}

function loadRestrictedPersonalListSuccess(state, resp) {
  return {
    ...state,
    restrictedPersonalList: resp.data,
  };
}

function loadRestrictedPersonalListFailure(state, err) {
  return {
    ...state,
  };
}

function addRestrictedRuleSuccess(state, resp) {
  return {
    ...state,
  };
}

function openFinishTaskModal(state, task) {
  return {
    ...state,
    finishTaskModal: {
      ...state.finishTaskModal,
      isOpened: true,
      fields: {
        ...state.finishTaskModal.fields,
        id: task.id,
        remarks: task.remarks,
      },
    },
  };
}

function openOperationRecordDialog(state, queryParams) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isOpened: true,
      queryFields: queryParams.queryFields,
      customColumns: queryParams.customColumns,
      id: queryParams.id,
    },
  };
}
function startQueryOperationRecord(state) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.LOGINING,
  };
}
function queryOperationRecordSuccess(state, resp) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.SUCCESS,
    queryOperationRecordResp: resp,
  };
}
function queryOperationRecordFailure(state, err) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.ERROR,
    queryOperationRecordResp: err,
  };
}
function initQueryOperationRecordStatus(state) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.READY,
  };
}
function resetOperationRecordDialog(state) {
  return {
    ...state,
    operationRecordDialogModal: initialState.operationRecordDialogModal,
  };
}

// detail
function showDetailRcd(state, detail) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isDetailOpened: true,
      detail: detail,
    },
  };
}
function resetDetailRcd(state) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isDetailOpened: false,
      detail: null,
    },
  };
}
function openRestrictedRuleDialog(state, { mode, detail }) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    mode,
    fields: detail,
    isOpened: true,
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields,
  };
}

function closeRestrictedRuleDialog(state, { mode }) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    mode,
    fields: {},
    isOpened: false,
    isFundBookOpened: false,
    fundBookFields: {},
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields,
  };
}

function loadInstituteUserAccountsSuccess(state, resp) {
  const instituteUserAccountArray = resp.data;
  const instituteUserAccountOptions = instituteUserAccountArray.map(
    (userAccount) => (
      <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
    )
  );

  return {
    ...state,
    instituteUserAccountArray,
    instituteUserAccountOptions,
  };
}

function loadInstituteUserAccountsFailure(state, err) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  [START_LOAD_USER_ACCESS_INFO]: startLoadMeetingAndTrainingCalenderInfo,
  [LOAD_USER_ACCESS_INFO_SUCCESS]: loadMeetingAndTrainingCalenderInfoSuccess,
  [LOAD_USER_ACCESS_INFO_FAILURE]: loadMeetingAndTrainingCalenderInfoFailure,
  [INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS]:
    initLoadMeetingAndTrainingCalenderInfoActionStatus,
  // select
  [SELECT_USER_ACCESS_INFO]: selectMeetingAndTrainingCalenderInfo,
  // add or edit
  [OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL]:
    openAddOrEditMeetingAndTrainingCalenderModal,
  [ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT]:
    addOrEditMeetingAndTrainingCalenderChangeInput,
  [START_ADD_OR_EDIT_USER_ACCESS]: startAddOrEditMeetingAndTrainingCalender,
  [ADD_OR_EDIT_USER_ACCESS_SUCCESS]: addOrEditMeetingAndTrainingCalenderSuccess,
  [ADD_OR_EDIT_USER_ACCESS_FAILURE]: addOrEditMeetingAndTrainingCalenderFailure,
  [INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS]:
    initAddOrEditMeetingAndTrainingCalenderActionStatus,
  [RESET_ADD_OR_EDIT_USER_ACCESS_MODAL]:
    resetAddOrEditMeetingAndTrainingCalenderModal,
  // refresh fx and amtUSD
  [START_REFRESH_FX]: startRefreshFx,
  [REFRESH_FX_SUCCESS]: refreshFxSuccess,
  [REFRESH_FX_FAILURE]: refreshFxFailure,
  [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
  [REFRESH_AMT_USD]: refreshAmtUSD,
  // del
  [OPEN_DEL_USER_ACCESS_MODAL]: openDelMeetingAndTrainingCalenderModal,
  [DEL_USER_ACCESS_CHANGE_INPUT]: delMeetingAndTrainingCalenderChangeInput,
  [START_DEL_USER_ACCESS]: startDelMeetingAndTrainingCalender,
  [DEL_USER_ACCESS_SUCCESS]: delMeetingAndTrainingCalenderSuccess,
  [DEL_USER_ACCESS_FAILURE]: delMeetingAndTrainingCalenderFailure,
  [INIT_DEL_USER_ACCESS_ACTION_STATUS]:
    initDelMeetingAndTrainingCalenderActionStatus,
  [RESET_DEL_USER_ACCESS_MODAL]: resetDelMeetingAndTrainingCalenderModal,

  // cop
  [OPEN_COP_USER_ACCESS_MODAL]: openCopMeetingAndTrainingCalenderModal,
  [COP_USER_ACCESS_CHANGE_INPUT]: copMeetingAndTrainingCalenderChangeInput,
  [START_COP_USER_ACCESS]: startCopMeetingAndTrainingCalender,
  [COP_USER_ACCESS_SUCCESS]: copMeetingAndTrainingCalenderSuccess,
  [COP_USER_ACCESS_FAILURE]: copMeetingAndTrainingCalenderFailure,
  [INIT_COP_USER_ACCESS_ACTION_STATUS]:
    initCopMeetingAndTrainingCalenderActionStatus,
  [RESET_COP_USER_ACCESS_MODAL]: resetCopMeetingAndTrainingCalenderModal,

  // Load User Account
  // [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  [LOAD_TRAINING_SUB_INDIVIDUAL_SUCCESS]: loadTrainingSubIndividualListSuccess,
  [LOAD_TRAINING_SUB_INDIVIDUAL_FAILURE]: loadTrainingSubIndividualListFailure,

  [LOAD_RESTRICTED_SECURITIES_PINPOINT_SUCCESS]:
    loadRestrictedPinPointListSuccess,
  [LOAD_RESTRICTED_SECURITIES_PINPOINT_FAILURE]:
    loadRestrictedPinPointListFailure,
  [LOAD_RESTRICTED_SECURITIES_PERSONAL_SUCCESS]:
    loadRestrictedPersonalListSuccess,
  [LOAD_RESTRICTED_SECURITIES_PERSONAL_FAILURE]:
    loadRestrictedPersonalListFailure,

  [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,

  [OPEN_OPERATION_RECORD_DIALOG]: openOperationRecordDialog,
  [START_QUERY_OPERATION_RECORD]: startQueryOperationRecord,
  [QUERY_OPERATION_RECORD_SUCESS]: queryOperationRecordSuccess,
  [QUERY_OPERATION_RECORD_FAILURE]: queryOperationRecordFailure,
  [INIT_QUERY_OPERATION_RECORD_STATUS]: initQueryOperationRecordStatus,
  [RESET_OPERATION_RECORD_DIALOG]: resetOperationRecordDialog,
  // detail
  [SHOW_DETAIL_RCD]: showDetailRcd,
  [RESET_DETAIL_RCD]: resetDetailRcd,

  [OPEN_RESTRICTED_RULE_DIALOG]: openRestrictedRuleDialog,
  [CLOSE_RESTRICTED_RULE_DIALOG]: closeRestrictedRuleDialog,

  [LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS]: loadInstituteUserAccountsSuccess,
  [LOAD_INSTITUTE_USER_ACCOUNT_FAILURE]: loadInstituteUserAccountsFailure,
});

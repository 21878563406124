import React from "react";
import { Select } from "antd";
import { createReducer } from "../../../../utils/reducerUtil";
import { ACTION_STATUS } from "../../../../utils/ActionStatus";
import GridColumnMap from "./GridColumnMap";
import BaseUrlConfig from "../../../../net/BaseUrlConfig";
import UrlConfig from "../../../../net/UrlConfig";
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from "../../../../utils/QuestionnaireEmailSendMode";
import moment from "moment";
import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_MEETING_AND_TRAINING_INFO,
  LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS,
  LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
  INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
  // select
  SELECT_MEETING_AND_TRAINING_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
  ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
  START_ADD_OR_EDIT_MEETING_AND_TRAINING,
  ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
  ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
  INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
  RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_MEETING_AND_TRAINING_MODAL,
  DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
  START_DEL_MEETING_AND_TRAINING,
  DEL_MEETING_AND_TRAINING_SUCCESS,
  DEL_MEETING_AND_TRAINING_FAILURE,
  INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
  RESET_DEL_MEETING_AND_TRAINING_MODAL,

  // del
  OPEN_COP_MEETING_AND_TRAINING_MODAL,
  COP_MEETING_AND_TRAINING_CHANGE_INPUT,
  START_COP_MEETING_AND_TRAINING,
  COP_MEETING_AND_TRAINING_SUCCESS,
  COP_MEETING_AND_TRAINING_FAILURE,
  INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
  RESET_COP_MEETING_AND_TRAINING_MODAL,
  ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
  ANSWEREE_SELECT_MODAL_DIALOG_RESET,

  // Select All Answeree
  SELECT_ALL_ANSWEREE,

  // Select Invert Answeree
  SELECT_INVERT_ANSWEREE,

  // Answeree Check Changed
  ANSWEREE_CHECK_CHANGED,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,

  // Select Answerees
  START_SELECT_ANSWEREES,
  SELECT_ANSWEREES_SUCCESS,
  SELECT_ANSWEREES_FAILURE,
  INIT_SELECT_ANSWEREES_ACTION_STATUS,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./MeetingAndTrainingGroupConstants";

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from "../../home/homeConstants";
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={"New"}>{"New"}</Option>);
statusOptions.push(<Option key={"Active"}>{"Active"}</Option>);
statusOptions.push(<Option key={"Inactive"}>{"Inactive"}</Option>);

const repeatOptions = [];
repeatOptions.push(<Option key={"Never"}>{"Never"}</Option>);
repeatOptions.push(<Option key={"Daily"}>{"Daily"}</Option>);
repeatOptions.push(<Option key={"Weekly"}>{"Weekly"}</Option>);
repeatOptions.push(<Option key={"Monthly"}>{"Monthly"}</Option>);

const repeatMonthlyOptions = [];
repeatMonthlyOptions.push(
  <Option key={"Repeat by date"}>{"Repeat by date"}</Option>
);
repeatMonthlyOptions.push(
  <Option key={"Repeat weekly"}>{"Repeat weekly"}</Option>
);

const repeatEveryOptions = [];
repeatEveryOptions.push(<Option key={"1"}>{"1"}</Option>);
repeatEveryOptions.push(<Option key={"2"}>{"2"}</Option>);
repeatEveryOptions.push(<Option key={"3"}>{"3"}</Option>);
repeatEveryOptions.push(<Option key={"4"}>{"4"}</Option>);
repeatEveryOptions.push(<Option key={"5"}>{"5"}</Option>);
repeatEveryOptions.push(<Option key={"6"}>{"6"}</Option>);
repeatEveryOptions.push(<Option key={"7"}>{"7"}</Option>);
repeatEveryOptions.push(<Option key={"8"}>{"8"}</Option>);
repeatEveryOptions.push(<Option key={"9"}>{"9"}</Option>);
repeatEveryOptions.push(<Option key={"10"}>{"10"}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={"7"}>{"Sun"}</Option>);
weekdayOptions.push(<Option key={"1"}>{"Mon"}</Option>);
weekdayOptions.push(<Option key={"2"}>{"Tue"}</Option>);
weekdayOptions.push(<Option key={"3"}>{"Wed"}</Option>);
weekdayOptions.push(<Option key={"4"}>{"Thu"}</Option>);
weekdayOptions.push(<Option key={"5"}>{"Fri"}</Option>);
weekdayOptions.push(<Option key={"6"}>{"Sat"}</Option>);

const typeOptions = [];
typeOptions.push(<Option key={"Meeting"}>{"Meeting"}</Option>);
typeOptions.push(<Option key={"Tranining"}>{"Tranining"}</Option>);
typeOptions.push(<Option key={"Other"}>{"Other"}</Option>);
const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {
  meetingAndTrainingGroupUploadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.MEETING_AND_TRAINING_UPLOAD_URL,
  meetingAndTrainingGroupDownloadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,
  meetingAndTrainingGroupGridColumns:
    GridColumnMap.meetingAndTrainingGroupGridColumns,

  statusOptions: statusOptions,
  typeOptions: typeOptions,
  weekdayOptions: weekdayOptions,
  repeatEveryOptions: repeatEveryOptions,
  repeatOptions: repeatOptions,
  repeatMonthlyOptions: repeatMonthlyOptions,
  fundFirmRelatedOptions: fundFirmRelatedOptions,
  ccyOptions: ccyOptions,

  meetingAndTrainingGroupQueryFields: {
    userName: "",
    status: "",
  },
  selectedMeetingAndTrainingGroupInfoArray: [],
  addOrEditMeetingAndTrainingGroupModal: {
    isOpened: false,
    mode: "ADD",
    respErrMsg: "",
    fields: {
      id: null,
      guid: "",
      category: "",
      firm: "",
      feedback: true,
      sendmail: false,
      fundFirmRelated: "",
      invDate: "",
      starttime: "",
      endtime: "",
      invNo: "",
      ccy: "",
      amount: "",
      fx: "",
      amtUSD: "",
      payDate: "",
      nature: "",
      remarks: "",
      payMethod: "",
      comments: "",
    },
  },
  delMeetingAndTrainingGroupModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
    },
  },
  copMeetingAndTrainingGroupModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
      employee: null,
      comments: "",
    },
  },
  // Load User Account
  userAccountArray: [],
  //   userAccountOptions: [],
  answereeSelectControlModal: {
    isOpened: false,
    respErrMsg: "",
  },

  userAccountArray: [],
  pmArray: [],
  pmGroupArray: [],
  userAccountOptions: [],
  // Open/Reset Questionnaire Email Edit Modal Dialog
  questionnaireEmailEditControlModal: {
    isOpened: false,
    sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
    emailPayload: null,
    respErrMsg: "",
  },
  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,
};

// query
function onQueryInputChange(state, { name, value }) {
  const upatedFields = {
    ...state.meetingAndTrainingGroupQueryFields,
    [name]: value,
  };
  return {
    ...state,
    meetingAndTrainingGroupQueryFields: upatedFields,
  };
}
function startLoadMeetingAndTrainingGroupInfo(state) {
  return {
    ...state,
    loadMeetingAndTrainingGroupInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}
function loadMeetingAndTrainingGroupInfoSuccess(state, resp) {
  return {
    ...state,
    loadMeetingAndTrainingGroupInfoActionStatus: ACTION_STATUS.SUCCESS,
    loadMeetingAndTrainingGroupInfoResp: resp,
  };
}
function loadMeetingAndTrainingGroupInfoFailure(state, err) {
  return {
    ...state,
    loadMeetingAndTrainingGroupInfoActionStatus: ACTION_STATUS.ERROR,
    loadMeetingAndTrainingGroupInfoResp: err,
  };
}
function initLoadMeetingAndTrainingGroupInfoActionStatus(state) {
  return {
    ...state,
    loadMeetingAndTrainingGroupInfoActionStatus: ACTION_STATUS.READY,
    selectedMeetingAndTrainingGroupInfoArray: [],
  };
}

// select
function selectMeetingAndTrainingGroupInfo(state, array) {
  const pmGroupArray = state.pmGroupArray;
  const answerArray = state.answerArray;

  // if (pmGroupArray.length > 0 && answerArray.length > 0 && array.length > 0) {
  //   pmGroupArray.forEach(pmArray => {
  //     pmArray.forEach(userAccount => {
  //       const filterAnswereeArray = answerArray.filter(answer => answer.questionnaireGuid === array[0].id).map(answer => answer.answeree);
  //       if (filterAnswereeArray.includes(userAccount.userAccountName)) {
  //         userAccount.checked = true;
  //       }
  //     })
  //   });
  // }

  return {
    ...state,
    selectedMeetingAndTrainingGroupInfoArray: array,
  };
}

// add or edit
function openAddOrEditMeetingAndTrainingGroupModal(state, { mode, data }) {
  if (mode === "ADD") {
    return {
      ...state,
      addOrEditMeetingAndTrainingGroupModal: {
        ...state.addOrEditMeetingAndTrainingGroupModal,
        isOpened: true,
        starttime: moment().format("YYYY-MM-DD HH:mm"),
        endtime: moment().format("YYYY-MM-DD HH:mm"),
        mode,
      },
    };
  } else {
    return {
      ...state,
      addOrEditMeetingAndTrainingGroupModal: {
        ...state.addOrEditMeetingAndTrainingGroupModal,
        isOpened: true,
        mode,
        fields: {
          ...state.addOrEditMeetingAndTrainingGroupModal.fields,
          id: data.id,
          title: data.title,
          desc: data.desc,
          type: data.type,
          feedback: data.feedback,
          groupname: data.groupname,
          accountname: data.accountname,
          sendmail: data.sendmail,
          comperename: data.comperename,
          starttime: data.starttime,
          endtime: data.endtime,
          score: data.score,
          address: data.address,
          requiredattendees: data.requiredattendees,
          optionalattendees: data.optionalattendees,
          repeat: data.repeat,
          repeatevery: data.repeatevery,
          week: data.week,
        },
      },
    };
  }
}
function addOrEditMeetingAndTrainingGroupChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.addOrEditMeetingAndTrainingGroupModal.fields,
    [name]: value,
  };
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupModal: {
      ...state.addOrEditMeetingAndTrainingGroupModal,
      fields: updatedFields,
    },
  };
}
function startAddOrEditMeetingAndTrainingGroup(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupStatus: ACTION_STATUS.LOGINING,
  };
}
function addOrEditMeetingAndTrainingGroupSuccess(state, resp) {
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupStatus: ACTION_STATUS.SUCCESS,
  };
}
function addOrEditMeetingAndTrainingGroupFailure(state, err) {
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupStatus: ACTION_STATUS.ERROR,
    addOrEditMeetingAndTrainingGroupModal: {
      ...state.addOrEditMeetingAndTrainingGroupModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initAddOrEditMeetingAndTrainingGroupActionStatus(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupStatus: ACTION_STATUS.READY,
  };
}
function resetAddOrEditMeetingAndTrainingGroupModal(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupModal:
      initialState.addOrEditMeetingAndTrainingGroupModal,
  };
}

// refresh fx and amtUSD
function startRefreshFx(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.LOGINING,
  };
}
function refreshFxSuccess(state, resp) {
  const fx = !!resp && !!resp.data && !!resp.data.last ? resp.data.last : "";
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.SUCCESS,
    addOrEditMeetingAndTrainingGroupModal: {
      ...state.addOrEditMeetingAndTrainingGroupModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingGroupModal.fields,
        fx: fx,
      },
    },
  };
}
function refreshFxFailure(state, err) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.ERROR,
    refreshFxResp: err,
    addOrEditMeetingAndTrainingGroupModal: {
      ...state.addOrEditMeetingAndTrainingGroupModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingGroupModal.fields,
        fx: "",
      },
    },
  };
}
function initRefreshFxActionStatus(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.READY,
  };
}
function refreshAmtUSD(state, amtUSD) {
  return {
    ...state,
    addOrEditMeetingAndTrainingGroupModal: {
      ...state.addOrEditMeetingAndTrainingGroupModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingGroupModal.fields,
        amtUSD: amtUSD,
      },
    },
  };
}

// del
function openDelMeetingAndTrainingGroupModal(state, { info, mode }) {
  return {
    ...state,
    delMeetingAndTrainingGroupModal: {
      ...state.delMeetingAndTrainingGroupModal,
      isOpened: true,
      mode: mode,
      fields: {
        ...state.delMeetingAndTrainingGroupModal.fields,
        id: info.id,
      },
    },
  };
}
function delMeetingAndTrainingGroupChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.delMeetingAndTrainingGroupModal.fields,
    [name]: value,
  };
  return {
    ...state,
    delMeetingAndTrainingGroupModal: {
      ...state.delMeetingAndTrainingGroupModal,
      fields: updatedFields,
    },
  };
}
function startDelMeetingAndTrainingGroup(state) {
  return {
    ...state,
    delMeetingAndTrainingGroupStatus: ACTION_STATUS.LOGINING,
  };
}
function delMeetingAndTrainingGroupSuccess(state, resp) {
  return {
    ...state,
    delMeetingAndTrainingGroupStatus: ACTION_STATUS.SUCCESS,
  };
}
function delMeetingAndTrainingGroupFailure(state, err) {
  return {
    ...state,
    delMeetingAndTrainingGroupStatus: ACTION_STATUS.ERROR,
    delMeetingAndTrainingGroupModal: {
      ...state.delMeetingAndTrainingGroupModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initDelMeetingAndTrainingGroupActionStatus(state) {
  return {
    ...state,
    delMeetingAndTrainingGroupStatus: ACTION_STATUS.READY,
  };
}
function resetDelMeetingAndTrainingGroupModal(state) {
  return {
    ...state,
    delMeetingAndTrainingGroupModal:
      initialState.delMeetingAndTrainingGroupModal,
  };
}

// cop
function openCopMeetingAndTrainingGroupModal(state, payload) {
  return {
    ...state,
    copMeetingAndTrainingGroupModal: {
      ...state.copMeetingAndTrainingGroupModal,
      isOpened: true,
      fields: {
        ...state.copMeetingAndTrainingGroupModal.fields,
        id: payload.id,
      },
    },
  };
}
function copMeetingAndTrainingGroupChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.copMeetingAndTrainingGroupModal.fields,
    [name]: value,
  };
  return {
    ...state,
    copMeetingAndTrainingGroupModal: {
      ...state.copMeetingAndTrainingGroupModal,
      fields: updatedFields,
    },
  };
}
function startCopMeetingAndTrainingGroup(state) {
  return {
    ...state,
    copMeetingAndTrainingGroupStatus: ACTION_STATUS.LOGINING,
  };
}
function copMeetingAndTrainingGroupSuccess(state, resp) {
  return {
    ...state,
    copMeetingAndTrainingGroupStatus: ACTION_STATUS.SUCCESS,
  };
}
function copMeetingAndTrainingGroupFailure(state, err) {
  return {
    ...state,
    copMeetingAndTrainingGroupStatus: ACTION_STATUS.ERROR,
    copMeetingAndTrainingGroupModal: {
      ...state.copMeetingAndTrainingGroupModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initCopMeetingAndTrainingGroupActionStatus(state) {
  return {
    ...state,
    copMeetingAndTrainingGroupStatus: ACTION_STATUS.READY,
  };
}
function resetCopMeetingAndTrainingGroupModal(state) {
  return {
    ...state,
    copMeetingAndTrainingGroupModal:
      initialState.copMeetingAndTrainingGroupModal,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  let pmArray = [];
  let pmGroupArray = [];
  let rowSize = 4;
  if (userAccountArray.length > 0) {
    pmArray = userAccountArray
      .filter((userAccount) => !!userAccount.isActive)
      .filter((userAccount) => userAccount.firm.toUpperCase() === "PINPOINT")
      .map((userAccount) => Object.assign({}, userAccount, { checked: false }));

    const groupSize =
      Math.floor(pmArray.length / rowSize) +
      (pmArray.length % rowSize > 0 ? 1 : 0);
    for (let index = 0; index < groupSize; index++) {
      const array = pmArray.slice(index * rowSize, (index + 1) * rowSize);
      pmGroupArray.push(array);
    }
  }

  // 已经存在的answeree 默认勾选
  const answerArray = state.answerArray;
  const selectedQuestionnaires = state.selectedQuestionnaires;

  const userAccountOptions = userAccountArray.map((userAccount) => (
    <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
  ));

  return {
    ...state,
    userAccountArray,
    pmArray,
    pmGroupArray,
    userAccountOptions,
  };
}
// Open/Reset Answeree Select Modal Dialog
function openAnswereeSelectModalDialog(state) {
  return {
    ...state,
    answereeSelectControlModal: {
      ...state.answereeSelectControlModal,
      isOpened: true,
      fields: {
        ...state.answereeSelectControlModal.fields,
      },
    },
  };
}

function resetAnswereeSelectModalDialog(state) {
  const pmGroupArray = state.pmGroupArray;

  // 重置答题人勾选项
  pmGroupArray.forEach((pmArray) => {
    pmArray.forEach((userAccount) => {
      userAccount.checked = false;
    });
  });

  return {
    ...state,
    answereeSelectControlModal: initialState.answereeSelectControlModal,
    pmGroupArray,
  };
}
// Select All Answeree
function selectAllAnsweree(state) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach((pmArray) => {
    pmArray.forEach((userAccount) => {
      userAccount.checked = true;
    });
  });

  return {
    ...state,
    pmGroupArray,
  };
}

// Select Invert Answeree
function selectInvertAnsweree(state) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach((pmArray) => {
    pmArray.forEach((userAccount) => {
      userAccount.checked = !userAccount.checked;
    });
  });

  return {
    ...state,
    pmGroupArray,
  };
}

// Answeree Check Changed
function answereeCheckChanged(state, { checked, userAccountName }) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach((pmArray) => {
    pmArray.forEach((userAccount) => {
      if (userAccount.userAccountName === userAccountName) {
        userAccount.checked = checked;
      }
    });
  });

  return {
    ...state,
  };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
function openQuestionnaireEmailEditModalDialog(
  state,
  { sendMode, emailPayload }
) {
  return {
    ...state,
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      isOpened: true,
      sendMode,
      emailPayload,
    },
  };
}

function resetQuestionnaireEmailEditModalDialog(state) {
  return {
    ...state,
    questionnaireEmailEditControlModal:
      initialState.questionnaireEmailEditControlModal,
  };
}

// Select Answerees
function startSelectAnswerees(state) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function selectAnswereesSuccess(state, resp) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function selectAnswereesFailure(state, err) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.ERROR,
    answereeSelectControlModal: {
      ...state.answereeSelectControlModal,
      respErrMsg: err.respMessage,
    },
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initSelectAnswereesActionStatus(state) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.READY,
  };
}

function loadInstituteUserAccountsSuccess(state, resp) {
  const instituteUserAccountArray = resp.data;
  const instituteUserAccountOptions = instituteUserAccountArray.map(
    (userAccount) => (
      <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
    )
  );

  return {
    ...state,
    instituteUserAccountArray,
    instituteUserAccountOptions,
  };
}

function loadInstituteUserAccountsFailure(state, err) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  [START_LOAD_MEETING_AND_TRAINING_INFO]: startLoadMeetingAndTrainingGroupInfo,
  [LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS]:
    loadMeetingAndTrainingGroupInfoSuccess,
  [LOAD_MEETING_AND_TRAINING_INFO_FAILURE]:
    loadMeetingAndTrainingGroupInfoFailure,
  [INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS]:
    initLoadMeetingAndTrainingGroupInfoActionStatus,
  // select
  [SELECT_MEETING_AND_TRAINING_INFO]: selectMeetingAndTrainingGroupInfo,
  // add or edit
  [OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL]:
    openAddOrEditMeetingAndTrainingGroupModal,
  [ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT]:
    addOrEditMeetingAndTrainingGroupChangeInput,
  [START_ADD_OR_EDIT_MEETING_AND_TRAINING]:
    startAddOrEditMeetingAndTrainingGroup,
  [ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS]:
    addOrEditMeetingAndTrainingGroupSuccess,
  [ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE]:
    addOrEditMeetingAndTrainingGroupFailure,
  [INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS]:
    initAddOrEditMeetingAndTrainingGroupActionStatus,
  [RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL]:
    resetAddOrEditMeetingAndTrainingGroupModal,
  // refresh fx and amtUSD
  [START_REFRESH_FX]: startRefreshFx,
  [REFRESH_FX_SUCCESS]: refreshFxSuccess,
  [REFRESH_FX_FAILURE]: refreshFxFailure,
  [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
  [REFRESH_AMT_USD]: refreshAmtUSD,
  // del
  [OPEN_DEL_MEETING_AND_TRAINING_MODAL]: openDelMeetingAndTrainingGroupModal,
  [DEL_MEETING_AND_TRAINING_CHANGE_INPUT]:
    delMeetingAndTrainingGroupChangeInput,
  [START_DEL_MEETING_AND_TRAINING]: startDelMeetingAndTrainingGroup,
  [DEL_MEETING_AND_TRAINING_SUCCESS]: delMeetingAndTrainingGroupSuccess,
  [DEL_MEETING_AND_TRAINING_FAILURE]: delMeetingAndTrainingGroupFailure,
  [INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS]:
    initDelMeetingAndTrainingGroupActionStatus,
  [RESET_DEL_MEETING_AND_TRAINING_MODAL]: resetDelMeetingAndTrainingGroupModal,

  // cop
  [OPEN_COP_MEETING_AND_TRAINING_MODAL]: openCopMeetingAndTrainingGroupModal,
  [COP_MEETING_AND_TRAINING_CHANGE_INPUT]:
    copMeetingAndTrainingGroupChangeInput,
  [START_COP_MEETING_AND_TRAINING]: startCopMeetingAndTrainingGroup,
  [COP_MEETING_AND_TRAINING_SUCCESS]: copMeetingAndTrainingGroupSuccess,
  [COP_MEETING_AND_TRAINING_FAILURE]: copMeetingAndTrainingGroupFailure,
  [INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS]:
    initCopMeetingAndTrainingGroupActionStatus,
  [RESET_COP_MEETING_AND_TRAINING_MODAL]: resetCopMeetingAndTrainingGroupModal,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
  [ANSWEREE_SELECT_MODAL_DIALOG_OPEN]: openAnswereeSelectModalDialog,
  [ANSWEREE_SELECT_MODAL_DIALOG_RESET]: resetAnswereeSelectModalDialog,
  // Answeree Check Changed
  [ANSWEREE_CHECK_CHANGED]: answereeCheckChanged,
  // Select All Answeree
  [SELECT_ALL_ANSWEREE]: selectAllAnsweree,

  // Select Invert Answeree
  [SELECT_INVERT_ANSWEREE]: selectInvertAnsweree,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN]:
    openQuestionnaireEmailEditModalDialog,
  [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET]:
    resetQuestionnaireEmailEditModalDialog,

  // Select Answerees
  [START_SELECT_ANSWEREES]: startSelectAnswerees,
  [SELECT_ANSWEREES_SUCCESS]: selectAnswereesSuccess,
  [SELECT_ANSWEREES_FAILURE]: selectAnswereesFailure,
  [INIT_SELECT_ANSWEREES_ACTION_STATUS]: initSelectAnswereesActionStatus,

  [LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS]: loadInstituteUserAccountsSuccess,
  [LOAD_INSTITUTE_USER_ACCOUNT_FAILURE]: loadInstituteUserAccountsFailure,
});

import React from "react";
import { Select } from "antd";
import { createReducer } from "../../../../utils/reducerUtil";
import { ACTION_STATUS } from "../../../../utils/ActionStatus";
import GridColumnMap from "./GridColumnMap";
import BaseUrlConfig from "../../../../net/BaseUrlConfig";
import UrlConfig from "../../../../net/UrlConfig";
import moment from "moment";
import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_ROTATION_INFO,
  LOAD_ROTATION_INFO_SUCCESS,
  LOAD_ROTATION_INFO_FAILURE,
  INIT_LOAD_ROTATION_INFO_ACTION_STATUS,
  // select
  SELECT_ROTATION_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_ROTATION_MODAL,
  ADD_OR_EDIT_ROTATION_CHANGE_INPUT,
  START_ADD_OR_EDIT_ROTATION,
  ADD_OR_EDIT_ROTATION_SUCCESS,
  ADD_OR_EDIT_ROTATION_FAILURE,
  INIT_ADD_OR_EDIT_ROTATION_ACTION_STATUS,
  RESET_ADD_OR_EDIT_ROTATION_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_ROTATION_MODAL,
  DEL_ROTATION_CHANGE_INPUT,
  START_DEL_ROTATION,
  DEL_ROTATION_SUCCESS,
  DEL_ROTATION_FAILURE,
  INIT_DEL_ROTATION_ACTION_STATUS,
  RESET_DEL_ROTATION_MODAL,

  // del
  OPEN_COP_ROTATION_MODAL,
  COP_ROTATION_CHANGE_INPUT,
  START_COP_ROTATION,
  COP_ROTATION_SUCCESS,
  COP_ROTATION_FAILURE,
  INIT_COP_ROTATION_ACTION_STATUS,
  RESET_COP_ROTATION_MODAL,
  LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,
  LOAD_ROTATION_SCORE_FAILURE,
  LOAD_ROTATION_SCORE_SUCCESS,
  UPDATE_ROTATION_SCORE_FAILURE,
  UPDATE_ROTATION_SCORE_SUCCESS,
  OPEN_FINISH_TASK_MODAL,
  OPEN_OPERATION_RECORD_DIALOG,
  START_QUERY_OPERATION_RECORD,
  QUERY_OPERATION_RECORD_SUCESS,
  QUERY_OPERATION_RECORD_FAILURE,
  INIT_QUERY_OPERATION_RECORD_STATUS,
  RESET_OPERATION_RECORD_DIALOG,
  // detail
  SHOW_DETAIL_RCD,
  RESET_DETAIL_RCD,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./RotationReviewConstants";

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from "../../home/homeConstants";
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={"New"}>{"New"}</Option>);
statusOptions.push(<Option key={"Confirm"}>{"Confirm"}</Option>);
statusOptions.push(<Option key={"Feedback"}>{"Feedback"}</Option>);

const typeOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
typeOptions.push(<Option key={"CIO Tutorial"}>{"CIO Tutorial"}</Option>);
typeOptions.push(
  <Option key={"Sell Side Sector Training"}>
    {"Sell Side Sector Training"}
  </Option>
);
typeOptions.push(<Option key={"Group Training"}>{"Group Training"}</Option>);
typeOptions.push(<Option key={"Modeling Class"}>{"Modeling Class"}</Option>);
typeOptions.push(
  <Option key={"Macro and Strategy Training"}>
    {"Macro and Strategy Training"}
  </Option>
);
typeOptions.push(
  <Option key={"Buy Side Sharing"}>{"Buy Side Sharing"}</Option>
);
typeOptions.push(
  <Option key={"Sale Side Meeting"}>{"Sale Side Meeting"}</Option>
);
typeOptions.push(<Option key={"Other"}>{"Other"}</Option>);

const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {
  rotationReviewUploadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.ROTATION_UPLOAD_URL,
  rotationReviewDownloadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,
  rotationReviewGridColumns: GridColumnMap.rotationReviewGridColumns,

  statusOptions: statusOptions,
  typeOptions: typeOptions,
  fundFirmRelatedOptions: fundFirmRelatedOptions,
  ccyOptions: ccyOptions,

  rotationReviewQueryFields: {
    userName: "",
    status: "",
    startTime: moment().startOf("year")
  },
  selectedRotationReviewInfoArray: [],
  addOrEditRotationReviewModal: {
    isOpened: false,
    mode: "ADD",
    respErrMsg: "",
    fields: {
      id: null,
      guid: "",
      category: "",
      firm: "",
      fundFirmRelated: "",
      invDate: "",
      invNo: "",
      ccy: "",
      amount: "",
      fx: "",
      amtUSD: "",
      payDate: "",
      nature: "",
      remarks: "",
      payMethod: "",
      comments: "",
    },
  },
  delRotationReviewModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
    },
  },
  copRotationReviewModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
      employee: null,
      comments: "",
    },
  },
  // Load User Account
  userAccountArray: [],
  //   userAccountOptions: [],

  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

  restrictedRuleList: [],
  selectedRestrictedRule: {
    rotationScoreList: [],
  },

  finishTaskModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: undefined,
      remarks: undefined,
      comments: undefined,
    },
  },

  operationRecordDialogModal: {
    isOpened: false,
    queryFields: {
      module: "",
      dataId: "",
      operateTimeStart: "",
      operateTimeEnd: "",
    },
    customColumns: [],
    // detail
    isDetailOpened: false,
    detail: null,
  },
};

// query
function onQueryInputChange(state, { name, value }) {
  const upatedFields = {
    ...state.rotationReviewQueryFields,
    [name]: value,
  };
  return {
    ...state,
    rotationReviewQueryFields: upatedFields,
  };
}
function startLoadRotationReviewInfo(state) {
  return {
    ...state,
    loadRotationReviewInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}
function loadRotationReviewInfoSuccess(state, resp) {
  return {
    ...state,
    loadRotationReviewInfoActionStatus: ACTION_STATUS.SUCCESS,
    loadRotationReviewInfoResp: resp,
  };
}
function loadRotationReviewInfoFailure(state, err) {
  return {
    ...state,
    loadRotationReviewInfoActionStatus: ACTION_STATUS.ERROR,
    loadRotationReviewInfoResp: err,
  };
}
function initLoadRotationReviewInfoActionStatus(state) {
  return {
    ...state,
    loadRotationReviewInfoActionStatus: ACTION_STATUS.READY,
    selectedRotationReviewInfoArray: [],
  };
}

// select
function selectRotationReviewInfo(state, array) {
  return {
    ...state,
    selectedRotationReviewInfoArray: array,
  };
}

// add or edit
function openAddOrEditRotationReviewModal(state, { mode, data }) {
  if (mode === "ADD") {
    return {
      ...state,
      addOrEditRotationReviewModal: {
        ...state.addOrEditRotationReviewModal,
        isOpened: true,
        mode,
      },
    };
  } else {
    return {
      ...state,
      addOrEditRotationReviewModal: {
        ...state.addOrEditRotationReviewModal,
        isOpened: true,
        mode,
        fields: {
          ...state.addOrEditRotationReviewModal.fields,
          id: data.id,
          cpty: data.cpty,
          functiontype: data.functiontype,
          usertype: data.usertype,
          fundandcompany: data.fundandcompany,
          employee: data.employee,
          status: data.status,

          remarks: data.remarks,
        },
      },
    };
  }
}
function addOrEditRotationReviewChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.addOrEditRotationReviewModal.fields,
    [name]: value,
  };
  return {
    ...state,
    addOrEditRotationReviewModal: {
      ...state.addOrEditRotationReviewModal,
      fields: updatedFields,
    },
  };
}
function startAddOrEditRotationReview(state) {
  return {
    ...state,
    addOrEditRotationReviewStatus: ACTION_STATUS.LOGINING,
  };
}
function addOrEditRotationReviewSuccess(state, resp) {
  return {
    ...state,
    addOrEditRotationReviewStatus: ACTION_STATUS.SUCCESS,
  };
}
function addOrEditRotationReviewFailure(state, err) {
  return {
    ...state,
    addOrEditRotationReviewStatus: ACTION_STATUS.ERROR,
    addOrEditRotationReviewModal: {
      ...state.addOrEditRotationReviewModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initAddOrEditRotationReviewActionStatus(state) {
  return {
    ...state,
    addOrEditRotationReviewStatus: ACTION_STATUS.READY,
  };
}
function resetAddOrEditRotationReviewModal(state) {
  return {
    ...state,
    addOrEditRotationReviewModal: initialState.addOrEditRotationReviewModal,
  };
}

// refresh fx and amtUSD
function startRefreshFx(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.LOGINING,
  };
}
function refreshFxSuccess(state, resp) {
  const fx = !!resp && !!resp.data && !!resp.data.last ? resp.data.last : "";
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.SUCCESS,
    addOrEditRotationReviewModal: {
      ...state.addOrEditRotationReviewModal,
      fields: {
        ...state.addOrEditRotationReviewModal.fields,
        fx: fx,
      },
    },
  };
}
function refreshFxFailure(state, err) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.ERROR,
    refreshFxResp: err,
    addOrEditRotationReviewModal: {
      ...state.addOrEditRotationReviewModal,
      fields: {
        ...state.addOrEditRotationReviewModal.fields,
        fx: "",
      },
    },
  };
}
function initRefreshFxActionStatus(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.READY,
  };
}
function refreshAmtUSD(state, amtUSD) {
  return {
    ...state,
    addOrEditRotationReviewModal: {
      ...state.addOrEditRotationReviewModal,
      fields: {
        ...state.addOrEditRotationReviewModal.fields,
        amtUSD: amtUSD,
      },
    },
  };
}

// del
function openDelRotationReviewModal(state, { info, mode }) {
  return {
    ...state,
    delRotationReviewModal: {
      ...state.delRotationReviewModal,
      isOpened: true,
      mode: mode,
      fields: {
        ...state.delRotationReviewModal.fields,
        id: info.id,
      },
    },
  };
}
function delRotationReviewChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.delRotationReviewModal.fields,
    [name]: value,
  };
  return {
    ...state,
    delRotationReviewModal: {
      ...state.delRotationReviewModal,
      fields: updatedFields,
    },
  };
}
function startDelRotationReview(state) {
  return {
    ...state,
    delRotationReviewStatus: ACTION_STATUS.LOGINING,
  };
}
function delRotationReviewSuccess(state, resp) {
  return {
    ...state,
    delRotationReviewStatus: ACTION_STATUS.SUCCESS,
  };
}
function delRotationReviewFailure(state, err) {
  return {
    ...state,
    delRotationReviewStatus: ACTION_STATUS.ERROR,
    delRotationReviewModal: {
      ...state.delRotationReviewModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initDelRotationReviewActionStatus(state) {
  return {
    ...state,
    delRotationReviewStatus: ACTION_STATUS.READY,
  };
}
function resetDelRotationReviewModal(state) {
  return {
    ...state,
    delRotationReviewModal: initialState.delRotationReviewModal,
  };
}

// cop
function openCopRotationReviewModal(state, payload) {
  return {
    ...state,
    copRotationReviewModal: {
      ...state.copRotationReviewModal,
      isOpened: true,
      fields: {
        ...state.copRotationReviewModal.fields,
        id: payload.id,
      },
    },
  };
}
function copRotationReviewChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.copRotationReviewModal.fields,
    [name]: value,
  };
  return {
    ...state,
    copRotationReviewModal: {
      ...state.copRotationReviewModal,
      fields: updatedFields,
    },
  };
}
function startCopRotationReview(state) {
  return {
    ...state,
    copRotationReviewStatus: ACTION_STATUS.LOGINING,
  };
}
function copRotationReviewSuccess(state, resp) {
  return {
    ...state,
    copRotationReviewStatus: ACTION_STATUS.SUCCESS,
  };
}
function copRotationReviewFailure(state, err) {
  return {
    ...state,
    copRotationReviewStatus: ACTION_STATUS.ERROR,
    copRotationReviewModal: {
      ...state.copRotationReviewModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initCopRotationReviewActionStatus(state) {
  return {
    ...state,
    copRotationReviewStatus: ACTION_STATUS.READY,
  };
}
function resetCopRotationReviewModal(state) {
  return {
    ...state,
    copRotationReviewModal: initialState.copRotationReviewModal,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map((userAccount) => (
    <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
  ));

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}

function loadRestrictedRuleListSuccess(state, resp) {
  return {
    ...state,
    restrictedRuleList: resp.data,
  };
}

function loadRestrictedRuleListFailure(state, err) {
  return {
    ...state,
  };
}

function loadRotationScoreSuccess(state, resp) {
  return {
    ...state,
    rotationScoreList: resp.data,
  };
}

function loadRotationScoreFailure(state, err) {
  return {
    ...state,
  };
}
// Batch upsert Transaction
function upRotationScoreSuccess(state, resp) {
  return {
    ...state,
  };
}

function upRotationScoreFailure(state, err) {
  return {
    ...state,
  };
}

function addRestrictedRuleSuccess(state, resp) {
  return {
    ...state,
  };
}

function openFinishTaskModal(state, task) {
  return {
    ...state,
    finishTaskModal: {
      ...state.finishTaskModal,
      isOpened: true,
      fields: {
        ...state.finishTaskModal.fields,
        id: task.id,
        remarks: task.remarks,
      },
    },
  };
}

function openOperationRecordDialog(state, queryParams) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isOpened: true,
      queryFields: queryParams.queryFields,
      customColumns: queryParams.customColumns,
      id: queryParams.id,
    },
  };
}
function startQueryOperationRecord(state) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.LOGINING,
  };
}
function queryOperationRecordSuccess(state, resp) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.SUCCESS,
    queryOperationRecordResp: resp,
  };
}
function queryOperationRecordFailure(state, err) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.ERROR,
    queryOperationRecordResp: err,
  };
}
function initQueryOperationRecordStatus(state) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.READY,
  };
}
function resetOperationRecordDialog(state) {
  return {
    ...state,
    operationRecordDialogModal: initialState.operationRecordDialogModal,
  };
}

// detail
function showDetailRcd(state, detail) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isDetailOpened: true,
      detail: detail,
    },
  };
}
function resetDetailRcd(state) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isDetailOpened: false,
      detail: null,
    },
  };
}

function loadInstituteUserAccountsSuccess(state, resp) {
  const instituteUserAccountArray = resp.data;
  const instituteUserAccountOptions = instituteUserAccountArray.map(
    (userAccount) => (
      <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
    )
  );

  return {
    ...state,
    instituteUserAccountArray,
    instituteUserAccountOptions,
  };
}

function loadInstituteUserAccountsFailure(state, err) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  [START_LOAD_ROTATION_INFO]: startLoadRotationReviewInfo,
  [LOAD_ROTATION_INFO_SUCCESS]: loadRotationReviewInfoSuccess,
  [LOAD_ROTATION_INFO_FAILURE]: loadRotationReviewInfoFailure,
  [INIT_LOAD_ROTATION_INFO_ACTION_STATUS]:
    initLoadRotationReviewInfoActionStatus,
  // select
  [SELECT_ROTATION_INFO]: selectRotationReviewInfo,
  // add or edit
  [OPEN_ADD_OR_EDIT_ROTATION_MODAL]: openAddOrEditRotationReviewModal,
  [ADD_OR_EDIT_ROTATION_CHANGE_INPUT]: addOrEditRotationReviewChangeInput,
  [START_ADD_OR_EDIT_ROTATION]: startAddOrEditRotationReview,
  [ADD_OR_EDIT_ROTATION_SUCCESS]: addOrEditRotationReviewSuccess,
  [ADD_OR_EDIT_ROTATION_FAILURE]: addOrEditRotationReviewFailure,
  [INIT_ADD_OR_EDIT_ROTATION_ACTION_STATUS]:
    initAddOrEditRotationReviewActionStatus,
  [RESET_ADD_OR_EDIT_ROTATION_MODAL]: resetAddOrEditRotationReviewModal,
  // refresh fx and amtUSD
  [START_REFRESH_FX]: startRefreshFx,
  [REFRESH_FX_SUCCESS]: refreshFxSuccess,
  [REFRESH_FX_FAILURE]: refreshFxFailure,
  [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
  [REFRESH_AMT_USD]: refreshAmtUSD,
  // del
  [OPEN_DEL_ROTATION_MODAL]: openDelRotationReviewModal,
  [DEL_ROTATION_CHANGE_INPUT]: delRotationReviewChangeInput,
  [START_DEL_ROTATION]: startDelRotationReview,
  [DEL_ROTATION_SUCCESS]: delRotationReviewSuccess,
  [DEL_ROTATION_FAILURE]: delRotationReviewFailure,
  [INIT_DEL_ROTATION_ACTION_STATUS]: initDelRotationReviewActionStatus,
  [RESET_DEL_ROTATION_MODAL]: resetDelRotationReviewModal,

  // cop
  [OPEN_COP_ROTATION_MODAL]: openCopRotationReviewModal,
  [COP_ROTATION_CHANGE_INPUT]: copRotationReviewChangeInput,
  [START_COP_ROTATION]: startCopRotationReview,
  [COP_ROTATION_SUCCESS]: copRotationReviewSuccess,
  [COP_ROTATION_FAILURE]: copRotationReviewFailure,
  [INIT_COP_ROTATION_ACTION_STATUS]: initCopRotationReviewActionStatus,
  [RESET_COP_ROTATION_MODAL]: resetCopRotationReviewModal,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  [LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS]: loadRestrictedRuleListSuccess,
  [LOAD_RESTRICTED_SECURITIES_RULE_FAILURE]: loadRestrictedRuleListFailure,

  [LOAD_ROTATION_SCORE_FAILURE]: loadRotationScoreFailure,
  [LOAD_ROTATION_SCORE_SUCCESS]: loadRotationScoreSuccess,

  [UPDATE_ROTATION_SCORE_FAILURE]: upRotationScoreFailure,
  [UPDATE_ROTATION_SCORE_SUCCESS]: upRotationScoreSuccess,

  [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,

  [OPEN_OPERATION_RECORD_DIALOG]: openOperationRecordDialog,
  [START_QUERY_OPERATION_RECORD]: startQueryOperationRecord,
  [QUERY_OPERATION_RECORD_SUCESS]: queryOperationRecordSuccess,
  [QUERY_OPERATION_RECORD_FAILURE]: queryOperationRecordFailure,
  [INIT_QUERY_OPERATION_RECORD_STATUS]: initQueryOperationRecordStatus,
  [RESET_OPERATION_RECORD_DIALOG]: resetOperationRecordDialog,
  // detail
  [SHOW_DETAIL_RCD]: showDetailRcd,
  [RESET_DETAIL_RCD]: resetDetailRcd,

  [LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS]: loadInstituteUserAccountsSuccess,
  [LOAD_INSTITUTE_USER_ACCOUNT_FAILURE]: loadInstituteUserAccountsFailure,
});

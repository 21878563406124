import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload,InputNumber, Icon,Checkbox ,Tag  } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditMeetingAndTrainingModalDialog extends Component {
    componentDidMount() {

    }
    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingQueryFields;
        this.props.loadMeetingAndTrainingInfo({userName, status})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add Meeting & Training';
        } else if(mode === 'EDIT') {
            return 'Edit Meeting & Training';
        } else {
            return 'Copy Meeting & Training';
        }
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditMeetingAndTrainingStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditMeetingAndTrainingModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditMeetingAndTrainingStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditMeetingAndTrainingModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditMeetingAndTrainingActionStatus();
            this.props.resetAddOrEditMeetingAndTrainingModal();
            this.refreshQuery();
        }
        if(addOrEditMeetingAndTrainingStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditMeetingAndTrainingActionStatus();
        }
    }
    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'repeat', value: value});
        this.onInputChange({name: 'week', value: undefined});
        this.onInputChange({name: 'repeatEvery', value: undefined});
    }   

    onInputChange = ({name, value}) => {
        this.props.addOrEditMeetingAndTrainingChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditMeetingAndTrainingModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditMeetingAndTrainingModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditMeetingAndTrainingModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
        if(name === 'type' && value ==='Sale Side Meeting'){
            this.onInputChange({ name: 'feedback', value: true })
        }
    }


    submitAddOrEditMeetingAndTraining = (state) => {
        const {
            addOrEditMeetingAndTrainingModal,
        } = this.props;
        const {
            mode,
        } = addOrEditMeetingAndTrainingModal;
        const {
            id,
            title,
            desc,
            type,
            broker,
            outsideemail,
            category,
            feedback,
            speaker,
            remark,
            sendmail,
            comperename,
            score,
            starttime,
            endtime,
            address,
            requiredattendees,
            optionalattendees,
            repeat,
            repeatevery,
            repeatmonthly,
            week,
            functiontype,
            usertype,
            fundandcompany,
            employee,
            status,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            filepath,
        
            remarks,
          
        } = addOrEditMeetingAndTrainingModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditMeetingAndTraining({
                    id,
                    title,
                    desc,
                    type,
                    broker,
                    outsideemail,
                    category,
                    feedback,
                    speaker,
                    remark,
                    sendmail,
                    comperename,
                    score,
                    starttime,
                    endtime,
                    address,
                    requiredattendees,
                    optionalattendees,
                    repeat,
                    repeatevery,
                    repeatmonthly,
                    week,
                    functiontype,
                    usertype,
                    fundandcompany,
                    employee,
                    status,
                    validetodate,
                    uploadChartOriName,
                    uploadChartRealName,
                    filepath,
                    remarks,
                    mode,
                    state,
                });                    
            }
        });
    }

    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
    generalAgreeementHandleChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'agreementUploadFileList', value: []});
            // this.onInputChange({name: 'uploadRealName', value: undefined});
            this.onInputChange({name: 'filepath', value: undefined});
            this.onInputChange({name: 'uploadOriName', value: undefined});
            return;
        }

        fileList = fileList.slice(-1);
        this.onInputChange({name: 'agreementUploadFileList', value: fileList});
        const file = fileList[0];
        if (!!file && !!file.response) {
          const filename = file.response.data.fileNameWithTimeStamp;
          const originalFileName = file.response.data.originalFileName;
          
          this.onInputChange({name: 'filepath', value: filename});
        //   this.onInputChange({name: 'uploadRealName', value: filename});
          this.onInputChange({name: 'uploadOriName', value: originalFileName});
        }
    }

    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditMeetingAndTrainingModal,
            instituteUserAccountOptions,
            userGroupOptions,
            statusOptions,
            typeOptions,
            categoryOptions,
            saleSideOptions,
            weekdayOptions,
            repeatEveryOptions,
            repeatMonthlyOptions,
            repeatOptions,
            
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditMeetingAndTrainingStatus,
            meetingAndTrainingUploadUrl,
            meetingAndTrainingDownloadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditMeetingAndTrainingModal;

        const {
            employee,
            usertype,
            title,
            desc,
            type,
            broker,
            outsideemail,
            category,
            feedback,
            remark,
            speaker,
            sendmail,
            comperename,
            score,
            starttime,
            endtime,
            address,
            status,
            requiredattendees,
            optionalattendees,
            repeat,
            repeatevery,
            repeatmonthly,
            week,
            state,
            filepath,
            uploadOriName,
            uploadRealName,
            functiontype,
            fundandcompany,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            chartFileList,
            remarks,
            agreementUploadFileList,

        } = addOrEditMeetingAndTrainingModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const isSubmitLoading = addOrEditMeetingAndTrainingStatus === ACTION_STATUS.LOGINING;
        const saveDis = mode === 'ADD' || state === 'Add' || mode==='COPY';
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditMeetingAndTrainingModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditMeetingAndTrainingModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" disabled={!saveDis} loading={isSubmitLoading} onClick={() => this.submitAddOrEditMeetingAndTraining('Add') }>
                        save
                        </Button>,
                           <Button key="save" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditMeetingAndTraining('New') }>
                          Submit
                        </Button>,
                        // <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditMeetingAndTraining}>
                        //   Submit
                        // </Button>,
                      ]}
                >
                <Form layout="horizontal">

                    <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Title">
                                {getFieldDecorator('title', {
                                    initialValue: title,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Title*',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'title', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Desc">
                                {getFieldDecorator('desc', {
                                    initialValue: desc,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Desc',
                                    }],
                                    })(
                                    // <Input
                                    //     style={{ width: 200, marginLeft: 10 }}
                                    //     onChange={(e) => {
                                    //         this.onInputChange({ name: 'desc', value: e.target.value })
                                    //     }}
                                    // />
                                    <TextArea 
                                    style={{ width: 500, marginLeft: 10 }}
                                    rows={3} 
                                    onChange={(e) => {
                                        this.onInputChange({ name: 'desc', value: e.target.value })
                                    }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>

                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Type">
                                {getFieldDecorator('type', {
                                    initialValue: type,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Type',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'type', value: val })
                                            }} 
                                            placeholder="Please select Type">
                                        {typeOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Category">
                                {getFieldDecorator('category', {
                                    initialValue: category,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Category',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'category', value: val })
                                            }} 
                                            placeholder="Please select Category">
                                        {categoryOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            </Row> 
                        <Row gutter={24}>
                            
                            <Col span={12} >
                                <FormItem {...formItemLayout} label="What did you learn">
                                <Checkbox 
                                    style={{ width: 100, marginLeft: 10 }}
                                    checked={remark}
                                    onChange={(e) => {
                                    this.onInputChange({ name: 'remark', value: e.target.checked })
                                    }}
                                    ></Checkbox>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="sendMail">
                                <Checkbox 
                                    style={{ width: 100, marginLeft: 10 }}
                                    checked={sendmail}
                                    onChange={(e) => {
                                    this.onInputChange({ name: 'sendmail', value: e.target.checked })
                                    }}
                                    ></Checkbox>
                                </FormItem>
                            </Col>

                        </Row>
                        
                        <Row gutter={24}>
                            
                            {/* <Col span={12} >
                                    <FormItem {...formItemLayout} label="Feedback">
                                    <Checkbox 
                                        style={{ width: 100, marginLeft: 10 }}
                                        checked={feedback}
                                        onChange={(e) => {
                                        this.onInputChange({ name: 'feedback', value: e.target.checked })
                                        }}
                                        ></Checkbox>
                                    </FormItem>
                                </Col> */}
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="Speaker">
                                    {getFieldDecorator('speaker', {
                                        initialValue: speaker,
                                        rules: [{
                                            required: false,
                                            message: 'Please input Speaker*',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'speaker', value: e.target.value })
                                            }}
                                        />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>



                        <Row gutter={24}>
                            <Col span={12}>
                                <div>
                                {type === 'Sale Side Meeting' ?
                                <FormItem {...formItemLayout} label="Broker">
                                {getFieldDecorator('broker', {
                                    initialValue: broker,
                                    rules: [{
                                        required: false,
                                        message: 'Please select broker',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'broker', value: val })
                                            }} 
                                            placeholder="Please select broker">
                                        {saleSideOptions}
                                    </Select>
                                    )}
                                </FormItem>
                                :<div></div>
                                }
                                </div>

                            </Col>
                            </Row>
                        
                        <Row gutter={24}>
                            <Col span={12}>
                                <div>
                                {type === 'Sale Side Meeting' ?
                                <div>
                                <FormItem {...formItemLayout} label="Outside email">
                                {getFieldDecorator('Outside email', {
                                    initialValue: outsideemail,
                                    rules: [{
                                        required: false,
                                        message: '(Use the ; split email, eg: xx@136.com;xx@gmail.com)',
                                        
                                    }],
                                    })(
                                        <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        placeholder='eg: xx@136.com;xx@gmail.com' 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'outsideemail', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                                </div>
                                
                                :<div></div>
                                }
                                </div>

                            </Col>
                            <Col span={12}>
                                <div>
                                {type === 'Sale Side Meeting' ?
                                <div>
                  
                                <p>email format<br/>
                                Use the ; split email, eg: xx@136.com;xx@gmail.com</p>
           
                                </div>
                                
                                :<div></div>
                                }
                                </div>

                            </Col>
                        </Row> 
                        


                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Coach">
                                {getFieldDecorator('comperename', {
                                    initialValue: comperename,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Coach',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            mode='multiple'
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onEmailInputChange({ name: 'comperename', value: val })
                                            }} 
                                            placeholder="Please select Coach">
                                        {instituteUserAccountOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            {/* <Col span={12}>
                                <FormItem {...formItemLayout} label="Score">
                                <InputNumber
                                    value={score}
                                    min={0}
                                    defaultValue={0}
                                    precision={0}
                                    onChange={(val) => {
                                        this.onInputChange({ name: 'score', value: val })
                                    }}
                                />
                                </FormItem>
                            </Col> */}

                            
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Class Credit">
                                {getFieldDecorator('score', {
                                    initialValue: score,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Class Credit',
                                    }],
                                    })(
                                    <InputNumber
                                        value={score}
                                        min={0}
                                        defaultValue={0}
                                        precision={0}
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(val) => {
                                            this.onInputChange({ name: 'score', value: val })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="Start Time">
                            {getFieldDecorator('starttime', {
                                initialValue: starttime === '' ? null : moment(starttime),
                                // initialValue: null, 
                                rules: [{
                                required: true,
                                message: 'Please select valid from',
                                }],
                            })(
                                <DatePicker 
                                style={{ width: 200, marginLeft: 10 }}
                                value={!starttime ? null : moment(starttime)}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                allowClear={false}
                                // disabledDate={(currentDate) => {
                                //     return currentDate.isBefore(moment().format("YYYY-MM-DD"));
                                //   }}
                                onChange={(date, dateString) => {
                                    this.onInputChange({ name: 'starttime', value: dateString });
                                }} />
                                )
                            }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="End Time">
                             {/* <DatePicker 
                                style={{ width: 200, marginLeft: 10 }}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                // value={moment(endtime)}
                                allowClear={false}
                                onChange={(date, dateString) => {
                                this.onInputChange({ name: 'endtime', value: dateString });
                                }} 
                            />*/}
                       {getFieldDecorator('endtime', {
                                initialValue: endtime === '' ? null : moment(endtime),
                                rules: [{
                                required: true,
                                message: 'Please select end time',
                                }],
                            })(
                                <DatePicker 
                                style={{ width: 200, marginLeft: 10 }}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                value={!endtime ? null : moment(endtime)}
                                allowClear={false}
                                // disabledDate={(currentDate1) => {
                                //     return currentDate1.isBefore(moment().format("YYYY-MM-DD"));
                                //   }}
                                onChange={(date, dateString) => {
                                    this.onInputChange({ name: 'endtime', value: dateString });
                                }} />
                                )
                            } 




                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                    <FormItem {...formItemLayout} label="Repeat">
                                            {getFieldDecorator('repeat', {
                                                initialValue: repeat,
                                                rules: [{
                                                    required: true,
                                                    message: '',
                                                }],
                                                })(
                                                <Select 
                                                    allowClear
                                                  
                                                    style={{ width: 100, marginLeft: 10 }}
                                                    placeholder='repeat' 
                                                    onChange={(val) => {
                                                        // this.onInputChange({ name: 'repeat', value: val })
                                                        this.onFrequencyInputChange(val)
                                                }}>
                                                    {repeatOptions}
                                                </Select>
                                            )}
                                        </FormItem>
                            </Col> 
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadOriName', {
                                        initialValue: uploadOriName,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload the Agreement',
                                        }],
                                        })(
                                        <Upload
                                            name="uploadOriName"
                                            withCredentials={true}
                                            // disabled={needDisable}
                                            multiple={false}
                                            fileList={agreementUploadFileList}
                                            action={meetingAndTrainingUploadUrl}
                                            onChange={this.generalAgreeementHandleChange}
                                        >
                                            <Button 
                                                style={{ width: 100, marginLeft: 10 }}
                                                // disabled={needDisable}
                                            >
                                                <Icon type="upload" />Upload
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <div style={{marginLeft: 80 }}>{
                            repeat === 'Weekly' ?
                            <Row gutter={24}>
                                {/* <Col span={8}>
                                        <FormItem {...formItemLayout} label="Repeat every">
                                                {getFieldDecorator('repeatEvery', {
                                                    initialValue: repeatEvery,
                                                    rules: [{
                                                        required: false,
                                                        message: '',
                                                    }],
                                                    })(
                                                    <Select 
                                                        allowClear
                                                        style={{ width: 100, marginLeft: 10 }}
                                                        placeholder='RepeatEvery' 
                                                        onChange={(val) => {
                                                            this.onInputChange({ name: 'repeatEvery', value: val })
                                                    }}>
                                                        {repeatEveryOptions}
                                                    </Select>
                                                )}
                                            </FormItem>
                                </Col> */}

                                <Col span={8}>
                                        <FormItem {...formItemLayout} label="Week">
                                                {getFieldDecorator('week', {
                                                    initialValue: week,
                                                    rules: [{
                                                        required: false,
                                                        message: '',
                                                    }],
                                                    })(
                                                    <Select 
                                                        allowClear
                                                        mode='multiple'
                                                        style={{ width: 200, marginLeft: 10 }}
                                                        placeholder='week' 
                                                        onChange={(val) => {
                                                            this.onEmailInputChange({ name: 'week', value: val })
                                                    }}>
                                                        {weekdayOptions}
                                                    </Select>
                                                )}
                                            </FormItem>
                                </Col>     

                            </Row>
                            : repeat === 'Daily' ?
                            <Row gutter={24}>
                                <Col span={8}>
                                        <FormItem {...formItemLayout} label="Repeat every">
                                                {getFieldDecorator('repeatEvery', {
                                                    initialValue: repeatevery,
                                                    rules: [{
                                                        required: false,
                                                        message: '',
                                                    }],
                                                    })(
                                                    <Select 
                                                        allowClear
                                                        style={{ width: 100, marginLeft: 10 }}
                                                        placeholder='RepeatEvery' 
                                                        onChange={(val) => {
                                                            this.onInputChange({ name: 'repeatevery', value: val })
                                                    }}>
                                                        {repeatEveryOptions}
                                                    </Select>
                                                )}
                                            </FormItem>
                                </Col>
                            </Row>
                            : repeat === 'Monthly' ?
                            <Row gutter={24}>
                                <Col span={8}>
                                        <FormItem {...formItemLayout} label="Repeat Monthly">
                                                {getFieldDecorator('repeatmonthly', {
                                                    initialValue: repeatmonthly,
                                                    rules: [{
                                                        required: false,
                                                        message: '',
                                                    }],
                                                    })(
                                                    <Select 
                                                        allowClear
                                                        style={{ width: 300, marginLeft: 10 }}
                                                        placeholder='RepeatMonthly' 
                                                        onChange={(val) => {
                                                            this.onInputChange({ name: 'repeatmonthly', value: val })
                                                    }}>
                                                        {repeatMonthlyOptions}
                                                    </Select>
                                                )}
                                            </FormItem>
                                </Col>
                            </Row>
                            : <div></div>
                           
                        }</div>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="Required Attendees">
                                        {getFieldDecorator('requiredAttendees', {
                                            initialValue: requiredattendees,
                                            rules: [{
                                                required: true,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                mode='multiple'
                                                style={{ width: 500, marginLeft: 10 }}
                                                placeholder='Please select Required Attendees' 
                                                onChange={(val) => {
                                                    this.onEmailInputChange({ name: 'requiredattendees', value: val })
                                            }}>
                                                {userGroupOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>       

                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="Optional Attendees">
                                        {getFieldDecorator('optionalAttendees', {
                                            initialValue: optionalattendees,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                mode='multiple'
                                                style={{ width: 500, marginLeft: 10 }}
                                                placeholder='Please select Optional Attendees' 
                                                onChange={(val) => {
                                                    this.onEmailInputChange({ name: 'optionalattendees', value: val })
                                            }}>
                                                {userGroupOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>       

                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="Location">
                                {getFieldDecorator('address', {
                                    initialValue: address,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Location',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 500, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'address', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            {/* <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                {getFieldDecorator('status', {
                                    initialValue: status,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Status',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'status', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                        {statusOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Valid to">
                                {getFieldDecorator('validetodate', {
                                    initialValue: validetodate,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Valid Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'validetodate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col> */}
                        </Row>
                        {/* <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadChartOriName', {
                                        initialValue: uploadChartOriName,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload the chart',
                                        }],
                                        })(
                                            <Upload
                                                name="uploadChartOriName"
                                                withCredentials={true}
                                                multiple={false}
                                                fileList={chartFileList}
                                                action={meetingAndTrainingUploadUrl}
                                                onChange={this.groupStructureUploadChange}
                                            >
                                                <Button style={{ width: 200, marginLeft: 10 }}>
                                                    <Icon type="upload" />Upload
                                                </Button>
                                            </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                {getFieldDecorator('remarks', {
                                    initialValue: remarks,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Remarks',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>                 */}

                        
                        
                </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditMeetingAndTrainingModalDialogForm = Form.create({ name: 'AddOrEditMeetingAndTrainingModalDialog' })(AddOrEditMeetingAndTrainingModalDialog);
export default AddOrEditMeetingAndTrainingModalDialogForm;

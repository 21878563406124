// query
export const ON_QUERY_INPUT_CHANGE = 'ON_QUERY_INPUT_CHANGE';
export const START_LOAD_MEETING_AND_TRAINING_INFO = 'START_LOAD_MEETING_AND_TRAINING_INFO';
export const LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS = 'LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS';
export const LOAD_MEETING_AND_TRAINING_INFO_FAILURE = 'LOAD_MEETING_AND_TRAINING_INFO_FAILURE';
export const INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS = 'INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS';
// select
export const SELECT_MEETING_AND_TRAINING_INFO = 'SELECT_MEETING_AND_TRAINING_INFO';
// add or edit
export const OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL = 'OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL';
export const ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT = 'ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT';
export const START_ADD_OR_EDIT_MEETING_AND_TRAINING = 'START_ADD_OR_EDIT_MEETING_AND_TRAINING';
export const ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS = 'ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS';
export const ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE = 'ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE';
export const INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS = 'INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS';
export const RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL = 'RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL';
// refresh fx and amtUSD
export const START_REFRESH_FX = 'START_REFRESH_FX';
export const REFRESH_FX_SUCCESS = 'REFRESH_FX_SUCCESS';
export const REFRESH_FX_FAILURE = 'REFRESH_FX_FAILURE';
export const INIT_REFRESH_FX_ACTION_STATUS = 'INIT_REFRESH_FX_ACTION_STATUS';
export const REFRESH_AMT_USD = 'REFRESH_AMT_USD';
// del
export const OPEN_DEL_MEETING_AND_TRAINING_MODAL = 'OPEN_DEL_MEETING_AND_TRAINING_MODAL';
export const DEL_MEETING_AND_TRAINING_CHANGE_INPUT = 'DEL_MEETING_AND_TRAINING_CHANGE_INPUT';
export const START_DEL_MEETING_AND_TRAINING = 'START_DEL_MEETING_AND_TRAINING';
export const DEL_MEETING_AND_TRAINING_SUCCESS = 'DEL_MEETING_AND_TRAINING_SUCCESS';
export const DEL_MEETING_AND_TRAINING_FAILURE = 'DEL_MEETING_AND_TRAINING_FAILURE';
export const INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS = 'INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS';
export const RESET_DEL_MEETING_AND_TRAINING_MODAL = 'RESET_DEL_MEETING_AND_TRAINING_MODAL';

// cop
export const OPEN_COP_MEETING_AND_TRAINING_MODAL = 'OPEN_COP_MEETING_AND_TRAINING_MODAL';
export const COP_MEETING_AND_TRAINING_CHANGE_INPUT = 'COP_MEETING_AND_TRAINING_CHANGE_INPUT';
export const START_COP_MEETING_AND_TRAINING = 'START_COP_MEETING_AND_TRAINING';
export const COP_MEETING_AND_TRAINING_SUCCESS = 'COP_MEETING_AND_TRAINING_SUCCESS';
export const COP_MEETING_AND_TRAINING_FAILURE = 'COP_MEETING_AND_TRAINING_FAILURE';
export const INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS = 'INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS';
export const RESET_COP_MEETING_AND_TRAINING_MODAL = 'RESET_COP_MEETING_AND_TRAINING_MODAL';


export const ANSWEREE_SELECT_MODAL_DIALOG_OPEN = 'ANSWEREE_SELECT_MODAL_DIALOG_OPEN';
export const ANSWEREE_SELECT_MODAL_DIALOG_RESET = 'ANSWEREE_SELECT_MODAL_DIALOG_RESET';


// Select All Answeree
export const SELECT_ALL_ANSWEREE = 'SELECT_ALL_ANSWEREE';

// Select Invert Answeree
export const SELECT_INVERT_ANSWEREE = 'SELECT_INVERT_ANSWEREE';
// Answeree Check Changed
export const ANSWEREE_CHECK_CHANGED = 'ANSWEREE_CHECK_CHANGED';

// Open/Reset Questionnaire Email Edit Modal Dialog
export const QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN = 'QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN';
export const QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET = 'QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET';



// Select Answerees
export const START_SELECT_ANSWEREES = 'START_SELECT_ANSWEREES';
export const SELECT_ANSWEREES_SUCCESS = 'SELECT_ANSWEREES_SUCCESS';
export const SELECT_ANSWEREES_FAILURE = 'SELECT_ANSWEREES_FAILURE';
export const INIT_SELECT_ANSWEREES_ACTION_STATUS = 'INIT_SELECT_ANSWEREES_ACTION_STATUS';

export const LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS = 'LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS';
export const LOAD_INSTITUTE_USER_ACCOUNT_FAILURE = 'LOAD_INSTITUTE_USER_ACCOUNT_FAILURE';
import {connect} from 'react-redux';
import MeetingAndTrainingDashboard from '../components/MeetingAndTrainingDashboard';

import {
    loadUserAccounts,
  } from '../../../home/homeActions';
import {
    loadMeetingAndTrainingGroupInfo,
} from '../../meetingAndTrainingGroup/MeetingAndTrainingGroupActions'
import {
    // query
    onQueryInputChange,
    loadMeetingAndTrainingInfo,
    initLoadMeetingAndTrainingInfoActionStatus,
    // select
    selectMeetingAndTrainingInfo,
    // add or edit
    openAddOrEditMeetingAndTrainingModal,
    addOrEditMeetingAndTrainingChangeInput,
    addOrEditMeetingAndTraining,
    initAddOrEditMeetingAndTrainingActionStatus,
    resetAddOrEditMeetingAndTrainingModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelMeetingAndTrainingModal,
    delMeetingAndTrainingChangeInput,
    delMeetingAndTraining,
    initDelMeetingAndTrainingActionStatus,
    resetDelMeetingAndTrainingModal,

    //cop
    openCopMeetingAndTrainingModal,
    copMeetingAndTrainingChangeInput,
    copMeetingAndTraining,
    initCopMeetingAndTrainingActionStatus,
    resetCopMeetingAndTrainingModal,

    
    openAnswereeSelectModalDialog,
    resetAnswereeSelectModalDialog,

    // Select All Answeree
    selectAllAnsweree,

    // Select Invert Answeree
    selectInvertAnsweree,

    // Answeree Check Changed
    answereeCheckChanged,


    // Open/Reset Questionnaire Email Edit Modal Dialog
    openQuestionnaireEmailEditModalDialog,
    resetQuestionnaireEmailEditModalDialog,

      // Select Answeree
    selectAnswerees,
    initSelectAnswereesActionStatus,
    
    loadInstituteUserAccounts

} from '../MeetingAndTrainingActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.meetingAndTraining.statusOptions,
        typeOptions: state.meetingAndTraining.typeOptions,
        categoryOptions: state.meetingAndTraining.categoryOptions,
        optonalOptions: state.meetingAndTraining.optonalOptions,
        saleSideOptions: state.meetingAndTraining.saleSideOptions,
        repeatOptions: state.meetingAndTraining.repeatOptions,
        repeatMonthlyOptions: state.meetingAndTraining.repeatMonthlyOptions,
        repeatEveryOptions: state.meetingAndTraining.repeatEveryOptions,
        weekdayOptions: state.meetingAndTraining.weekdayOptions,
        fundFirmRelatedOptions: state.meetingAndTraining.fundFirmRelatedOptions,
        ccyOptions: state.meetingAndTraining.ccyOptions,
        // userAccountOptions: state.meetingAndTraining.userAccountOptions,
        userGroupOptions: state.meetingAndTraining.userGroupOptions,
        // meetingAndTrainingQueryFields
        meetingAndTrainingQueryFields: state.meetingAndTraining.meetingAndTrainingQueryFields,
        // query
        loadMeetingAndTrainingInfoActionStatus: state.meetingAndTraining.loadMeetingAndTrainingInfoActionStatus,
        loadMeetingAndTrainingInfoResp: state.meetingAndTraining.loadMeetingAndTrainingInfoResp,
        selectedMeetingAndTrainingInfoArray: state.meetingAndTraining.selectedMeetingAndTrainingInfoArray,
        meetingAndTrainingGridColumns: state.meetingAndTraining.meetingAndTrainingGridColumns,
        // add or edit
        addOrEditMeetingAndTrainingModal: state.meetingAndTraining.addOrEditMeetingAndTrainingModal,
        addOrEditMeetingAndTrainingStatus: state.meetingAndTraining.addOrEditMeetingAndTrainingStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.meetingAndTraining.refreshFxStatus,
        refreshFxResp: state.meetingAndTraining.refreshFxResp,
        // del
        delMeetingAndTrainingModal: state.meetingAndTraining.delMeetingAndTrainingModal,
        delMeetingAndTrainingStatus: state.meetingAndTraining.delMeetingAndTrainingStatus,
        // cop
        copMeetingAndTrainingModal: state.meetingAndTraining.copMeetingAndTrainingModal,
        copMeetingAndTrainingStatus: state.meetingAndTraining.copMeetingAndTrainingStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        meetingAndTrainingUploadUrl: state.meetingAndTraining.meetingAndTrainingUploadUrl,
        meetingAndTrainingDownloadUrl: state.meetingAndTraining.meetingAndTrainingDownloadUrl,
        answereeSelectControlModal: state.meetingAndTraining.answereeSelectControlModal,

        pmArray: state.meetingAndTraining.pmArray,
        pmGroupArray: state.meetingAndTraining.pmGroupArray,
        userAccountArray: state.meetingAndTraining.userAccountArray,
           // Answers
        answerArray: state.meetingAndTraining.answerArray,

            // Open/Reset Questionnaire Email Edit Modal Dialog
        questionnaireEmailEditControlModal: state.meetingAndTraining.questionnaireEmailEditControlModal,

        // Select Answerees
        selectAnswereesActionStatus: state.meetingAndTraining.selectAnswereesActionStatus,


        // meetingAndTrainingUploadUrl: state.meetingAndTraining.meetingAndTrainingUploadUrl,
        // meetingAndTrainingDownloadUrl: state.meetingAndTraining.meetingAndTrainingDownloadUrl,

        instituteUserAccountOptions: state.meetingAndTraining.instituteUserAccountOptions,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadMeetingAndTrainingInfo: (params) => dispatch(loadMeetingAndTrainingInfo(params)),
        initLoadMeetingAndTrainingInfoActionStatus: () => dispatch(initLoadMeetingAndTrainingInfoActionStatus()),
        // select
        selectMeetingAndTrainingInfo: (dataArray) => dispatch(selectMeetingAndTrainingInfo(dataArray)),
        // add or edit
        openAddOrEditMeetingAndTrainingModal: (mode, data) => dispatch(openAddOrEditMeetingAndTrainingModal(mode, data)),
        addOrEditMeetingAndTrainingChangeInput: (params) => dispatch(addOrEditMeetingAndTrainingChangeInput(params)),
        addOrEditMeetingAndTraining: (info) => dispatch(addOrEditMeetingAndTraining(info)),
        initAddOrEditMeetingAndTrainingActionStatus: () => dispatch(initAddOrEditMeetingAndTrainingActionStatus()),
        resetAddOrEditMeetingAndTrainingModal: () => dispatch(resetAddOrEditMeetingAndTrainingModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelMeetingAndTrainingModal: (info,mode) => dispatch(openDelMeetingAndTrainingModal(info,mode)),
        delMeetingAndTrainingChangeInput: (params) => dispatch(delMeetingAndTrainingChangeInput(params)),
        delMeetingAndTraining: (info) => dispatch(delMeetingAndTraining(info)),
        initDelMeetingAndTrainingActionStatus: () => dispatch(initDelMeetingAndTrainingActionStatus()),
        resetDelMeetingAndTrainingModal: () => dispatch(resetDelMeetingAndTrainingModal()),

        // cop
        openCopMeetingAndTrainingModal: (info) => dispatch(openCopMeetingAndTrainingModal(info)),
        copMeetingAndTrainingChangeInput: (params) => dispatch(copMeetingAndTrainingChangeInput(params)),
        copMeetingAndTraining: (params) => dispatch(copMeetingAndTraining(params)),
        initCopMeetingAndTrainingActionStatus: () => dispatch(initCopMeetingAndTrainingActionStatus()),
        resetCopMeetingAndTrainingModal: () => dispatch(resetCopMeetingAndTrainingModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),

        openAnswereeSelectModalDialog: () => dispatch(openAnswereeSelectModalDialog()),
        resetAnswereeSelectModalDialog: () => dispatch(resetAnswereeSelectModalDialog()),

            // loadUserAccounts
        loadUserAccounts: () => dispatch(loadUserAccounts()),


        // Select All Answeree
        selectAllAnsweree: () => dispatch(selectAllAnsweree()),

        // Select Invert Answeree
        selectInvertAnsweree: () => dispatch(selectInvertAnsweree()),

        // Answeree Check Changed
        answereeCheckChanged: (checked ,userAccountName) => dispatch(answereeCheckChanged(checked ,userAccountName)),

        // Open/Reset Questionnaire Email Edit Modal Dialog
        openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
        resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),

        // Select Answeree
        selectAnswerees: (userAccountNameList, questionnaireGuid, emailTitle, emailContent) => dispatch(selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent)),

        initSelectAnswereesActionStatus: () => dispatch(initSelectAnswereesActionStatus()),
        loadMeetingAndTrainingGroupInfo: (params) => dispatch(loadMeetingAndTrainingGroupInfo(params)),

        loadInstituteUserAccounts: (params) => dispatch(loadInstituteUserAccounts(params)),
        
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MeetingAndTrainingDashboard);
import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload, Icon  } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import FundBookListGrid from './FundBookListGrid'
import FundBookAddDialog from './FundBookAddDialog'
import YearPicker from './YearPicker'; 
const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};

const formItemLayoutDataGrid = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};  
class AddOrEditRatingManagementModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.ratingManagementQueryFields;
        this.props.loadRatingManagementInfo({userName, status})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add Rating Management Info';
        } else if(mode === 'EDIT') {
            return 'Edit Rating Management Info';
        } else {
            return 'Copy Rating Management Info';
        }
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditRatingManagementStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditRatingManagementModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditRatingManagementStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditRatingManagementModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditRatingManagementActionStatus();
            this.props.resetAddOrEditRatingManagementModal();
            this.refreshQuery();
        }
        if(addOrEditRatingManagementStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditRatingManagementActionStatus();
        }
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    onInputChange = ({name, value}) => {
        this.props.addOrEditRatingManagementChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditRatingManagementModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditRatingManagementModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditRatingManagementModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
    }


    submitAddOrEditRatingManagement = () => {
        const {
            addOrEditRatingManagementModal,
        } = this.props;
        const {
            fields
          } = this.props.restrictedRuleEditControlModal;
        const {
            mode,
        } = addOrEditRatingManagementModal;

        const userList = fields.fundBookList;
        const {
            id,
            cpty,
            functiontype,
            usertype,
            fundandcompany,
            employee,
            status,
            className,

            remarks,
          
        } = addOrEditRatingManagementModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditRatingManagement({
                    id,
                  
                    employee,
                
                    className,
 
                    remarks,
                    mode,

                });                    
            }
        });
    }
    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
 

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditRatingManagementModal,
            instituteUserAccountOptions,
            statusOptions,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditRatingManagementStatus,
            ratingManagementUploadUrl,
            ratingManagementDownloadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditRatingManagementModal;

        const {
            employee,
            usertype,
            cpty,
            status,
            functiontype,
            fundandcompany,
            className,
            uploadChartOriName,
            uploadChartRealName,
            chartFileList,
            remarks,

        } = addOrEditRatingManagementModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const isSubmitLoading = addOrEditRatingManagementStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditRatingManagementModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditRatingManagementModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditRatingManagement}>
                          Submit
                        </Button>,
                      ]}
                >
                <Form layout="horizontal">

                    {/* <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Cpty">
                                {getFieldDecorator('cpty', {
                                    initialValue: cpty,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Cpty*',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'cpty', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Function">
                                {getFieldDecorator('functiontype', {
                                    initialValue: functiontype,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Function',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'functiontype', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>

                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Fund/Co.">
                                {getFieldDecorator('fundandcompany', {
                                    initialValue: fundandcompany,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Fund/Co.',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'fundandcompany', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>  */}



                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Employee">
                                {getFieldDecorator('employee', {
                                    initialValue: employee,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Employee',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            mode='multiple'
                                            style={{ width: 200, marginLeft: 10 }}
                                           
                                            onChange={(val) => {
                                                this.onEmailInputChange({ name: 'employee', value: val })
                                            }} 
                                            placeholder="Please select Employee">
                                        {instituteUserAccountOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            {/* <Col span={12}>
                                <FormItem {...formItemLayout} label="User Type">
                                {getFieldDecorator('usertype', {
                                    initialValue: usertype,
                                    rules: [{
                                        required: false,
                                        message: 'Please input User Type',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'usertype', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col> */}
                        </Row>
                        <Row gutter={24}>
                            {/* <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                {getFieldDecorator('status', {
                                    initialValue: status,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Status',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'status', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                        {statusOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col> */}
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="className">
                                {getFieldDecorator('validetodate', {
                                    initialValue: className,
                                    rules: [{
                                        required: false,
                                        message: 'Please select class',
                                    }],
                                    })(
                                    <YearPicker 
                                        // mode="year"
                                        // format="YYYY"
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'className', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        {/* <Col span={12}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadChartOriName', {
                                        initialValue: uploadChartOriName,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload the chart',
                                        }],
                                        })(
                                            <Upload
                                                name="uploadChartOriName"
                                                withCredentials={true}
                                                multiple={false}
                                                fileList={chartFileList}
                                                action={ratingManagementUploadUrl}
                                                onChange={this.groupStructureUploadChange}
                                            >
                                                <Button style={{ width: 200, marginLeft: 10 }}>
                                                    <Icon type="upload" />Upload
                                                </Button>
                                            </Upload>
                                    )}
                                </FormItem>
                            </Col> */}
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                {getFieldDecorator('remarks', {
                                    initialValue: remarks,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Remarks',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>                
                        {/* <Row>
                            <Col span={24}>
                                <FormItem {...formItemLayoutDataGrid} label="User List">
                                    <FundBookListGrid {...this.props}/>
                                </FormItem>  
                            </Col>
                        </Row> */}
                        
                        
                </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
                <FundBookAddDialog {...this.props}/>
            </div>
        );
    }
}

const AddOrEditRatingManagementModalDialogForm = Form.create({ name: 'AddOrEditRatingManagementModalDialog' })(AddOrEditRatingManagementModalDialog);
export default AddOrEditRatingManagementModalDialogForm;

import React, { Component } from "react";
import moment from "moment";
import { AgGridReact } from "@ag-grid-community/react";
import { Breadcrumb, Button, message, Input, Select, DatePicker } from "antd";
import AddOrEditRestrictedRuleDialog from "./AddOrEditRestrictedRuleDialog";
import { Message } from "semantic-ui-react";
import OperationRecordDetail from "../OperationRecordDetail";
class RestrictedSecurityRuleGrid extends Component {
  constructor(props) {
    super(props);
    const that = this;
    this.state = {
      // columnDefs: props.restrictedSecurityRuleGridColumns,
      columnDefs: [
        {
          field: "participant",
          headerName: "Participant*",
          cellClass: "non-number",
          width: 200,
          cellRenderer: "agGroupCellRenderer",
        },
        {
          field: "title",
          headerName: "Title*",
          cellClass: "non-number",
          width: 300,
        },
        {
          field: "starttime",
          headerName: "Start Time",
          cellClass: "non-number",
          width: 150,
          sort: 'desc'
        },
        {
          field: "endtime",
          headerName: "End Time",
          cellClass: "non-number",
          width: 150,
        },
        {
          field: "type",
          headerName: "Type",
          cellClass: "non-number",
          width: 100,
        },
        {
          field: "totlescore",
          headerName: "Credit",
          cellClass: "non-number",
          width: 100,
        },
        {
          field: "progress",
          headerName: "Participation Rate",
          cellClass: "non-number",
          width: 200,
        },
        {
          field: "score",
          headerName: "Score",
          cellClass: "non-number",
          width: 100,
        },
        {
          field: "required",
          headerName: "Mandatory or Optional",
          cellClass: "non-number",
          width: 200,
        },
        // {
        //   field: 'optional',
        //   headerName: "Optional",
        //   cellClass: 'non-number',
        //   width: 100,
        // },
      ],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100,
      },
      rowSelection: "single",
      rowClassRules: {
        MarkBackgroundRede: function (params) {
          // if(params.data.status === 'New') {
          return (
            params.data.required === "M" &&
            params.data.progress.split("/")[0] === "0"
          );
          // } alertFlag  limitFlag  1 表示出警
        },
      },
      context: { componentParent: this },
      frameworkComponents: {},
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              field: "id",
              headerName: "id",
              cellClass: "non-number",
              width: 120,
              cellRenderer: "agGroupCellRenderer",
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: true,
            },
            // {field: 'title', headerName: 'title', cellClass: 'non-number', width: 150},
            {
              field: "starttime",
              headerName: "Training Date*",
              cellClass: "non-number",
              width: 100,
              cellRenderer: (params) => {
                // const commaNum = date(params.data.starttime).format('0,0.00');
                // params.data.starttime
                return params.data.starttime.substring(0, 10);
              },
            },
            // {field: 'required', headerName: 'required', cellClass: 'non-number', width: 120},
            // {
            //     field: 'participant',
            //     headerName: "Participant*",
            //     cellClass: 'non-number',
            //     width: 200,
            // },
            {
              field: "title",
              headerName: "title",
              cellClass: "non-number",
              width: 150,
            },

            {
              field: "comperename",
              headerName: "Coach",
              cellClass: "non-number",
              width: 200,
            },

            {
              field: "subtitle",
              headerName: "Sub title",
              cellClass: "non-number",
              width: 150,
            },
            {
              field: "note",
              headerName: "What did you learn",
              cellClass: "non-number",
              width: 300,
            },
            {
              field: "comments",
              headerName: "Coach's comments",
              cellClass: "non-number",
              width: 300,
            },
            {
              field: "filepath",
              headerName: "Attachment",
              cellClass: "non-number",
              width: 300,
              cellRenderer: this.sendHistoryAttachementCellRender,
            },
            {
              field: "signin",
              headerName: "Sumitter*",
              cellClass: "non-number",
              width: 150,
            },
            //   {
            //     field: 'feedbackComments',
            //     headerName: "Feedback",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
            // {
            //     field: 'feedbackScore',
            //     headerName: "feedback Score",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
          ],
          suppressRowClickSelection: true,
          suppressAggFuncInHeader: true,
          enableColResize: true,
          enableSorting: true,
          enableFilter: true,
          animateRows: true,
          deltaRowDataMode: false,
          onSelectionChanged(event) {
            let rowNodeArray = event.api.getSelectedNodes();
            let cptRcdDataArray = rowNodeArray.map((rowNode) => rowNode.data);
            that.props.selectRotationReviewInfo(cptRcdDataArray);
          },
          onRowClicked(event) {
            const selectedRcd = event.data;
            that.props.doOperationRecordAction({
              action: "SHOW_DETAIL",
              detail: selectedRcd,
            });
          },
        },

        getDetailRowData: function (params) {
          params.successCallback(params.data.detialList);
        },
        getRowNodeId: function (data) {
          return data.id;
        },
        template:
          '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
          '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">  Detail</div>' +
          '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
          "</div>",
      },
      selectedRow: null,
    };
  }
  //   onRowClicked = (event) => {
  //     const selectedRcd = event.data;
  //     this.props.doOperationRecordAction({action:'SHOW_DETAIL', detail: selectedRcd});
  // }
  sendHistoryAttachementCellRender = (node) => {
    const attachement = node.data.filepath;
    if (!attachement) {
      return "";
    }
    const attArray = attachement.split(";");
    const retElement = document.createElement("div");
    let first = true;
    attArray.forEach((e) => {
      if (!first) {
        retElement.append(" | ");
      }
      first = false;

      const url = this.props.rotationReviewDownloadUrl + "/" + e;
      const link = document.createElement("a");
      link.innerText = e;
      link.target = "_blank";
      link.href = url;
      retElement.appendChild(link);
    });
    return retElement;
  };
  componentDidMount() {
    const { title, participant, state, startTime, endTime, speaker } = this.props.rotationReviewQueryFields;
    const person = participant;
    this.props.loadRestrictedRuleList({
      title,
      person,
      starttime: !!startTime ? startTime.format("YYYY-MM-DD") : null,
      state,
      classType: "Rotation",
    });
    // this.props.loadRestrictedRuleList({});
  }

  getRowNodeId = (data) => {
    return data.id;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    // this.columnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  refreshData = () => {
    const { title, participant, type, startTime, endTime, speaker } = this.props.rotationReviewQueryFields;
    const person = participant;
    this.props.loadRestrictedRuleList({
      title,
      person,
      type,
      starttime: !!startTime ? startTime.format("YYYY-MM-DD") : null,
      endtime: !!endTime ? endTime.format("YYYY-MM-DD") : null,
      speaker,
      classType: "Rotation",
    });
    // this.props.loadRestrictedRuleList({});
  };

  addBtnClick = () => {
    this.props.openRestrictedRuleDialog("NEW", {
      isFundBookMatched: 0,
    });
  };

  updateBtnClick = () => {
    const { selectedRow } = this.state;
    if (selectedRow) {
      this.props.openRestrictedRuleDialog("EDIT", selectedRow);
    }
  };

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(
      (rowNode) => rowNode.data
    );

    this.setState({
      selectedRow: restrictedSecurityRequestArray[0],
    });
  };
  getRScore = (array) => {
    if (array.length > 0) {
      let rArray = array
        .filter((r) => r.required === "M")
        .reduce(function (prev, cur) {
          return parseInt(cur.score) + prev;
        }, 0);
      return rArray;
    } else {
      return 0;
    }
  };

  getOScore = (array) => {
    if (array.length > 0) {
      let rArray = array
        .filter((r) => r.required === "O")
        .reduce(function (prev, cur) {
          return parseInt(cur.score) + prev;
        }, 0);
      return rArray;
    } else {
      return 0;
    }
  };
  onInputChange = ({ name, value }) => {
    if (name === "startTime" || name === "endTime") {
      value = moment(value);
    }
    this.props.onQueryInputChange({ name, value });
  };

  render() {
    const {
      restrictedRuleList,
      typeOptions,
      instituteUserAccountOptions,
      className = "grid-wrapper",
    } = this.props;
    const { participant, startTime, endTime } =
      this.props.rotationReviewQueryFields;
    const name = participant;

    const isShow = name !== null && name !== "";

    let filterRestrictedSecArray = restrictedRuleList.filter((rel) =>
      rel.participant && rel.participant.includes(name)
    );
    const detailShow = filterRestrictedSecArray.length > 0 ? true : false;
    const rScore = this.getRScore(filterRestrictedSecArray);
    const oScore = this.getOScore(filterRestrictedSecArray);
    //style={{height: '100px'}}
    return (
      <div className="RotationReviewGrid">
        <div className="operationBar">
          <div className="breadcrumbWrapper">
            <Breadcrumb>
              <Breadcrumb.Item> Search by:</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <Input 
              allowClear={true}
              placeholder="Participant Name"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'participant', value: event.target.value })
              }} 
          /> */}
          <Select
            allowClear
            showSearch
            style={{ width: 200, marginLeft: 10 }}
            onChange={(val) => {
              this.onInputChange({ name: "participant", value: val });
            }}
            placeholder="Select participant"
          >
            {instituteUserAccountOptions}
          </Select>
          <Select
            allowClear
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            onChange={(val) => {
              this.onInputChange({ name: "type", value: val });
            }}
            placeholder="type"
          >
            {typeOptions}
          </Select>

          <DatePicker
            allowClear={true}
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            placeholder=" Date Start"
            value={startTime}
            onChange={(startTime, dateString) => {
              this.onInputChange({ name: "startTime", value: dateString });
            }}
          />
          <DatePicker
            allowClear={true}
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            placeholder="Date End"
            value={endTime}
            onChange={(endTime, dateString) => {
              this.onInputChange({ name: "endTime", value: dateString });
            }}
          />
          <Input
            allowClear={true}
            placeholder="Speaker"
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            onPressEnter={this.refreshBtnOnClicked}
            onChange={(event) => {
              this.onInputChange({
                name: "speaker",
                value: event.target.value,
              });
            }}
          />
          <Button
            onClick={this.refreshData}
            // loading={refreshBtnIsLoading}
            size="small"
            style={{ marginLeft: 10 }}
            type="primary"
            icon="search"
          />
          {/* <Button 
            onClick={this.refreshData} 
            size='small'
            className='positive-button'>
            
          </Button> */}
        </div>

        <div className="operationBar">
          {detailShow && (
            <Message negative>
              <Message.Header>
                {name} Mandatory Score- {rScore}, Optional Score- {oScore}.
              </Message.Header>
            </Message>
          )}
        </div>

        <div
          className={`ag-theme-balham ${className}`}
          style={{ height: "85%" }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={restrictedRuleList}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        </div>
        <OperationRecordDetail {...this.props} />
      </div>
    );
  }
}

export default RestrictedSecurityRuleGrid;

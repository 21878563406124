import {connect} from 'react-redux';
import RotationReviewDashboard from '../components/RotationReviewDashboard';


import {
    // query
    onQueryInputChange,
    loadRotationReviewInfo,
    initLoadRotationReviewInfoActionStatus,
    // select
    selectRotationReviewInfo,
    // add or edit
    openAddOrEditRotationReviewModal,
    addOrEditRotationReviewChangeInput,
    addOrEditRotationReview,
    initAddOrEditRotationReviewActionStatus,
    resetAddOrEditRotationReviewModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelRotationReviewModal,
    delRotationReviewChangeInput,
    delRotationReview,
    initDelRotationReviewActionStatus,
    resetDelRotationReviewModal,

    //cop
    openCopRotationReviewModal,
    copRotationReviewChangeInput,
    copRotationReview,
    initCopRotationReviewActionStatus,
    resetCopRotationReviewModal,

      // Select Answeree
    selectAnswerees,
    initSelectAnswereesActionStatus,

    loadRestrictedRuleList,

    loadRotationScoreList,
    upRotationScore,
    openFinishTaskModal,

    doOperationRecordAction,

    loadInstituteUserAccounts,

} from '../RotationReviewActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.rotationReview.statusOptions,
        typeOptions: state.rotationReview.typeOptions,
        fundFirmRelatedOptions: state.rotationReview.fundFirmRelatedOptions,
        ccyOptions: state.rotationReview.ccyOptions,
        // userAccountOptions: state.rotationReview.userAccountOptions,
        // rotationReviewQueryFields
        rotationReviewQueryFields: state.rotationReview.rotationReviewQueryFields,
        // query
        loadRotationReviewInfoActionStatus: state.rotationReview.loadRotationReviewInfoActionStatus,
        loadRotationReviewInfoResp: state.rotationReview.loadRotationReviewInfoResp,
       
        restrictedRuleList:  state.rotationReview.restrictedRuleList,
        rotationScoreList:  state.rotationReview.rotationScoreList,
        selectedRotationReviewInfoArray: state.rotationReview.selectedRotationReviewInfoArray,
        rotationReviewGridColumns: state.rotationReview.rotationReviewGridColumns,
        // add or edit
        addOrEditRotationReviewModal: state.rotationReview.addOrEditRotationReviewModal,
        addOrEditRotationReviewStatus: state.rotationReview.addOrEditRotationReviewStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.rotationReview.refreshFxStatus,
        refreshFxResp: state.rotationReview.refreshFxResp,
        // del
        delRotationReviewModal: state.rotationReview.delRotationReviewModal,
        delRotationReviewStatus: state.rotationReview.delRotationReviewStatus,
        // cop
        copRotationReviewModal: state.rotationReview.copRotationReviewModal,
        copRotationReviewStatus: state.rotationReview.copRotationReviewStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        rotationReviewUploadUrl: state.rotationReview.rotationReviewUploadUrl,
        rotationReviewDownloadUrl: state.rotationReview.rotationReviewDownloadUrl,
        finishTaskModal: state.rotationReview.finishTaskModal,

        instituteUserAccountOptions: state.rotationReview.instituteUserAccountOptions,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadRotationReviewInfo: (params) => dispatch(loadRotationReviewInfo(params)),
        initLoadRotationReviewInfoActionStatus: () => dispatch(initLoadRotationReviewInfoActionStatus()),
        // select
        selectRotationReviewInfo: (dataArray) => dispatch(selectRotationReviewInfo(dataArray)),
        // add or edit
        openAddOrEditRotationReviewModal: (mode, data) => dispatch(openAddOrEditRotationReviewModal(mode, data)),
        addOrEditRotationReviewChangeInput: (params) => dispatch(addOrEditRotationReviewChangeInput(params)),
        addOrEditRotationReview: (info) => dispatch(addOrEditRotationReview(info)),
        initAddOrEditRotationReviewActionStatus: () => dispatch(initAddOrEditRotationReviewActionStatus()),
        resetAddOrEditRotationReviewModal: () => dispatch(resetAddOrEditRotationReviewModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelRotationReviewModal: (info,mode) => dispatch(openDelRotationReviewModal(info,mode)),
        delRotationReviewChangeInput: (params) => dispatch(delRotationReviewChangeInput(params)),
        delRotationReview: (info) => dispatch(delRotationReview(info)),
        initDelRotationReviewActionStatus: () => dispatch(initDelRotationReviewActionStatus()),
        resetDelRotationReviewModal: () => dispatch(resetDelRotationReviewModal()),

        // cop
        openCopRotationReviewModal: (info) => dispatch(openCopRotationReviewModal(info)),
        copRotationReviewChangeInput: (params) => dispatch(copRotationReviewChangeInput(params)),
        copRotationReview: (params) => dispatch(copRotationReview(params)),
        initCopRotationReviewActionStatus: () => dispatch(initCopRotationReviewActionStatus()),
        resetCopRotationReviewModal: () => dispatch(resetCopRotationReviewModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),

        loadRestrictedRuleList: (params) => dispatch(loadRestrictedRuleList(params)),

        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),

        loadRotationScoreList: (params) => dispatch(loadRotationScoreList(params)),
        upRotationScore: (preliminaryTrades) => dispatch(upRotationScore(preliminaryTrades)),

        loadInstituteUserAccounts: (params) => dispatch(loadInstituteUserAccounts(params)),

    };
}


export default connect (
    
    mapStateToProps,
    mapDispatchToProps
)(RotationReviewDashboard);
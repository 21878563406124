import React, { Component } from "react";
import { Breadcrumb, Select, DatePicker, Button, message, Input } from "antd";
import { ACTION_STATUS } from "../../../../../utils/ActionStatus";
import "./MeetingAndTrainingGroupDashboard.css";
import MeetingAndTrainingGroupGrid from "./MeetingAndTrainingGroupGrid";
import AddOrEditMeetingAndTrainingGroupModalDialog from "./AddOrEditMeetingAndTrainingGroupModalDialog";
import DelMeetingAndTrainingGroupModalDialog from "./DelMeetingAndTrainingGroupModalDialog";
import CopMeetingAndTrainingGroupModalDialog from "./CopMeetingAndTrainingGroupModalDialog";
import AnswereeSelectDialog from "./AnswereeSelectDialog";
import OperationRecordDialog from "../../../common/operationRcd/components/OperationRecordDialog";

class MeetingAndTrainingGroupDashboard extends Component {
  componentDidMount() {
    const { cpty, functiontype, fundandcompany, userName, status } =
      this.props.meetingAndTrainingGroupQueryFields;
    this.props.loadMeetingAndTrainingGroupInfo({
      cpty: undefined,
      functiontype: undefined,
      fundandcompany: undefined,
      userName: undefined,
      status: "Active",
    });
    this.props.loadInstituteUserAccounts({})
  }

  componentDidUpdate() {
    const {
      // query
      loadMeetingAndTrainingGroupInfoActionStatus,
      loadMeetingAndTrainingGroupInfoResp,
    } = this.props;

    if (loadMeetingAndTrainingGroupInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadMeetingAndTrainingGroupInfoActionStatus();
    }
    if (loadMeetingAndTrainingGroupInfoActionStatus === ACTION_STATUS.ERROR) {
      let err = "Load User Access Info failed";
      if (
        !!loadMeetingAndTrainingGroupInfoResp &&
        !!loadMeetingAndTrainingGroupInfoResp.respMessage
      ) {
        err = loadMeetingAndTrainingGroupInfoResp.respMessage;
      }
      message.error(err);
      this.props.initLoadMeetingAndTrainingGroupInfoActionStatus();
    }
  }

  onInputChange = ({ name, value }) => {
    this.props.onQueryInputChange({ name, value });
  };

  addOrEditBtnOnClicked = (mode) => {
    if (mode === "ADD") {
      this.props.openAddOrEditMeetingAndTrainingGroupModal(mode, null);
    } else {
      const { selectedMeetingAndTrainingGroupInfoArray } = this.props;
      if (selectedMeetingAndTrainingGroupInfoArray.length !== 1) {
        message.warning("Please choose one and only one User Access Info");
        return;
      }
      const currentMeetingAndTrainingGroupInfo =
        selectedMeetingAndTrainingGroupInfoArray[0];
      this.props.openAddOrEditMeetingAndTrainingGroupModal(
        mode,
        currentMeetingAndTrainingGroupInfo
      );
    }
  };
  copyAllBtnOnClicked = () => {
    const { selectedMeetingAndTrainingGroupInfoArray } = this.props;
    if (selectedMeetingAndTrainingGroupInfoArray.length !== 1) {
      message.warning("Please choose one and only one User Access Info");
      return;
    }
    const currentMeetingAndTrainingGroupInfo =
      selectedMeetingAndTrainingGroupInfoArray[0];
    this.props.openCopMeetingAndTrainingGroupModal(
      currentMeetingAndTrainingGroupInfo
    );
  };

  delBtnOnClicked = (mode) => {
    const { selectedMeetingAndTrainingGroupInfoArray } = this.props;
    if (selectedMeetingAndTrainingGroupInfoArray.length !== 1) {
      message.warning("Please choose one and only one User Access Info");
      return;
    }
    const currentMeetingAndTrainingGroupInfo =
      selectedMeetingAndTrainingGroupInfoArray[0];
    this.props.openDelMeetingAndTrainingGroupModal(
      currentMeetingAndTrainingGroupInfo,
      mode
    );
  };

  refreshBtnOnClicked = () => {
    const { title, functiontype, fundandcompany, userName, groupname, status } =
      this.props.meetingAndTrainingGroupQueryFields;
    this.props.loadMeetingAndTrainingGroupInfo({
      title,
      functiontype,
      fundandcompany,
      userName,
      groupname,
      status,
    });
  };

  selAnswereeButtonOnClicked = () => {
    const { selectedMeetingAndTrainingGroupInfoArray } = this.props;

    if (selectedMeetingAndTrainingGroupInfoArray.length !== 1) {
      message.warning("Please select one questionnaire!");
      return;
    }

    this.props.loadUserAccounts(); // 每次打开页面需要先刷新userAccount
    this.props.openAnswereeSelectModalDialog();
  };

  // 操作记录页面，自定义需要展示的列
  opRcdCustomColumns = [
    "operator",
    "operateTime",
    "dataId",
    "operateType",
    "comments",
    "before",
    "after",
  ];

  render() {
    const {
      statusOptions,
      meetingAndTrainingGroupOptions,
      meetingAndTrainingGroupQueryFields,
      loadMeetingAndTrainingGroupInfoActionStatus,
    } = this.props;

    const { userName, status } = meetingAndTrainingGroupQueryFields;

    const refreshBtnIsLoading =
      loadMeetingAndTrainingGroupInfoActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="MeetingAndTrainingGroupWrapper">
        <div className="breadcrumbWrapper">
          <Breadcrumb>
            <Breadcrumb.Item></Breadcrumb.Item>
            <Breadcrumb.Item>M&T Group</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="OperationBar">
          <div className="breadcrumbWrapper">
            <Breadcrumb>
              <Breadcrumb.Item> Search by:</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Input
            size="small"
            style={{ width: 150, marginLeft: 10 }}
            placeholder="GroupName"
            onPressEnter={this.refreshBtnOnClicked}
            onChange={(event) => {
              this.onInputChange({
                name: "groupname",
                value: event.target.value,
              });
            }}
          />
          {/* <Input 
                        placeholder="Function"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'functiontype', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="Fund/Co."
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'fundandcompany', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="User"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'userName', value: event.target.value })
                        }} 
                    /> */}
          {/* <Select
                        allowClear
                        showSearch
                        size='small'
                        // --value={!user ? undefined : user}
                        style={{marginLeft: 10, width: 150}}
                        //onChange={(value) => this.onInputChange({name: 'userName', value: value})}
                        onChange={(val) => {
                            this.onInputChange({ name: 'userName', value: val })
                        }} 
                        placeholder="Employee">
                        {meetingAndTrainingGroupOptions}
                    </Select> */}
          {/* <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'status', value: val })
                        }} 
                        placeholder="Status">
                        {statusOptions}
                    </Select> */}
          {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date Start"
                      onChange={(invDateStart, dateString) => {
                        this.onInputChange({ name: 'invDateStart', value: dateString });
                      }}
                    /> */}
          {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date End"
                      onChange={(invDateEnd, dateString) => {
                        this.onInputChange({ name: 'invDateEnd', value: dateString });
                      }}
                    /> */}
          <Button
            onClick={this.refreshBtnOnClicked}
            loading={refreshBtnIsLoading}
            size="small"
            style={{ marginLeft: 10 }}
            type="primary"
            icon="search"
          />
          <Button
            onClick={() => this.addOrEditBtnOnClicked("ADD")}
            size="small"
            style={{ fontSize: 10, marginLeft: 10 }}
            className="positive-button"
          >
            Add
          </Button>
          {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                   <Button 
                        onClick={()=>this.copyAllBtnOnClicked()} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy All
                    </Button> */}
          <Button
            onClick={() => this.addOrEditBtnOnClicked("EDIT")}
            size="small"
            style={{ fontSize: 10, marginLeft: 10 }}
            type="primary"
          >
            Edit
          </Button>
          {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDITALL')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit Series
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('inactive')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Inactivate User
                    </Button> */}
          <Button
            onClick={() => this.delBtnOnClicked("del")}
            size="small"
            style={{ fontSize: 10, marginLeft: 10 }}
            className="negative-button"
          >
            Del
          </Button>
          {/* <Button 
                        onClick={this.selAnswereeButtonOnClicked} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Select Answeree
                    </Button> */}
          {/* <Button 
                        onClick={()=>this.delBtnOnClicked('send')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Send Email
                    </Button>
                     <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'MeetingAndTrainingGroup'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button>  */}
        </div>

        <div className="MeetingAndTrainingGroupGrid">
          <MeetingAndTrainingGroupGrid {...this.props} />
        </div>
        <AddOrEditMeetingAndTrainingGroupModalDialog {...this.props} />
        <DelMeetingAndTrainingGroupModalDialog {...this.props} />
        <CopMeetingAndTrainingGroupModalDialog {...this.props} />
        <OperationRecordDialog {...this.props} />
        <AnswereeSelectDialog {...this.props} />
      </div>
    );
  }
}

export default MeetingAndTrainingGroupDashboard;

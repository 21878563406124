import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import '@ag-grid-enterprise/all-modules';
class MeetingAndTrainingCalenderGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
            //     {
            //     field: 'meetingid',
            //     headerName: "MeetingID",
            //     cellClass: 'non-number',
            //     width: 80,
            //     cellRenderer: 'agGroupCellRenderer',
            //     // headerCheckboxSelection: true,
            //     // headerCheckboxSelectionFilteredOnly: true,
            //     // checkboxSelection: true
            // },
            {
                field: 'broker',
                headerName: "Broker*",
                cellClass: 'non-number',
                width: 300,
                cellRenderer: 'agGroupCellRenderer',
            },
            {
                field: 'month',
                headerName: "Month",
                cellClass: 'non-number',
                width: 280,
            },
            {
                field: 'meetingNum',
                headerName: "meetingNum",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'expertCallNum',
                headerName: "ExpertCallNum",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'analystMeetingNum',
                headerName: "AnalystMeetingNum",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'corporateAccessNum',
                headerName: "CorporateAccessNum",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'luncheonNum',
                headerName: "LuncheonNum",
                cellClass: 'non-number',
                width: 100,
                // hide: true,
            },
            {
                field: 'score',
                headerName: "score",
                cellClass: 'non-number',
                width: 100,
                editable: true,
            },
            {
                field: 'scorefinal',
                headerName: "FinalScore",
                cellClass: 'non-number',
                width: 100,
                // editable: true,
            },
            // {
            //     field: 'start',
            //     headerName: "StartTime*",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
            // {
            //     field: 'end',
            //     headerName: "EndTime*",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
            // {
            //     field: 'comperename',
            //     headerName: "Coach",
            //     cellClass: 'non-number',
            //     width: 200,
            // },
            // {
            //     field: 'desc',
            //     headerName: "Desc*",
            //     cellClass: 'non-number',
            //     width: 300,
            // },
            // {
            //     field: 'score',
            //     headerName: "Credit",
            //     cellClass: 'non-number',
            //     width: 80,
            // },
            // {
            //     field: 'confirmRNumReat',
            //     headerName: "Mandatory Participation Rate",
            //     cellClass: 'non-number',
            //     width: 200,
            // },
            // {
            //     field: 'confirmONumReat',
            //     headerName: "Optional Participation",
            //     cellClass: 'non-number',
            //     width: 200,
            // },

            // {
            //     field: 'speaker',
            //     headerName: "Speaker",
            //     cellClass: 'non-number',
            //     width: 150,
            // },

        ],
        frameworkComponents: {
        },
        
        defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 50
        },



        rowSelection: "multiple",
        context: { componentParent: this },
            
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        // {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                       
                        {
                            field: 'subject',
                            headerName: "Subject*",
                            cellClass: 'non-number',
                            width: 100,
                            // cellRenderer: (params) => {
                             
                            //     return params.data.starttime.substring(0, 10);;
                            // },
                        },
                        {field: 'type', headerName: 'Meeting Type', cellClass: 'non-number', width: 150},
                        {field: 'start', headerName: 'start time', cellClass: 'non-number', width: 150},
                        {field: 'score', headerName: 'score', cellClass: 'non-number', width: 150},
                        {field: 'peopelNum', headerName: 'peopelNum', cellClass: 'non-number', width: 150},
                        // {
                        //     field: 'comperename',
                        //     headerName: "Coach",
                        //     cellClass: 'non-number',
                        //     width: 200,
                        // },
                        // {
                        //     field: 'requiredattendees',
                        //     headerName: "Name",
                        //     cellClass: 'non-number',
                        //     width: 200,
                        // },
                        
                        // {
                        //     field: 'confirmRNumReat',
                        //     headerName: "Mandatory Participation Rate",
                        //     cellClass: 'non-number',
                        //     width: 200,
                        // },
 
                        // {field: 'meetingID', headerName: 'id', cellClass: 'non-number', width: 120,hide:true},
                        {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},

                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectMeetingAndTrainingCalenderInfo(cptRcdDataArray);
                    },
                    defaultColDef:{
                        enableCellChangeFlash: true,
                        minWidth: 50,
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                        tooltipValueGetter: (params) => {
                          return params.value;
                        }
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.detialList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">  Detail</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingCalenderDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.closeToolPanel();
      };
    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let meetingAndTrainingCalenderInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectMeetingAndTrainingCalenderInfo(meetingAndTrainingCalenderInfoArray);
    }
    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
        // if(data.status === 'Finished') {
        //     return retDiv;
        // }
        const finishTaskBtn = document.createElement('button');
        const finishTaskText = document.createTextNode("ShowDetail");
        finishTaskBtn.appendChild(finishTaskText);
        finishTaskBtn.onclick = () => {
            props.openFinishTaskModal({borker:data.borker,subject:data.subject});
        }
        retDiv.appendChild(finishTaskBtn);
        return retDiv;
    }
    dataColorCellStyle(param) {
        const status = param.value;
        if(status === 'true') {
            // return {color: 'green'};
            return { background: '#fcf800 '};
        }
        
        
    }
    onCellValueChanged = params => {
        const colId = params.column.getId();

        let trades = []
        trades.push(params.data)
    
        if (colId == 'score' && params.oldValue !== params.newValue) {

          this.props.addOrEditMeetingAndTrainingCalender({
              brokerCode: trades[0].broker,
              month: trades[0].start,
              userName: 'lu.xin',
              score: trades[0].score,
              mode: 'UPSCORE'
        });  
        }

      }
    render() {

        const {
            // query
            loadMeetingAndTrainingCalenderInfoActionStatus,
            loadMeetingAndTrainingCalenderInfoResp,
        } = this.props;


        const isLoading = loadMeetingAndTrainingCalenderInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadMeetingAndTrainingCalenderInfoArray = [];
        if(!!loadMeetingAndTrainingCalenderInfoResp && !!loadMeetingAndTrainingCalenderInfoResp.data) {
            loadMeetingAndTrainingCalenderInfoArray = loadMeetingAndTrainingCalenderInfoResp.data;
        }
//style={{height: '500px'}}

        return (
            <div className={'ag-theme-balham grid-wrapper'} >
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadMeetingAndTrainingCalenderInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            rowClassRules={this.state.rowClassRules}
                            // frameworkComponents={this.state.frameworkComponents}
                            onCellValueChanged={this.onCellValueChanged}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            masterDetail={true}
                            sideBar={true}
                            resizable={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            // onGridReady={this.onGridReady}
                            onSelectionChanged={this.onDataSelectionChanged}
                            tooltipShowDelay={0} 
                     
                        />
                    )} 
            </div>
        );
    }
}

export default MeetingAndTrainingCalenderGrid;

import numeral from 'numeral';

const moneyValueFormatter = function(params) {
  var number = params.value;
  return numeral(number).format('0,0.00');
}

const numValueFormatter = function(params) {
  var number = params.value;
  return numeral(number).format('0,0');
}

const numValueFormatterAllowEmpty = function(params) {
  var number = params.value;
  if(number === undefined || number === null || number === '') {
    return '';
  }
  return numeral(number).format('0,0');
}

const numValueCellStyle = function(params) {
  return {color: 'green', fontWeight: 'bold'};
}

const bannedTradeRequestGridColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    pinned: "left",
    width: 100,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'team',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    pinned: "left",
    width: 150,
  },
  {
    field: 'bbgTicker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true
  },
  {
    field: 'secName',
    headerName: 'Security',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'tradeDateLabel',
    headerName: 'Trade Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 120,
  },
  {
    field: 'tradeSource',
    headerName: 'Trade Source',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 120,
  },
  {
    field: 'side',
    headerName: 'Side',
    width: 100,
  },
  {
    field: 'orderType',
    headerName: 'OrderType',
    enableRowGroup: true,
    width: 100,
  },
  {
    field: 'lmtPrcLocal',
    headerName: 'Lmt Price',
    cellClass: 'number',
  },
  {
    field: 'requestStatus',
    headerName: 'Req Status',
    enableRowGroup: true,
    width: 120,
  },
  {
    field: 'orderStatus',
    headerName: 'Ord Status',
    enableRowGroup: true,
    width: 120,
  },
  {
    field: 'qtyShare',
    headerName: 'Qty',
    width: 120,
  },
  {
    field: 'qtyPct',
    headerName: 'AUM %',
    width: 120,
  },
  {
    field: 'qtyUsd',
    headerName: 'Value $',
    width: 120,
  },
  {
    field: 'prevQtyFilled',
    headerName: 'Prev Filled',
    width: 120,
  },
  // {
  //   field: 'workingQuantity',
  //   headerName: 'Working Qty',
  //   width: 120,
  // },
  {
    field: 'qtyFilled',
    headerName: 'Filled',
    width: 120,
  },
  {
    field: 'pctFilled',
    headerName: 'Filled %',
    cellClass: 'number',
    width: 120,
  },
  // {
  //   field: 'executionNetPrice',
  //   headerName: 'Exec Price',
  //   cellClass: 'number',
  // },
  {
    field: 'pmName',
    headerName: 'PM Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100,
  },
  {
    field: 'pmStatus',
    headerName: 'PM Status',
    enableRowGroup: true,
    width: 100,
  },
  {
    field: 'complianceStatus',
    headerName: 'Cpl Status',
    enableRowGroup: true,
    width: 100,
  },
  {
    field: 'complianceError',
    headerName: 'Cpl Error',
    cellClass: 'comment',
  },
  {
    field: 'traderName',
    headerName: 'Trdr Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 150,
  },
  {
    field: 'traderStatus',
    headerName: 'Trdr Status',
    enableRowGroup: true,
    width: 100,
  },
  {
    field: 'bookStatus',
    headerName: 'Book Status',
    enableRowGroup: true,
    width: 120,
  },
  {
    field: 'traderFeedback',
    headerName: 'Trdr Feedback',
    cellClass: 'comment',
  },
  {
    field: 'pmRemark',
    headerName: 'PM Remark',
    cellClass: 'comment'
  },
  {
    field: 'pmReason',
    headerName: 'PM Reason',
    cellClass: 'comment',
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100,
  },
  {
    field: 'timeInForce',
    headerName: 'TIF',
    enableRowGroup: true,
    width: 100,
  },
  {
    field: 'country',
    headerName: 'Country',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100,
  },
  {
    field: 'reqTimeLabel',
    headerName: 'Req Time',
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'lastModifiedTimeLabel',
    headerName: 'Last MOD Time',
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'archiveDateTimeLabel',
    headerName: 'Archive Time',
    cellClass: 'non-number',
    width: 140,
  }
];

const personalTradeGridColumns = [
  {
    field: 'tradeId',
    headerName: "TradeId",
    cellClass: 'non-number',
    width: 110,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    pinned: 'left',
  },
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 110,
    pinned: 'left',
  },
  {
    field: 'approvalStatusLabel',
    headerName: 'ApprStatus',
    width: 180,
    pinned: 'left',
    cellStyle: function(params) {
      if (params.value == 'Approved') {
          return {color: 'white', fontWeight: 'bold', backgroundColor: '#90EE90'};
      } else if (params.value == 'Rejected') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#FF8BA0'};
      } else if (params.value == 'PendingApproval') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#FED06C'};
      } else if (params.value == 'PendingFinalApproval') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#FFA53F'};
      }  else if (params.value == 'FinalApproved') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#E05C04'};
      } 
    },
  },
  {
    field: 'sysCheck',
    headerName: 'SysCheck',
    cellClass: 'non-number',
    width: 90,
    pinned: 'left',
    valueGetter: (params) => {
      if (params.data) {
       if (params.data.matchRestrictedList === false  && params.data.matchReportedHolding === false && 
           params.data.matchTradeYesterdayPMLocation === false) {
          return 'Pass';
        } 
      };
      return 'Fail';
    },
    cellStyle: function(params) {
      if (params.value == 'Pass') {
        return {color: 'green', fontWeight: 'bold'};
      } else if (params.value == 'Fail') {
        return {color: 'red', fontWeight: 'bold'};
      }
    },
  },
  
  {
    field: 'accountName',
    headerName: "Account Holder",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'accountNo',
    headerName: "Account No",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'brokerAccount',
    headerName: "Brokerage Firm",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'category',
    headerName: "Category",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'isPassive',
    headerName: 'IsPassive',
    cellClass: 'non-number',
    width: 80,
    valueGetter: (params) => {
      if (params.data) {
        if (params.data.isPassive === true) {
          return 'Yes';
        } else if (params.data.isPassive === false) {
          return 'No';
        } 
      };
      return '';
    }
  },
  {
    field: 'securityId',
    headerName: "Security Id",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'bbgTicker',
    headerName: "Bbg Ticker",
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'secName',
    headerName: 'Security Name',
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'stockMarket',
    headerName: 'Stock Market',
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'applyQty',
    headerName: 'Apply Qty',
    cellClass: 'number',
    width: 150,
    // valueFormatter: numValueFormatter,
    cellStyle: numValueCellStyle,
    valueFormatter: function(params) {
      if (params.data.side === 'SHORT') {
          return '-' + params.value; // 如果 side 字段为 SHORT，则在 quantity 值前面加上负号
      } else {
          return params.value; // 其他情况下不做处理，直接返回原始值
      }
    }
  },
  {
    field: 'actualQty',
    headerName: 'Actual Qty',
    cellClass: 'number',
    width: 150,
    // valueFormatter: numValueFormatterAllowEmpty,
    cellStyle: numValueCellStyle,
    valueFormatter: function(params) {
      var number = params.value;
      if(number === undefined || number === null || number === '') {
        return '';
      }
      if (params.data.side === 'SHORT' && number !== 0) {
          return '-' + params.value; // 如果 side 字段为 SHORT，则在 quantity 值前面加上负号
      } else {
          return params.value; // 其他情况下不做处理，直接返回原始值
      }
    }
  },
  {
    field: 'priceType',
    headerName: 'Price Type',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'tradeDateLabel',
    headerName: 'Trade Date',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'createdDate',
    headerName: 'Create Date',
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'holdingDays',
    headerName: 'Holding Days',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "previewTradeAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'source',
    headerName: 'Source',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'matchActiveTradeLabel',
    headerName: 'Trade Today',
    width: 120,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchRestrictedListLabel',
    headerName: 'Restricted List',
    width: 120,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchReportedHoldingLabel',
    headerName: '30 Days Holding',
    width: 140,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchTradeYesterdayLabel',
    headerName: 'Trade On Prev 1 Day',
    width: 160,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchTradeYesterdayPMLocationLabel',
    headerName: 'Chinese Wall checking',
    width: 165,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchActiveTradeReason',
    headerName: 'Match Today Trade Reason',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'matchRestrictedListReason',
    headerName: 'Match Restricted List Reason',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'matchReportedHoldingReason',
    headerName: 'Match Reported Holding Reason',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'matchTradeYesterdayReason',
    headerName: 'Match Yesterday Trade Reason',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'matchTradeYesterdayPMLocationReason',
    headerName: 'Chinese Wall checking Reason',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'isAbandonedLabel',
    headerName: 'IsAbandoned',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestStatusLabel',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'finishInDays',
    headerName: 'Finish In Days',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'remark',
    headerName: 'Remark',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 250,
  },
 
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const personalHoldingGridColumns = [
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'accountName',
    headerName: "Account Name",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'bbgTicker',
    headerName: "Bbg Ticker",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'direction',
    headerName: 'Direction',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    cellClass: 'non-number',
    width: 120,
    valueFormatter: numValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    cellClass: 'number',
    width: 120,
    valueFormatter: moneyValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 100,
  },
  // {
  //   field: 'requestStatusLabel',
  //   headerName: 'Application Status',
  //   cellClass: 'non-number',
  //   width: 150,
  // },
  // {
  //   field: 'approvalStatusLabel',
  //   headerName: 'Approval Status',
  //   cellClass: 'non-number',
  //   width: 150,
  // },
  // {
  //   field: 'approvedBy',
  //   headerName: 'ApprovedBy',
  //   cellClass: 'non-number',
  //   width: 150,
  // },
  // {
  //   field: 'approvalTimeLabel',
  //   headerName: 'Approval Time',
  //   cellClass: 'non-number',
  //   width: 150,
  // },
  {
    field: 'createdBy',
    headerName: "CreatedBy",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'createdDateLabel',
    headerName: "Created Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedBy',
    headerName: "Last ModifiedBy",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedDateLabel',
    headerName: 'Last Modified Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const personalAccountGridColumns = [
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 160,
    cellRenderer: "agGroupCellRenderer",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'accountName',
    headerName: "Account Name",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'brokerAccount',
    headerName: "Broker Account",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'accountNo',
    headerName: 'Account No',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'relationshipLabel',
    headerName: 'Relationship',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'relationshipDesc',
    headerName: 'Remark',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "previewAccountAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'source',
    headerName: 'Source',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestStatusLabel',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatusLabel',
    headerName: 'ApprStatus',
    cellClass: 'non-number',
    width: 150,
    cellRenderer: (params) => {
      if(!!params && !!params.data && params.data.approvalStatusLabel === 'Rejected') {
        return '<font color="red">' + params.data.approvalStatusLabel + '</font>';
      } else if(!!params && !!params.data && params.data.approvalStatusLabel === 'Accepted') {
        return '<font color="green">' + params.data.approvalStatusLabel + '</font>';
      } else {
        return params.data.approvalStatusLabel;
      }
    }
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'processedReason',
    headerName: 'Processed Reason',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'createdBy',
    headerName: "CreatedBy",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'createdDateLabel',
    headerName: "Created Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedBy',
    headerName: "Last ModifiedBy",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedDateLabel',
    headerName: 'Last Modified Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const giftHospitalityRequestGridColumns = [
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 150,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'dateLabel',
    headerName: "Date",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'giver',
    headerName: 'Giver',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'giverPosition',
    headerName: 'Giver Position',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'giverCompany',
    headerName: 'Giver Company',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'USDAmount',
    cellClass: 'non-number',
    width: 150,
    valueFormatter: moneyValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'localAmount',
    headerName: 'LocalAmount',
    cellClass: 'non-number',
    width: 150,
    valueFormatter: moneyValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'description',
    headerName: 'Description',
    cellClass: 'non-number',
    width: 170,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellClass: 'non-number',
    cellRenderer: "giftAttachmentRenderer",
    width: 150,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'requestStatus',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatus',
    headerName: 'Approval Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const accountReportPlanRecordGridColumns = [
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'reportPlanName',
    headerName: 'Report Plan Name',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'emailSendStatusLabel',
    headerName: "Email Send Status",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'reportStatusLabel',
    headerName: "Report Status",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'createdDateLabel',
    headerName: "Created Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'createdBy',
    headerName: "CreatedBy",
    cellClass: 'non-number',
    width: 150,
  },
];

const personalTradeRecognizeGridColumns = [
  {
    field: 'tradeId',
    headerName: "TradeId",
    cellClass: 'non-number',
    width: 110,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
  },
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 110,
  },
  {
    field: 'accountName',
    headerName: "Account Name",
    cellClass: 'non-number',
    width: 130,
  },
  {
    field: 'accountNo',
    headerName: "Account No",
    cellClass: 'non-number',
    width: 150,
    hide: true,
  },
  {
    field: 'brokerAccount',
    headerName: "Broker Account",
    cellClass: 'non-number',
    width: 200,
    hide: true,
  },
  {
    field: 'securityId',
    headerName: "Security Id",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'bbgTicker',
    headerName: "Bbg Ticker",
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'stockMarket',
    headerName: 'Stock Market',
    cellClass: 'non-number',
    width: 240,
    hide: true,
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: 'non-number',
    width: 100,
    hide: true,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    cellClass: 'number',
    width: 100,
    hide: true,

  },
  {
    field: 'priceType',
    headerName: 'Price Type',
    cellClass: 'non-number',
    width: 150,
    hide: true,
  },
  {
    field: 'marketPrice',
    headerName: 'Market Price',
    cellClass: 'number',
    width: 150,
    hide: true,
  },
  {
    field: 'buyingPrice',
    headerName: 'Buying Price',
    cellClass: 'number',
    width: 150,
    hide: true,
  },
  {
    field: 'Selling Price',
    headerName: 'sellingPrice',
    cellClass: 'number',
    width: 150,
    hide: true,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'number',
    width: 120,
    hide: true,
  },
  {
    field: 'tradeDateLabel',
    headerName: 'Trade Date',
    cellClass: 'number',
    width: 120,
    hide: true,
  },
  {
    field: 'holdingDays',
    headerName: 'Holding Days',
    cellClass: 'number',
    width: 150,
    hide: true,
  },
  {
    field: 'matchActiveTradeLabel',
    headerName: 'Trade Today',
    width: 120,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchComplianceRuleLabel',
    headerName: 'Restricted List',
    width: 120,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchReportedHoldingLabel',
    headerName: '30 Days Holding',
    width: 140,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchTradeYesterdayLabel',
    headerName: 'Trade On Prev 1 Day',
    width: 160,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
];

// ================================== Account Holding Report Plan Grid Column ================================
  
// Account Holding Report Plan Grid Columns
const accountHoldingReportPlanGridColumns = [
  {
    field: 'name',
    headerName: "Name",
    cellClass: 'non-number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'expiredDateLabel',
    headerName: "Expired Date",
    cellClass: 'non-number',
    width: 120,
  },
];

// Personal Trade Operation Record Grid Columns
const personalTradeOpRecordGridColumns = [
  {
    field: 'operator',
    headerName: "Operator",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'operationTimeLabel',
    headerName: 'Operation Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'tradeId',
    headerName: "TradeId",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'operation',
    headerName: "Operation",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'comment',
    headerName: "Comment",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'accountName',
    headerName: "Account Name",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'accountNo',
    headerName: "Account No",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'brokerAccount',
    headerName: "Broker Account",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'securityId',
    headerName: "Security Id",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'bbgTicker',
    headerName: "Bbg Ticker",
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'secName',
    headerName: 'Security Name',
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'stockMarket',
    headerName: 'Stock Market',
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'applyQty',
    headerName: 'Apply Qty',
    cellClass: 'number',
    width: 150,
    // valueFormatter: numValueFormatter,
    cellStyle: numValueCellStyle,
    valueFormatter: function(params) {
      if (params.data.side === 'SHORT') {
          return '-' + params.value; // 如果 side 字段为 SHORT，则在 quantity 值前面加上负号
      } else {
          return params.value; // 其他情况下不做处理，直接返回原始值
      }
  }
  },
  {
    field: 'actualQty',
    headerName: 'Actual Qty',
    cellClass: 'number',
    width: 150,
    valueFormatter: numValueFormatterAllowEmpty,
    cellStyle: numValueCellStyle,
    valueFormatter: function(params) {
      var number = params.value;
      if(number === undefined || number === null || number === '') {
        return '';
      }
      if (params.data.side === 'SHORT' && number !== 0) {
          return '-' + params.value; // 如果 side 字段为 SHORT，则在 quantity 值前面加上负号
      } else {
          return params.value; // 其他情况下不做处理，直接返回原始值
      }
    }
  },
  {
    field: 'priceType',
    headerName: 'Price Type',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'tradeDateLabel',
    headerName: 'Trade Date',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'holdingDays',
    headerName: 'Holding Days',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "previewTradeAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'source',
    headerName: 'Source',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'matchActiveTradeLabel',
    headerName: 'Trade Today',
    width: 120,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchRestrictedListLabel',
    headerName: 'Restricted List',
    width: 120,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchReportedHoldingLabel',
    headerName: '30 Days Holding',
    width: 140,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'matchTradeYesterdayLabel',
    headerName: 'Trade On Prev 1 Day',
    width: 160,
    cellStyle: function(params) {
      if (params.value=='Pass') {
          return {color: 'white', backgroundColor: 'green'};
      } else if (params.value=='Fail') {
        return {color: 'white', backgroundColor: 'red'};
      }
    },
  },
  {
    field: 'isAbandonedLabel',
    headerName: 'IsAbandoned',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestStatusLabel',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'finishInDays',
    headerName: 'Finish In Days',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'remark',
    headerName: 'Remark',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'approvalStatusLabel',
    headerName: 'ApprStatus',
    width: 150,
    cellStyle: function(params) {
      if (params.value=='Approved') {
          return {color: 'green', fontWeight: 'bold'};
      } else if (params.value=='Rejected') {
        return {color: 'red', fontWeight: 'bold'};
      }
    },
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'createdDateLabel',
    headerName: 'CreatedDate',
    cellClass: 'non-number',
    width: 150,
  },
];


const GridColumnMap = {
  bannedTradeRequestGridColumns,
  personalTradeGridColumns,
  personalHoldingGridColumns,
  personalAccountGridColumns,
  giftHospitalityRequestGridColumns,

  accountReportPlanRecordGridColumns,

  personalTradeRecognizeGridColumns,

  // ================================== Account Holding Report Plan Grid Column ================================
  
  // Account Holding Report Plan Grid Columns
  accountHoldingReportPlanGridColumns,

  // Personal Trade Operation Record Grid Columns
  personalTradeOpRecordGridColumns,


};

export default GridColumnMap;
import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload,InputNumber, Icon,Checkbox,Divider  } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditMeetingAndTrainingGroupModalDialog extends Component {
    componentDidMount() {

    }
    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingGroupQueryFields;
        this.props.loadMeetingAndTrainingGroupInfo({userName, status})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add Meeting & Training';
        } else if(mode === 'EDIT') {
            return 'Edit Meeting & Training';
        } else {
            return 'Copy Meeting & Training';
        }
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditMeetingAndTrainingGroupStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditMeetingAndTrainingGroupModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditMeetingAndTrainingGroupStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditMeetingAndTrainingGroupModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditMeetingAndTrainingGroupActionStatus();
            this.props.resetAddOrEditMeetingAndTrainingGroupModal();
            this.refreshQuery();
        }
        if(addOrEditMeetingAndTrainingGroupStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditMeetingAndTrainingGroupActionStatus();
        }
    }
    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'repeat', value: value});
        this.onInputChange({name: 'week', value: undefined});
        this.onInputChange({name: 'repeatEvery', value: undefined});
    }   

    onInputChange = ({name, value}) => {
        this.props.addOrEditMeetingAndTrainingGroupChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditMeetingAndTrainingGroupModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditMeetingAndTrainingGroupModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditMeetingAndTrainingGroupModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
    }


    submitAddOrEditMeetingAndTrainingGroup = () => {
        const {
            addOrEditMeetingAndTrainingGroupModal,
        } = this.props;
        const {
            mode,
        } = addOrEditMeetingAndTrainingGroupModal;
        const {
            id,
            title,
            desc,
            type,
            feedback,
            groupname,
            accountname,
            sendmail,
            comperename,
            score,
            starttime,
            endtime,
            address,
            requiredattendees,
            optionalattendees,
            repeat,
            repeatevery,
            repeatmonthly,
            week,
            functiontype,
            usertype,
            fundandcompany,
            employee,
            status,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            remarks,
          
        } = addOrEditMeetingAndTrainingGroupModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditMeetingAndTrainingGroup({
                    id,
                    title,
                    desc,
                    type,
                    feedback,
                    groupname,
                    accountname,
                    sendmail,
                    comperename,
                    score,
                    starttime,
                    endtime,
                    address,
                    requiredattendees,
                    optionalattendees,
                    repeat,
                    repeatevery,
                    repeatmonthly,
                    week,
                    functiontype,
                    usertype,
                    fundandcompany,
                    employee,
                    status,
                    validetodate,
                    uploadChartOriName,
                    uploadChartRealName,
                    remarks,
                    mode,
                });                    
            }
        });
    }
    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditMeetingAndTrainingGroupModal,
            instituteUserAccountOptions,
            statusOptions,
            typeOptions,
            weekdayOptions,
            repeatEveryOptions,
            repeatMonthlyOptions,
            repeatOptions,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditMeetingAndTrainingGroupStatus,
            meetingAndTrainingGroupUploadUrl,
            meetingAndTrainingGroupDownloadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditMeetingAndTrainingGroupModal;

        const {
            employee,
           
            usertype,
            title,
            desc,
            type,
            feedback,
            groupname,
            accountname,
            sendmail,
            comperename,
            score,
            starttime,
            endtime,
            address,
            status,
            requiredattendees,
            optionalattendees,
            repeat,
            repeatevery,
            repeatmonthly,
            week,
            functiontype,
            fundandcompany,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            chartFileList,
            remarks,

        } = addOrEditMeetingAndTrainingGroupModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const isSubmitLoading = addOrEditMeetingAndTrainingGroupStatus === ACTION_STATUS.LOGINING;
        const nameArray = !accountname ? [] : accountname.split(';');
        
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditMeetingAndTrainingGroupModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditMeetingAndTrainingGroupModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditMeetingAndTrainingGroup}>
                          Submit
                        </Button>,
                      ]}
                >
                <Form layout="horizontal">

                    <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="GroupName">
                                {getFieldDecorator('title', {
                                    initialValue: groupname,
                                    rules: [{
                                        required: true,
                                        message: 'Please input GroupName*',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'groupname', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="User Name">
                                        {getFieldDecorator('userName', {
                                            initialValue: nameArray,
                                            rules: [{
                                                required: true,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                mode='multiple'
                                                style={{ width: 500, marginLeft: 10 }}                        
                                                placeholder='Please select User Name' 
                                                
                                                onChange={(val) => {
                                                    this.onEmailInputChange({ name: 'accountname', value: val })
                                                }}
                                                // dropdownRender={menu => (
                                                //     <>
                                                //       {menu}
                                                //       <Divider style={{ margin: '8px 0' }} />
                                                //       <Space style={{ padding: '0 8px 4px' }}>
                                                //         <Input
                                                //           placeholder="Please enter item"
                                                //           ref={inputRef}
                                                //           //value={name}
                                                //           onChange={onNameChange}
                                                //         />
                                                //         <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                //           Add item
                                                //         </Button>
                                                //       </Space>
                                                //     </>
                                                //   )}
                                            >
                                                {instituteUserAccountOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>       

                        </Row>
                </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditMeetingAndTrainingGroupModalDialogForm = Form.create({ name: 'AddOrEditMeetingAndTrainingGroupModalDialog' })(AddOrEditMeetingAndTrainingGroupModalDialog);
export default AddOrEditMeetingAndTrainingGroupModalDialogForm;

// query
export const ON_QUERY_INPUT_CHANGE = "ON_QUERY_INPUT_CHANGE";
export const START_LOAD_BROKER_REVIEW_INFO = "START_LOAD_BROKER_REVIEW_INFO";
export const LOAD_BROKER_REVIEW_INFO_SUCCESS =
  "LOAD_BROKER_REVIEW_INFO_SUCCESS";
export const LOAD_BROKER_REVIEW_INFO_FAILURE =
  "LOAD_BROKER_REVIEW_INFO_FAILURE";
export const INIT_LOAD_BROKER_REVIEW_INFO_ACTION_STATUS =
  "INIT_LOAD_BROKER_REVIEW_INFO_ACTION_STATUS";
// select
export const SELECT_BROKER_REVIEW_INFO = "SELECT_BROKER_REVIEW_INFO";
// add or edit
export const OPEN_ADD_OR_EDIT_BROKER_REVIEW_MODAL =
  "OPEN_ADD_OR_EDIT_BROKER_REVIEW_MODAL";
export const ADD_OR_EDIT_BROKER_REVIEW_CHANGE_INPUT =
  "ADD_OR_EDIT_BROKER_REVIEW_CHANGE_INPUT";
export const START_ADD_OR_EDIT_BROKER_REVIEW =
  "START_ADD_OR_EDIT_BROKER_REVIEW";
export const ADD_OR_EDIT_BROKER_REVIEW_SUCCESS =
  "ADD_OR_EDIT_BROKER_REVIEW_SUCCESS";
export const ADD_OR_EDIT_BROKER_REVIEW_FAILURE =
  "ADD_OR_EDIT_BROKER_REVIEW_FAILURE";
export const INIT_ADD_OR_EDIT_BROKER_REVIEW_ACTION_STATUS =
  "INIT_ADD_OR_EDIT_BROKER_REVIEW_ACTION_STATUS";
export const RESET_ADD_OR_EDIT_BROKER_REVIEW_MODAL =
  "RESET_ADD_OR_EDIT_BROKER_REVIEW_MODAL";
// refresh fx and amtUSD
export const START_REFRESH_FX = "START_REFRESH_FX";
export const REFRESH_FX_SUCCESS = "REFRESH_FX_SUCCESS";
export const REFRESH_FX_FAILURE = "REFRESH_FX_FAILURE";
export const INIT_REFRESH_FX_ACTION_STATUS = "INIT_REFRESH_FX_ACTION_STATUS";
export const REFRESH_AMT_USD = "REFRESH_AMT_USD";
// del
export const OPEN_DEL_BROKER_REVIEW_MODAL = "OPEN_DEL_BROKER_REVIEW_MODAL";
export const DEL_BROKER_REVIEW_CHANGE_INPUT = "DEL_BROKER_REVIEW_CHANGE_INPUT";
export const START_DEL_BROKER_REVIEW = "START_DEL_BROKER_REVIEW";
export const DEL_BROKER_REVIEW_SUCCESS = "DEL_BROKER_REVIEW_SUCCESS";
export const DEL_BROKER_REVIEW_FAILURE = "DEL_BROKER_REVIEW_FAILURE";
export const INIT_DEL_BROKER_REVIEW_ACTION_STATUS =
  "INIT_DEL_BROKER_REVIEW_ACTION_STATUS";
export const RESET_DEL_BROKER_REVIEW_MODAL = "RESET_DEL_BROKER_REVIEW_MODAL";

// cop
export const OPEN_COP_BROKER_REVIEW_MODAL = "OPEN_COP_BROKER_REVIEW_MODAL";
export const COP_BROKER_REVIEW_CHANGE_INPUT = "COP_BROKER_REVIEW_CHANGE_INPUT";
export const START_COP_BROKER_REVIEW = "START_COP_BROKER_REVIEW";
export const COP_BROKER_REVIEW_SUCCESS = "COP_BROKER_REVIEW_SUCCESS";
export const COP_BROKER_REVIEW_FAILURE = "COP_BROKER_REVIEW_FAILURE";
export const INIT_COP_BROKER_REVIEW_ACTION_STATUS =
  "INIT_COP_BROKER_REVIEW_ACTION_STATUS";
export const RESET_COP_BROKER_REVIEW_MODAL = "RESET_COP_BROKER_REVIEW_MODAL";

export const OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG =  "OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG";
export const CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG =  "CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG";
export const RESTRICTED_RULE_INPUT_CHANGE = "RESTRICTED_RULE_INPUT_CHANGE";

export const START_LOAD_BROKER_REVIEW_INDIVIDUAL = "START_LOAD_BROKER_REVIEW_INDIVIDUAL";
export const LOAD_BROKER_REVIEW_INDIVIDUAL_SUCCESS =  "LOAD_BROKER_REVIEW_INDIVIDUAL_SUCCESS";
export const LOAD_BROKER_REVIEW_INDIVIDUAL_FAILURE =  "LOAD_BROKER_REVIEW_INDIVIDUAL_FAILURE";

export const START_LOAD_BROKER_REVIEW_PURPOSE = "START_LOAD_BROKER_REVIEW_PURPOSE";
export const LOAD_BROKER_REVIEW_PURPOSE_SUCCESS = "LOAD_BROKER_REVIEW_PURPOSE_SUCCESS";
export const LOAD_BROKER_REVIEW_PURPOSE_FAILURE = "LOAD_BROKER_REVIEW_PURPOSE_FAILURE";

export const LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS = "LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS";
export const LOAD_INSTITUTE_USER_ACCOUNT_FAILURE = "LOAD_INSTITUTE_USER_ACCOUNT_FAILURE";

import React from "react";
import { Select } from "antd";
import { createReducer } from "../../../../utils/reducerUtil";
import { ACTION_STATUS } from "../../../../utils/ActionStatus";
import GridColumnMap from "./GridColumnMap";
import BaseUrlConfig from "../../../../net/BaseUrlConfig";
import UrlConfig from "../../../../net/UrlConfig";
import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_RATING_MANAGEMENT_INFO,
  LOAD_RATING_MANAGEMENT_INFO_SUCCESS,
  LOAD_RATING_MANAGEMENT_INFO_FAILURE,
  INIT_LOAD_RATING_MANAGEMENT_INFO_ACTION_STATUS,
  // select
  SELECT_RATING_MANAGEMENT_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL,
  ADD_OR_EDIT_RATING_MANAGEMENT_CHANGE_INPUT,
  START_ADD_OR_EDIT_RATING_MANAGEMENT,
  ADD_OR_EDIT_RATING_MANAGEMENT_SUCCESS,
  ADD_OR_EDIT_RATING_MANAGEMENT_FAILURE,
  INIT_ADD_OR_EDIT_RATING_MANAGEMENT_ACTION_STATUS,
  RESET_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_RATING_MANAGEMENT_MODAL,
  DEL_RATING_MANAGEMENT_CHANGE_INPUT,
  START_DEL_RATING_MANAGEMENT,
  DEL_RATING_MANAGEMENT_SUCCESS,
  DEL_RATING_MANAGEMENT_FAILURE,
  INIT_DEL_RATING_MANAGEMENT_ACTION_STATUS,
  RESET_DEL_RATING_MANAGEMENT_MODAL,

  // del
  OPEN_COP_RATING_MANAGEMENT_MODAL,
  COP_RATING_MANAGEMENT_CHANGE_INPUT,
  START_COP_RATING_MANAGEMENT,
  COP_RATING_MANAGEMENT_SUCCESS,
  COP_RATING_MANAGEMENT_FAILURE,
  INIT_COP_RATING_MANAGEMENT_ACTION_STATUS,
  RESET_COP_RATING_MANAGEMENT_MODAL,
  OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  RESTRICTED_RULE_INPUT_CHANGE,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./RatingManagementConstants";

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from "../../home/homeConstants";
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={"Active"}>{"Active"}</Option>);
statusOptions.push(<Option key={"Inactive"}>{"Inactive"}</Option>);

const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {
  ratingManagementUploadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.RATING_MANAGEMENT_UPLOAD_URL,
  ratingManagementDownloadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.RATING_MANAGEMENT_DOWNLOAD_URL,
  ratingManagementGridColumns: GridColumnMap.ratingManagementGridColumns,

  statusOptions: statusOptions,
  fundFirmRelatedOptions: fundFirmRelatedOptions,
  ccyOptions: ccyOptions,

  ratingManagementQueryFields: {
    userName: "",
    status: "Active",
  },
  selectedRatingManagementInfoArray: [],
  addOrEditRatingManagementModal: {
    isOpened: false,
    mode: "ADD",
    respErrMsg: "",
    fields: {
      id: null,
      guid: "",
      category: "",
      firm: "",
      fundFirmRelated: "",
      invDate: "",
      invNo: "",
      ccy: "",
      amount: "",
      fx: "",
      amtUSD: "",
      payDate: "",
      nature: "",
      remarks: "",
      payMethod: "",
      comments: "",
    },
  },
  delRatingManagementModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
    },
  },
  copRatingManagementModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
      employee: null,
      comments: "",
    },
  },
  // Load User Account
  userAccountArray: [],
  //   userAccountOptions: [],
  restrictedRuleEditControlModal: {
    isOpened: false,
    respErrMsg: null,
    isAddMode: null,
    isFundBookOpened: false,
    fields: {},
    fundBookFields: {},
  },
};

// query
function onQueryInputChange(state, { name, value }) {
  const upatedFields = {
    ...state.ratingManagementQueryFields,
    [name]: value,
  };
  return {
    ...state,
    ratingManagementQueryFields: upatedFields,
  };
}
function startLoadRatingManagementInfo(state) {
  return {
    ...state,
    loadRatingManagementInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}
function loadRatingManagementInfoSuccess(state, resp) {
  return {
    ...state,
    loadRatingManagementInfoActionStatus: ACTION_STATUS.SUCCESS,
    loadRatingManagementInfoResp: resp,
  };
}
function loadRatingManagementInfoFailure(state, err) {
  return {
    ...state,
    loadRatingManagementInfoActionStatus: ACTION_STATUS.ERROR,
    loadRatingManagementInfoResp: err,
  };
}
function initLoadRatingManagementInfoActionStatus(state) {
  return {
    ...state,
    loadRatingManagementInfoActionStatus: ACTION_STATUS.READY,
    selectedRatingManagementInfoArray: [],
  };
}

// select
function selectRatingManagementInfo(state, array) {
  return {
    ...state,
    selectedRatingManagementInfoArray: array,
  };
}

// add or edit
function openAddOrEditRatingManagementModal(state, { mode, data }) {
  if (mode === "ADD") {
    return {
      ...state,
      addOrEditRatingManagementModal: {
        ...state.addOrEditRatingManagementModal,
        isOpened: true,
        mode,
      },
    };
  } else {
    return {
      ...state,
      addOrEditRatingManagementModal: {
        ...state.addOrEditRatingManagementModal,
        isOpened: true,
        mode,
        fields: {
          ...state.addOrEditRatingManagementModal.fields,
          id: data.id,
          cpty: data.cpty,
          functiontype: data.functiontype,
          usertype: data.usertype,
          fundandcompany: data.fundandcompany,
          employee: data.employee,
          status: data.status,

          remarks: data.remarks,
        },
      },
    };
  }
}
function addOrEditRatingManagementChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.addOrEditRatingManagementModal.fields,
    [name]: value,
  };
  return {
    ...state,
    addOrEditRatingManagementModal: {
      ...state.addOrEditRatingManagementModal,
      fields: updatedFields,
    },
  };
}
function startAddOrEditRatingManagement(state) {
  return {
    ...state,
    addOrEditRatingManagementStatus: ACTION_STATUS.LOGINING,
  };
}
function addOrEditRatingManagementSuccess(state, resp) {
  return {
    ...state,
    addOrEditRatingManagementStatus: ACTION_STATUS.SUCCESS,
  };
}
function addOrEditRatingManagementFailure(state, err) {
  return {
    ...state,
    addOrEditRatingManagementStatus: ACTION_STATUS.ERROR,
    addOrEditRatingManagementModal: {
      ...state.addOrEditRatingManagementModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initAddOrEditRatingManagementActionStatus(state) {
  return {
    ...state,
    addOrEditRatingManagementStatus: ACTION_STATUS.READY,
  };
}
function resetAddOrEditRatingManagementModal(state) {
  return {
    ...state,
    addOrEditRatingManagementModal: initialState.addOrEditRatingManagementModal,
  };
}

// refresh fx and amtUSD
function startRefreshFx(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.LOGINING,
  };
}
function refreshFxSuccess(state, resp) {
  const fx = !!resp && !!resp.data && !!resp.data.last ? resp.data.last : "";
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.SUCCESS,
    addOrEditRatingManagementModal: {
      ...state.addOrEditRatingManagementModal,
      fields: {
        ...state.addOrEditRatingManagementModal.fields,
        fx: fx,
      },
    },
  };
}
function refreshFxFailure(state, err) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.ERROR,
    refreshFxResp: err,
    addOrEditRatingManagementModal: {
      ...state.addOrEditRatingManagementModal,
      fields: {
        ...state.addOrEditRatingManagementModal.fields,
        fx: "",
      },
    },
  };
}
function initRefreshFxActionStatus(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.READY,
  };
}
function refreshAmtUSD(state, amtUSD) {
  return {
    ...state,
    addOrEditRatingManagementModal: {
      ...state.addOrEditRatingManagementModal,
      fields: {
        ...state.addOrEditRatingManagementModal.fields,
        amtUSD: amtUSD,
      },
    },
  };
}

// del
function openDelRatingManagementModal(state, { info, mode, idList, level }) {
  return {
    ...state,
    delRatingManagementModal: {
      ...state.delRatingManagementModal,
      isOpened: true,
      mode: mode,
      idList: idList,
      level: level,
      fields: {
        ...state.delRatingManagementModal.fields,
        id: info.id,
      },
    },
  };
}
function delRatingManagementChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.delRatingManagementModal.fields,
    [name]: value,
  };
  return {
    ...state,
    delRatingManagementModal: {
      ...state.delRatingManagementModal,
      fields: updatedFields,
    },
  };
}
function startDelRatingManagement(state) {
  return {
    ...state,
    delRatingManagementStatus: ACTION_STATUS.LOGINING,
  };
}
function delRatingManagementSuccess(state, resp) {
  return {
    ...state,
    delRatingManagementStatus: ACTION_STATUS.SUCCESS,
  };
}
function delRatingManagementFailure(state, err) {
  return {
    ...state,
    delRatingManagementStatus: ACTION_STATUS.ERROR,
    delRatingManagementModal: {
      ...state.delRatingManagementModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initDelRatingManagementActionStatus(state) {
  return {
    ...state,
    delRatingManagementStatus: ACTION_STATUS.READY,
  };
}
function resetDelRatingManagementModal(state) {
  return {
    ...state,
    delRatingManagementModal: initialState.delRatingManagementModal,
  };
}

// cop
function openCopRatingManagementModal(state, payload) {
  return {
    ...state,
    copRatingManagementModal: {
      ...state.copRatingManagementModal,
      isOpened: true,
      fields: {
        ...state.copRatingManagementModal.fields,
        id: payload.id,
      },
    },
  };
}
function copRatingManagementChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.copRatingManagementModal.fields,
    [name]: value,
  };
  return {
    ...state,
    copRatingManagementModal: {
      ...state.copRatingManagementModal,
      fields: updatedFields,
    },
  };
}
function startCopRatingManagement(state) {
  return {
    ...state,
    copRatingManagementStatus: ACTION_STATUS.LOGINING,
  };
}
function copRatingManagementSuccess(state, resp) {
  return {
    ...state,
    copRatingManagementStatus: ACTION_STATUS.SUCCESS,
  };
}
function copRatingManagementFailure(state, err) {
  return {
    ...state,
    copRatingManagementStatus: ACTION_STATUS.ERROR,
    copRatingManagementModal: {
      ...state.copRatingManagementModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initCopRatingManagementActionStatus(state) {
  return {
    ...state,
    copRatingManagementStatus: ACTION_STATUS.READY,
  };
}
function resetCopRatingManagementModal(state) {
  return {
    ...state,
    copRatingManagementModal: initialState.copRatingManagementModal,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map((userAccount) => (
    <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
  ));

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}

export function openRestrictedRuleFundBookDialog(state) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    isFundBookOpened: true,
    fundBookFields: {},
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields,
  };
}

export function closeRestrictedRuleFundBookDialog(state) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    isFundBookOpened: false,
    fundBookFields: {},
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields,
  };
}

function restrictedRuleEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    [name]: value,
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields,
  };
}


function loadInstituteUserAccountsSuccess(state, resp) {
    const instituteUserAccountArray = resp.data;
    const instituteUserAccountOptions = instituteUserAccountArray.map(
      (userAccount) => (
        <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
      )
    );
  
    return {
      ...state,
      instituteUserAccountArray,
      instituteUserAccountOptions,
    };
  }
  
  function loadInstituteUserAccountsFailure(state, err) {
    return {
      ...state,
    };
  }

export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  [START_LOAD_RATING_MANAGEMENT_INFO]: startLoadRatingManagementInfo,
  [LOAD_RATING_MANAGEMENT_INFO_SUCCESS]: loadRatingManagementInfoSuccess,
  [LOAD_RATING_MANAGEMENT_INFO_FAILURE]: loadRatingManagementInfoFailure,
  [INIT_LOAD_RATING_MANAGEMENT_INFO_ACTION_STATUS]:
    initLoadRatingManagementInfoActionStatus,
  // select
  [SELECT_RATING_MANAGEMENT_INFO]: selectRatingManagementInfo,
  // add or edit
  [OPEN_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL]:
    openAddOrEditRatingManagementModal,
  [ADD_OR_EDIT_RATING_MANAGEMENT_CHANGE_INPUT]:
    addOrEditRatingManagementChangeInput,
  [START_ADD_OR_EDIT_RATING_MANAGEMENT]: startAddOrEditRatingManagement,
  [ADD_OR_EDIT_RATING_MANAGEMENT_SUCCESS]: addOrEditRatingManagementSuccess,
  [ADD_OR_EDIT_RATING_MANAGEMENT_FAILURE]: addOrEditRatingManagementFailure,
  [INIT_ADD_OR_EDIT_RATING_MANAGEMENT_ACTION_STATUS]:
    initAddOrEditRatingManagementActionStatus,
  [RESET_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL]:
    resetAddOrEditRatingManagementModal,
  // refresh fx and amtUSD
  [START_REFRESH_FX]: startRefreshFx,
  [REFRESH_FX_SUCCESS]: refreshFxSuccess,
  [REFRESH_FX_FAILURE]: refreshFxFailure,
  [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
  [REFRESH_AMT_USD]: refreshAmtUSD,
  // del
  [OPEN_DEL_RATING_MANAGEMENT_MODAL]: openDelRatingManagementModal,
  [DEL_RATING_MANAGEMENT_CHANGE_INPUT]: delRatingManagementChangeInput,
  [START_DEL_RATING_MANAGEMENT]: startDelRatingManagement,
  [DEL_RATING_MANAGEMENT_SUCCESS]: delRatingManagementSuccess,
  [DEL_RATING_MANAGEMENT_FAILURE]: delRatingManagementFailure,
  [INIT_DEL_RATING_MANAGEMENT_ACTION_STATUS]:
    initDelRatingManagementActionStatus,
  [RESET_DEL_RATING_MANAGEMENT_MODAL]: resetDelRatingManagementModal,

  // cop
  [OPEN_COP_RATING_MANAGEMENT_MODAL]: openCopRatingManagementModal,
  [COP_RATING_MANAGEMENT_CHANGE_INPUT]: copRatingManagementChangeInput,
  [START_COP_RATING_MANAGEMENT]: startCopRatingManagement,
  [COP_RATING_MANAGEMENT_SUCCESS]: copRatingManagementSuccess,
  [COP_RATING_MANAGEMENT_FAILURE]: copRatingManagementFailure,
  [INIT_COP_RATING_MANAGEMENT_ACTION_STATUS]:
    initCopRatingManagementActionStatus,
  [RESET_COP_RATING_MANAGEMENT_MODAL]: resetCopRatingManagementModal,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  [OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG]: openRestrictedRuleFundBookDialog,
  [CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG]: closeRestrictedRuleFundBookDialog,
  [RESTRICTED_RULE_INPUT_CHANGE]: restrictedRuleEditChangeInput,

  [LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS]: loadInstituteUserAccountsSuccess,
  [LOAD_INSTITUTE_USER_ACCOUNT_FAILURE]: loadInstituteUserAccountsFailure,
});

import React, { Component } from "react";
import moment from 'moment';
import {
  Breadcrumb,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Tabs,
} from "antd";
import { ACTION_STATUS } from "../../../../../utils/ActionStatus";
import "./RotationReviewDashboard.css";
import RotationReviewGrid from "./RotationReviewGrid";
import AddOrEditRotationReviewModalDialog from "./AddOrEditRotationReviewModalDialog";
import DelRotationReviewModalDialog from "./DelRotationReviewModalDialog";
import CopRotationReviewModalDialog from "./CopRotationReviewModalDialog";
import OperationRecordDialog from "./OperationRecordDialog";
import RestrictedSecurityRuleGrid from "./restrictedRule/RestrictedSecurityRuleGrid";
import RotationScoreGrid from "./restrictedRule/RotationScoreGrid";
import FinishTaskModalDialog from "./FinishTaskModalDialog";
const TabPane = Tabs.TabPane;
class RotationReviewDashboard extends Component {
  componentDidMount() {
    const { title, type, startTime, endTime, speaker } = this.props.rotationReviewQueryFields;
    this.props.loadRotationReviewInfo({
        title,
        type,
        startTime: startTime.format("YYYY-MM-DD"),
        endTime: !!endTime ? endTime.format("YYYY-MM-DD") : null,
        speaker,
        classType: "Rotation",
      });
    this.props.loadInstituteUserAccounts({})
  }

  componentDidUpdate() {
    const {
      // query
      loadRotationReviewInfoActionStatus,
      loadRotationReviewInfoResp,
    } = this.props;

    if (loadRotationReviewInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadRotationReviewInfoActionStatus();
    }
    if (loadRotationReviewInfoActionStatus === ACTION_STATUS.ERROR) {
      let err = "Load Rotation Info failed";
      if (
        !!loadRotationReviewInfoResp &&
        !!loadRotationReviewInfoResp.respMessage
      ) {
        err = loadRotationReviewInfoResp.respMessage;
      }
      message.error(err);
      this.props.initLoadRotationReviewInfoActionStatus();
    }
  }

  onInputChange = ({ name, value }) => {
    if  (name === 'startTime' || name === 'endTime') {
        value = moment(value)
    }
    this.props.onQueryInputChange({ name, value });
  };

  addOrEditBtnOnClicked = (mode) => {
    if (mode === "ADD") {
      this.props.openAddOrEditRotationReviewModal(mode, null);
    } else {
      const { selectedRotationReviewInfoArray } = this.props;
      if (selectedRotationReviewInfoArray.length !== 1) {
        message.warning("Please choose one and only one User Access Info");
        return;
      }
      const currentRotationReviewInfo = selectedRotationReviewInfoArray[0];
      this.props.openAddOrEditRotationReviewModal(
        mode,
        currentRotationReviewInfo
      );
    }
  };
  copyAllBtnOnClicked = () => {
    const { selectedRotationReviewInfoArray } = this.props;
    if (selectedRotationReviewInfoArray.length !== 1) {
      message.warning("Please choose one and only one User Access Info");
      return;
    }
    const currentRotationReviewInfo = selectedRotationReviewInfoArray[0];
    this.props.openCopRotationReviewModal(currentRotationReviewInfo);
  };

  delBtnOnClicked = (mode) => {
    const { selectedRotationReviewInfoArray } = this.props;
    if (selectedRotationReviewInfoArray.length !== 1) {
      message.warning("Please choose one and only one Info");
      return;
    }
    const currentRotationReviewInfo = selectedRotationReviewInfoArray[0];
    this.props.openDelRotationReviewModal(currentRotationReviewInfo, mode);
  };

  refreshBtnOnClicked = () => {
    const { title, type, startTime, endTime, speaker } = this.props.rotationReviewQueryFields;
    // const person = participant;
    this.props.loadRotationReviewInfo({
      title,
      type,
      startTime: !!startTime ? startTime.format("YYYY-MM-DD") : null,
      endTime: !!endTime ? endTime.format("YYYY-MM-DD") : null,
      speaker,
      classType: "Rotation",
    });
  };

  // 操作记录页面，自定义需要展示的列
  opRcdCustomColumns = [
    "operator",
    "operateTime",
    "dataId",
    "operateType",
    "comments",
    "before",
    "after",
  ];

  render() {
    const {
      typeOptions,
      instituteUserAccountOptions,
      rotationReviewQueryFields,
      loadRotationReviewInfoActionStatus,
    } = this.props;

    const { userName, status, title, type, startTime, endTime, speaker } = rotationReviewQueryFields;

    const refreshBtnIsLoading =
      loadRotationReviewInfoActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="RotationReviewWrapper">
        <div className="breadcrumbWrapper">
          <Breadcrumb>
            <Breadcrumb.Item>Rotation Review</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Tabs defaultActiveKey="1" tabPosition={"top"}>
          <TabPane tab="Rotation" key="1">
            <div className="OperationBar">
              <div className="breadcrumbWrapper">
                <Breadcrumb>
                  <Breadcrumb.Item> Search by:</Breadcrumb.Item>
                </Breadcrumb>
              </div>

              <Input
                allowClear={true}
                size="small"
                style={{ width: 150, marginLeft: 10 }}
                placeholder="Title"
                onPressEnter={this.refreshBtnOnClicked}
                value={title}
                onChange={(event) => {
                  this.onInputChange({
                    name: "title",
                    value: event.target.value,
                  });
                }}
              />
              <Select
                allowClear
                size="small"
                style={{ width: 150, marginLeft: 10 }}
                value={type}
                onChange={(val) => {
                  this.onInputChange({ name: "type", value: val });
                }}
                placeholder="type"
              >
                {typeOptions}
              </Select>
              <DatePicker
                allowClear={true}
                size="small"
                style={{ width: 150, marginLeft: 10 }}
                placeholder=" Date Start"
                value={startTime}
                onChange={(startTime, dateString) => {
                  this.onInputChange({ name: "startTime", value: dateString });
                }}
              />
              <DatePicker
                allowClear={true}
                size="small"
                style={{ width: 150, marginLeft: 10 }}
                placeholder="Date End"
                value={endTime}
                onChange={(endTime, dateString) => {
                  this.onInputChange({ name: "endTime", value: dateString });
                }}
              />
              <Input
                allowClear={true}
                placeholder="Speaker"
                size="small"
                style={{ width: 150, marginLeft: 10 }}
                onPressEnter={this.refreshBtnOnClicked}
                value={speaker}
                onChange={(event) => {
                  this.onInputChange({
                    name: "speaker",
                    value: event.target.value,
                  });
                }}
              />
              <Button
                onClick={this.refreshBtnOnClicked}
                loading={refreshBtnIsLoading}
                size="small"
                style={{ marginLeft: 10 }}
                type="primary"
                icon="search"
              />

              <Button
                onClick={() => this.delBtnOnClicked("inactive")}
                size="small"
                style={{ fontSize: 10, marginLeft: 10 }}
                type="primary"
              >
                Reset
              </Button>
            </div>

            <div className="RotationReviewGrid">
              <RotationReviewGrid {...this.props} />
            </div>
            {/* <AddOrEditRotationReviewModalDialog {...this.props} />
                
                <CopRotationReviewModalDialog {...this.props} /> */}

            <OperationRecordDialog {...this.props} />
          </TabPane>
          <TabPane tab="Individual" key="2">
            <RestrictedSecurityRuleGrid {...this.props} />
          </TabPane>
          <TabPane tab="RotationScore" key="3">
            <RotationScoreGrid {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RotationReviewDashboard;

import { httpClient } from "../../../../net/httpClient";
import UrlConfig from "../../../../net/UrlConfig";

import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_MEETING_AND_TRAINING_INFO,
  LOAD_MEETING_AND_TRAINING_INFO_SUCCESS,
  LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
  INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
  // select
  SELECT_MEETING_AND_TRAINING_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
  ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
  START_ADD_OR_EDIT_MEETING_AND_TRAINING,
  ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
  ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
  INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
  RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_MEETING_AND_TRAINING_MODAL,
  DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
  START_DEL_MEETING_AND_TRAINING,
  DEL_MEETING_AND_TRAINING_SUCCESS,
  DEL_MEETING_AND_TRAINING_FAILURE,
  INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
  RESET_DEL_MEETING_AND_TRAINING_MODAL,

  // COP
  OPEN_COP_MEETING_AND_TRAINING_MODAL,
  COP_MEETING_AND_TRAINING_CHANGE_INPUT,
  START_COP_MEETING_AND_TRAINING,
  COP_MEETING_AND_TRAINING_SUCCESS,
  COP_MEETING_AND_TRAINING_FAILURE,
  INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
  RESET_COP_MEETING_AND_TRAINING_MODAL,
  ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
  ANSWEREE_SELECT_MODAL_DIALOG_RESET,

  // Select All Answeree
  SELECT_ALL_ANSWEREE,

  // Select Invert Answeree
  SELECT_INVERT_ANSWEREE,
  // Answeree Check Changed
  ANSWEREE_CHECK_CHANGED,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,

  // Select Answerees
  START_SELECT_ANSWEREES,
  SELECT_ANSWEREES_SUCCESS,
  SELECT_ANSWEREES_FAILURE,
  INIT_SELECT_ANSWEREES_ACTION_STATUS,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./MeetingAndTrainingConstants";

// query
export function onQueryInputChange({ name, value }) {
  return {
    type: ON_QUERY_INPUT_CHANGE,
    payload: {
      name,
      value,
    },
  };
}
function startLoadMeetingAndTrainingInfo() {
  return {
    type: START_LOAD_MEETING_AND_TRAINING_INFO,
  };
}
export function loadMeetingAndTrainingInfo(params) {
  return function (dispatch) {
    dispatch(startLoadMeetingAndTrainingInfo());
    httpClient
      .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO, params)
      .then((resp) => dispatch(loadMeetingAndTrainingInfoSuccess(resp)))
      .catch((err) => dispatch(loadMeetingAndTrainingInfoFailure(err)));
  };
}
function loadMeetingAndTrainingInfoSuccess(resp) {
  return {
    type: LOAD_MEETING_AND_TRAINING_INFO_SUCCESS,
    payload: resp,
  };
}
function loadMeetingAndTrainingInfoFailure(err) {
  return {
    type: LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
    payload: err,
  };
}
export function initLoadMeetingAndTrainingInfoActionStatus() {
  return {
    type: INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
  };
}

// select
export function selectMeetingAndTrainingInfo(array) {
  return {
    type: SELECT_MEETING_AND_TRAINING_INFO,
    payload: array,
  };
}

// add or edit
export function openAddOrEditMeetingAndTrainingModal(mode, data) {
  return {
    type: OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    payload: {
      mode,
      data,
    },
  };
}
export function addOrEditMeetingAndTrainingChangeInput({ name, value }) {
  return {
    type: ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startAddOrEditMeetingAndTraining() {
  return {
    type: START_ADD_OR_EDIT_MEETING_AND_TRAINING,
  };
}
export function addOrEditMeetingAndTraining(params) {
  return function (dispatch) {
    dispatch(startAddOrEditMeetingAndTraining());
    httpClient
      .post(UrlConfig.ADD_OR_EDIT_MEETING_AND_TRAINING, params)
      .then((resp) => dispatch(addOrEditMeetingAndTrainingSuccess(resp)))
      .catch((err) => dispatch(addOrEditMeetingAndTrainingFailure(err)));
  };
}
function addOrEditMeetingAndTrainingSuccess(resp) {
  return {
    type: ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
    payload: resp,
  };
}
function addOrEditMeetingAndTrainingFailure(err) {
  return {
    type: ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
    payload: err,
  };
}
export function initAddOrEditMeetingAndTrainingActionStatus() {
  return {
    type: INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
  };
}
export function resetAddOrEditMeetingAndTrainingModal() {
  return {
    type: RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
  };
}

// refresh fx and amtUSD
function startRefreshFx() {
  return {
    type: START_REFRESH_FX,
  };
}
export function refreshFx({ currInvDate, currCcy }) {
  if (!currInvDate || !currCcy) {
    return function (dispatch) {
      dispatch(refreshFxSuccess(null));
    };
  } else {
    const params = {
      fromCurrencyCode: "USD",
      toCurrencyCode: currCcy,
      specifiedDateStr: currInvDate,
    };
    return function (dispatch) {
      dispatch(startRefreshFx());
      httpClient
        .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
        .then((resp) => dispatch(refreshFxSuccess(resp)))
        .catch((err) => dispatch(refreshFxFailure(err)));
    };
  }
}
function refreshFxSuccess(resp) {
  return {
    type: REFRESH_FX_SUCCESS,
    payload: resp,
  };
}
function refreshFxFailure(err) {
  return {
    type: REFRESH_FX_FAILURE,
    payload: err,
  };
}
export function initRefreshFxActionStatus() {
  return {
    type: INIT_REFRESH_FX_ACTION_STATUS,
  };
}
export function refreshAmtUSD({ currAmount, currFx }) {
  let amtUSD = "";
  if (!!currAmount && !!currFx) {
    amtUSD = currAmount / currFx;
    amtUSD = Math.round(amtUSD * 100) / 100;
  }
  if (isNaN(amtUSD)) {
    amtUSD = "";
  }
  return {
    type: REFRESH_AMT_USD,
    payload: amtUSD,
  };
}

// del
export function openDelMeetingAndTrainingModal(info, mode) {
  return {
    type: OPEN_DEL_MEETING_AND_TRAINING_MODAL,
    payload: {
      info,
      mode,
    },
  };
}
export function delMeetingAndTrainingChangeInput({ name, value }) {
  return {
    type: DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startDelMeetingAndTraining() {
  return {
    type: START_DEL_MEETING_AND_TRAINING,
  };
}
export function delMeetingAndTraining(params) {
  return function (dispatch) {
    dispatch(startDelMeetingAndTraining());
    httpClient
      .get(UrlConfig.DEL_MEETING_AND_TRAINING, params)
      .then((resp) => dispatch(delMeetingAndTrainingSuccess(resp)))
      .catch((err) => dispatch(delMeetingAndTrainingFailure(err)));
  };
}
function delMeetingAndTrainingSuccess(resp) {
  return {
    type: DEL_MEETING_AND_TRAINING_SUCCESS,
    payload: resp,
  };
}
function delMeetingAndTrainingFailure(err) {
  return {
    type: DEL_MEETING_AND_TRAINING_FAILURE,
    payload: err,
  };
}
export function initDelMeetingAndTrainingActionStatus() {
  return {
    type: INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
  };
}
export function resetDelMeetingAndTrainingModal() {
  return {
    type: RESET_DEL_MEETING_AND_TRAINING_MODAL,
  };
}

//cop
export function openCopMeetingAndTrainingModal(info) {
  return {
    type: OPEN_COP_MEETING_AND_TRAINING_MODAL,
    payload: info,
  };
}
export function copMeetingAndTrainingChangeInput({ name, value }) {
  return {
    type: COP_MEETING_AND_TRAINING_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startCopMeetingAndTraining() {
  return {
    type: START_COP_MEETING_AND_TRAINING,
  };
}
export function copMeetingAndTraining(params) {
  return function (dispatch) {
    dispatch(startCopMeetingAndTraining());
    httpClient
      .get(UrlConfig.COP_ALL_MEETING_AND_TRAINING, params)
      .then((resp) => dispatch(copMeetingAndTrainingSuccess(resp)))
      .catch((err) => dispatch(copMeetingAndTrainingFailure(err)));
  };
}
function copMeetingAndTrainingSuccess(resp) {
  return {
    type: COP_MEETING_AND_TRAINING_SUCCESS,
    payload: resp,
  };
}
function copMeetingAndTrainingFailure(err) {
  return {
    type: COP_MEETING_AND_TRAINING_FAILURE,
    payload: err,
  };
}
export function initCopMeetingAndTrainingActionStatus() {
  return {
    type: INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
  };
}
export function resetCopMeetingAndTrainingModal() {
  return {
    type: RESET_COP_MEETING_AND_TRAINING_MODAL,
  };
}
export function openAnswereeSelectModalDialog() {
  return {
    type: ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    payload: {},
  };
}
export function resetAnswereeSelectModalDialog() {
  return {
    type: ANSWEREE_SELECT_MODAL_DIALOG_RESET,
  };
}

// Select All Answeree
export function selectAllAnsweree() {
  return {
    type: SELECT_ALL_ANSWEREE,
  };
}

// Select Invert Answeree
export function selectInvertAnsweree() {
  return {
    type: SELECT_INVERT_ANSWEREE,
  };
}

// Answeree Check Changed
export function answereeCheckChanged(checked, userAccountName) {
  return {
    type: ANSWEREE_CHECK_CHANGED,
    payload: {
      checked,
      userAccountName,
    },
  };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
  return {
    type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      sendMode,
      emailPayload,
    },
  };
}

export function resetQuestionnaireEmailEditModalDialog() {
  return {
    type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,
  };
}

// Select Answerees
function startSelectAnswerees() {
  return {
    type: START_SELECT_ANSWEREES,
  };
}
export function selectAnswerees(
  userAccountNameList,
  questionnaireGuid,
  emailTitle,
  emailContent
) {
  const requestParams = {
    userAccountNameList,
    questionnaireGuid,
    emailTitle,
    emailContent,
  };

  return function (dispatch) {
    dispatch(startSelectAnswerees());
    httpClient
      .post(UrlConfig.SELECT_MEETINGANSWEREES, requestParams)
      .then((resp) => dispatch(selectAnswereesSuccess(resp)))
      .catch((err) => dispatch(selectAnswereesFailure(err)));
  };
}

function selectAnswereesSuccess(resp) {
  return {
    type: SELECT_ANSWEREES_SUCCESS,
    payload: resp,
  };
}

function selectAnswereesFailure(err) {
  return {
    type: SELECT_ANSWEREES_FAILURE,
    payload: err,
  };
}

export function initSelectAnswereesActionStatus() {
  return {
    type: INIT_SELECT_ANSWEREES_ACTION_STATUS,
  };
}

export function loadInstituteUserAccounts(params) {
  return function (dispatch) {
    httpClient
      .get(UrlConfig.QUERY_INSTITUTE_USER_ACCOUNTS, params)
      .then((resp) => dispatch(loadInstituteUserAccountsSuccess(resp)))
      .catch((err) => dispatch(loadInstituteUserAccountsFailure(err)));
  };
}
function loadInstituteUserAccountsSuccess(resp) {
  return {
    type: LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
    payload: resp,
  };
}
function loadInstituteUserAccountsFailure(err) {
  return {
    type: LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
    payload: err,
  };
}

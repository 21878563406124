import React from "react";
import { Select } from "antd";
import { createReducer } from "../../../../utils/reducerUtil";
import { ACTION_STATUS } from "../../../../utils/ActionStatus";
import GridColumnMap from "./GridColumnMap";
import BaseUrlConfig from "../../../../net/BaseUrlConfig";
import UrlConfig from "../../../../net/UrlConfig";
import moment from "moment";

import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_USER_ACCESS_INFO,
  LOAD_USER_ACCESS_INFO_SUCCESS,
  LOAD_USER_ACCESS_INFO_FAILURE,
  INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
  // select
  SELECT_USER_ACCESS_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
  ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
  START_ADD_OR_EDIT_USER_ACCESS,
  ADD_OR_EDIT_USER_ACCESS_SUCCESS,
  ADD_OR_EDIT_USER_ACCESS_FAILURE,
  INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
  RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_USER_ACCESS_MODAL,
  DEL_USER_ACCESS_CHANGE_INPUT,
  START_DEL_USER_ACCESS,
  DEL_USER_ACCESS_SUCCESS,
  DEL_USER_ACCESS_FAILURE,
  INIT_DEL_USER_ACCESS_ACTION_STATUS,
  RESET_DEL_USER_ACCESS_MODAL,

  // del
  OPEN_COP_USER_ACCESS_MODAL,
  COP_USER_ACCESS_CHANGE_INPUT,
  START_COP_USER_ACCESS,
  COP_USER_ACCESS_SUCCESS,
  COP_USER_ACCESS_FAILURE,
  INIT_COP_USER_ACCESS_ACTION_STATUS,
  RESET_COP_USER_ACCESS_MODAL,
  LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,
  OPEN_FINISH_TASK_MODAL,
  OPEN_OPERATION_RECORD_DIALOG,
  START_QUERY_OPERATION_RECORD,
  QUERY_OPERATION_RECORD_SUCESS,
  QUERY_OPERATION_RECORD_FAILURE,
  INIT_QUERY_OPERATION_RECORD_STATUS,
  RESET_OPERATION_RECORD_DIALOG,
  // detail
  SHOW_DETAIL_RCD,
  RESET_DETAIL_RCD,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./MeetingAndTrainingReviewConstants";

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from "../../home/homeConstants";
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={"New"}>{"New"}</Option>);
statusOptions.push(<Option key={"Confirm"}>{"Confirm"}</Option>);
statusOptions.push(<Option key={"Feedback"}>{"Feedback"}</Option>);

const typeOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
typeOptions.push(<Option key={"CIO Tutorial"}>{"CIO Tutorial"}</Option>);
typeOptions.push(
  <Option key={"Sell Side Sector Training"}>
    {"Sell Side Sector Training"}
  </Option>
);
typeOptions.push(<Option key={"Group Training"}>{"Group Training"}</Option>);
typeOptions.push(<Option key={"Modeling Class"}>{"Modeling Class"}</Option>);
typeOptions.push(
  <Option key={"Macro and Strategy Training"}>
    {"Macro and Strategy Training"}
  </Option>
);
typeOptions.push(
  <Option key={"Buy Side Sharing"}>{"Buy Side Sharing"}</Option>
);
typeOptions.push(
  <Option key={"Sale Side Meeting"}>{"Sale Side Meeting"}</Option>
);
typeOptions.push(<Option key={"Other"}>{"Other"}</Option>);

const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {
  meetingAndTrainingReviewUploadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.USER_ACCESS_UPLOAD_URL,
  meetingAndTrainingReviewDownloadUrl:
    BaseUrlConfig.api.REST_URL +
    BaseUrlConfig.api.API_VERSION +
    UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,
  meetingAndTrainingReviewGridColumns:
    GridColumnMap.meetingAndTrainingReviewGridColumns,

  statusOptions: statusOptions,
  typeOptions: typeOptions,
  fundFirmRelatedOptions: fundFirmRelatedOptions,
  ccyOptions: ccyOptions,

  meetingAndTrainingReviewQueryFields: {
    userName: "",
    status: "",
    startTime: moment().subtract(1, "year").month(6).startOf("month"),
  },
  selectedMeetingAndTrainingReviewInfoArray: [],
  addOrEditMeetingAndTrainingReviewModal: {
    isOpened: false,
    mode: "ADD",
    respErrMsg: "",
    fields: {
      id: null,
      guid: "",
      category: "",
      firm: "",
      fundFirmRelated: "",
      invDate: "",
      invNo: "",
      ccy: "",
      amount: "",
      fx: "",
      amtUSD: "",
      payDate: "",
      nature: "",
      remarks: "",
      payMethod: "",
      comments: "",
    },
  },
  delMeetingAndTrainingReviewModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
    },
  },
  copMeetingAndTrainingReviewModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: null,
      employee: null,
      comments: "",
    },
  },
  // Load User Account
  userAccountArray: [],
  //   userAccountOptions: [],

  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

  restrictedRuleList: [],
  selectedRestrictedRule: {},

  finishTaskModal: {
    isOpened: false,
    respErrMsg: "",
    fields: {
      id: undefined,
      remarks: undefined,
      comments: undefined,
    },
  },

  operationRecordDialogModal: {
    isOpened: false,
    queryFields: {
      module: "",
      dataId: "",
      operateTimeStart: "",
      operateTimeEnd: "",
    },
    customColumns: [],
    // detail
    isDetailOpened: false,
    detail: null,
  },
};

// query
function onQueryInputChange(state, { name, value }) {
  const upatedFields = {
    ...state.meetingAndTrainingReviewQueryFields,
    [name]: value,
  };
  return {
    ...state,
    meetingAndTrainingReviewQueryFields: upatedFields,
  };
}
function startLoadMeetingAndTrainingReviewInfo(state) {
  return {
    ...state,
    loadMeetingAndTrainingReviewInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}
function loadMeetingAndTrainingReviewInfoSuccess(state, resp) {
  return {
    ...state,
    loadMeetingAndTrainingReviewInfoActionStatus: ACTION_STATUS.SUCCESS,
    loadMeetingAndTrainingReviewInfoResp: resp,
  };
}
function loadMeetingAndTrainingReviewInfoFailure(state, err) {
  return {
    ...state,
    loadMeetingAndTrainingReviewInfoActionStatus: ACTION_STATUS.ERROR,
    loadMeetingAndTrainingReviewInfoResp: err,
  };
}
function initLoadMeetingAndTrainingReviewInfoActionStatus(state) {
  return {
    ...state,
    loadMeetingAndTrainingReviewInfoActionStatus: ACTION_STATUS.READY,
    selectedMeetingAndTrainingReviewInfoArray: [],
  };
}

// select
function selectMeetingAndTrainingReviewInfo(state, array) {
  return {
    ...state,
    selectedMeetingAndTrainingReviewInfoArray: array,
  };
}

// add or edit
function openAddOrEditMeetingAndTrainingReviewModal(state, { mode, data }) {
  if (mode === "ADD") {
    return {
      ...state,
      addOrEditMeetingAndTrainingReviewModal: {
        ...state.addOrEditMeetingAndTrainingReviewModal,
        isOpened: true,
        mode,
      },
    };
  } else {
    return {
      ...state,
      addOrEditMeetingAndTrainingReviewModal: {
        ...state.addOrEditMeetingAndTrainingReviewModal,
        isOpened: true,
        mode,
        fields: {
          ...state.addOrEditMeetingAndTrainingReviewModal.fields,
          id: data.id,
          cpty: data.cpty,
          functiontype: data.functiontype,
          usertype: data.usertype,
          fundandcompany: data.fundandcompany,
          employee: data.employee,
          status: data.status,

          remarks: data.remarks,
        },
      },
    };
  }
}
function addOrEditMeetingAndTrainingReviewChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.addOrEditMeetingAndTrainingReviewModal.fields,
    [name]: value,
  };
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewModal: {
      ...state.addOrEditMeetingAndTrainingReviewModal,
      fields: updatedFields,
    },
  };
}
function startAddOrEditMeetingAndTrainingReview(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewStatus: ACTION_STATUS.LOGINING,
  };
}
function addOrEditMeetingAndTrainingReviewSuccess(state, resp) {
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewStatus: ACTION_STATUS.SUCCESS,
  };
}
function addOrEditMeetingAndTrainingReviewFailure(state, err) {
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewStatus: ACTION_STATUS.ERROR,
    addOrEditMeetingAndTrainingReviewModal: {
      ...state.addOrEditMeetingAndTrainingReviewModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initAddOrEditMeetingAndTrainingReviewActionStatus(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewStatus: ACTION_STATUS.READY,
  };
}
function resetAddOrEditMeetingAndTrainingReviewModal(state) {
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewModal:
      initialState.addOrEditMeetingAndTrainingReviewModal,
  };
}

// refresh fx and amtUSD
function startRefreshFx(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.LOGINING,
  };
}
function refreshFxSuccess(state, resp) {
  const fx = !!resp && !!resp.data && !!resp.data.last ? resp.data.last : "";
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.SUCCESS,
    addOrEditMeetingAndTrainingReviewModal: {
      ...state.addOrEditMeetingAndTrainingReviewModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingReviewModal.fields,
        fx: fx,
      },
    },
  };
}
function refreshFxFailure(state, err) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.ERROR,
    refreshFxResp: err,
    addOrEditMeetingAndTrainingReviewModal: {
      ...state.addOrEditMeetingAndTrainingReviewModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingReviewModal.fields,
        fx: "",
      },
    },
  };
}
function initRefreshFxActionStatus(state) {
  return {
    ...state,
    refreshFxStatus: ACTION_STATUS.READY,
  };
}
function refreshAmtUSD(state, amtUSD) {
  return {
    ...state,
    addOrEditMeetingAndTrainingReviewModal: {
      ...state.addOrEditMeetingAndTrainingReviewModal,
      fields: {
        ...state.addOrEditMeetingAndTrainingReviewModal.fields,
        amtUSD: amtUSD,
      },
    },
  };
}

// del
function openDelMeetingAndTrainingReviewModal(state, { info, mode }) {
  return {
    ...state,
    delMeetingAndTrainingReviewModal: {
      ...state.delMeetingAndTrainingReviewModal,
      isOpened: true,
      mode: mode,
      fields: {
        ...state.delMeetingAndTrainingReviewModal.fields,
        id: info.id,
      },
    },
  };
}
function delMeetingAndTrainingReviewChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.delMeetingAndTrainingReviewModal.fields,
    [name]: value,
  };
  return {
    ...state,
    delMeetingAndTrainingReviewModal: {
      ...state.delMeetingAndTrainingReviewModal,
      fields: updatedFields,
    },
  };
}
function startDelMeetingAndTrainingReview(state) {
  return {
    ...state,
    delMeetingAndTrainingReviewStatus: ACTION_STATUS.LOGINING,
  };
}
function delMeetingAndTrainingReviewSuccess(state, resp) {
  return {
    ...state,
    delMeetingAndTrainingReviewStatus: ACTION_STATUS.SUCCESS,
  };
}
function delMeetingAndTrainingReviewFailure(state, err) {
  return {
    ...state,
    delMeetingAndTrainingReviewStatus: ACTION_STATUS.ERROR,
    delMeetingAndTrainingReviewModal: {
      ...state.delMeetingAndTrainingReviewModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initDelMeetingAndTrainingReviewActionStatus(state) {
  return {
    ...state,
    delMeetingAndTrainingReviewStatus: ACTION_STATUS.READY,
  };
}
function resetDelMeetingAndTrainingReviewModal(state) {
  return {
    ...state,
    delMeetingAndTrainingReviewModal:
      initialState.delMeetingAndTrainingReviewModal,
  };
}

// cop
function openCopMeetingAndTrainingReviewModal(state, payload) {
  return {
    ...state,
    copMeetingAndTrainingReviewModal: {
      ...state.copMeetingAndTrainingReviewModal,
      isOpened: true,
      fields: {
        ...state.copMeetingAndTrainingReviewModal.fields,
        id: payload.id,
      },
    },
  };
}
function copMeetingAndTrainingReviewChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.copMeetingAndTrainingReviewModal.fields,
    [name]: value,
  };
  return {
    ...state,
    copMeetingAndTrainingReviewModal: {
      ...state.copMeetingAndTrainingReviewModal,
      fields: updatedFields,
    },
  };
}
function startCopMeetingAndTrainingReview(state) {
  return {
    ...state,
    copMeetingAndTrainingReviewStatus: ACTION_STATUS.LOGINING,
  };
}
function copMeetingAndTrainingReviewSuccess(state, resp) {
  return {
    ...state,
    copMeetingAndTrainingReviewStatus: ACTION_STATUS.SUCCESS,
  };
}
function copMeetingAndTrainingReviewFailure(state, err) {
  return {
    ...state,
    copMeetingAndTrainingReviewStatus: ACTION_STATUS.ERROR,
    copMeetingAndTrainingReviewModal: {
      ...state.copMeetingAndTrainingReviewModal,
      respErrMsg: err.respMessage,
    },
  };
}
function initCopMeetingAndTrainingReviewActionStatus(state) {
  return {
    ...state,
    copMeetingAndTrainingReviewStatus: ACTION_STATUS.READY,
  };
}
function resetCopMeetingAndTrainingReviewModal(state) {
  return {
    ...state,
    copMeetingAndTrainingReviewModal:
      initialState.copMeetingAndTrainingReviewModal,
  };
}

// Load User Account
// function loadUserAccountsSuccess(state, resp) {
//     const userAccountArray = resp.data;
//     const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

//     return {
//       ...state,
//       userAccountArray,
//       userAccountOptions,
//     };
//   }

function loadRestrictedRuleListSuccess(state, resp) {
  return {
    ...state,
    restrictedRuleList: resp.data,
  };
}

function loadRestrictedRuleListFailure(state, err) {
  return {
    ...state,
  };
}

function addRestrictedRuleSuccess(state, resp) {
  return {
    ...state,
  };
}

function openFinishTaskModal(state, task) {
  return {
    ...state,
    finishTaskModal: {
      ...state.finishTaskModal,
      isOpened: true,
      fields: {
        ...state.finishTaskModal.fields,
        id: task.id,
        remarks: task.remarks,
      },
    },
  };
}

function openOperationRecordDialog(state, queryParams) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isOpened: true,
      queryFields: queryParams.queryFields,
      customColumns: queryParams.customColumns,
      id: queryParams.id,
    },
  };
}
function startQueryOperationRecord(state) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.LOGINING,
  };
}
function queryOperationRecordSuccess(state, resp) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.SUCCESS,
    queryOperationRecordResp: resp,
  };
}
function queryOperationRecordFailure(state, err) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.ERROR,
    queryOperationRecordResp: err,
  };
}
function initQueryOperationRecordStatus(state) {
  return {
    ...state,
    queryOperationRecordStatus: ACTION_STATUS.READY,
  };
}
function resetOperationRecordDialog(state) {
  return {
    ...state,
    operationRecordDialogModal: initialState.operationRecordDialogModal,
  };
}

// detail
function showDetailRcd(state, detail) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isDetailOpened: true,
      detail: detail,
    },
  };
}
function resetDetailRcd(state) {
  return {
    ...state,
    operationRecordDialogModal: {
      ...state.operationRecordDialogModal,
      isDetailOpened: false,
      detail: null,
    },
  };
}

function loadInstituteUserAccountsSuccess(state, resp) {
  const instituteUserAccountArray = resp.data;
  const instituteUserAccountOptions = instituteUserAccountArray.map(
    (userAccount) => (
      <Option key={userAccount.englishName}>{userAccount.englishName}</Option>
    )
  );

  return {
    ...state,
    instituteUserAccountArray,
    instituteUserAccountOptions,
  };
}

function loadInstituteUserAccountsFailure(state, err) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  [START_LOAD_USER_ACCESS_INFO]: startLoadMeetingAndTrainingReviewInfo,
  [LOAD_USER_ACCESS_INFO_SUCCESS]: loadMeetingAndTrainingReviewInfoSuccess,
  [LOAD_USER_ACCESS_INFO_FAILURE]: loadMeetingAndTrainingReviewInfoFailure,
  [INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS]:
    initLoadMeetingAndTrainingReviewInfoActionStatus,
  // select
  [SELECT_USER_ACCESS_INFO]: selectMeetingAndTrainingReviewInfo,
  // add or edit
  [OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL]:
    openAddOrEditMeetingAndTrainingReviewModal,
  [ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT]:
    addOrEditMeetingAndTrainingReviewChangeInput,
  [START_ADD_OR_EDIT_USER_ACCESS]: startAddOrEditMeetingAndTrainingReview,
  [ADD_OR_EDIT_USER_ACCESS_SUCCESS]: addOrEditMeetingAndTrainingReviewSuccess,
  [ADD_OR_EDIT_USER_ACCESS_FAILURE]: addOrEditMeetingAndTrainingReviewFailure,
  [INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS]:
    initAddOrEditMeetingAndTrainingReviewActionStatus,
  [RESET_ADD_OR_EDIT_USER_ACCESS_MODAL]:
    resetAddOrEditMeetingAndTrainingReviewModal,
  // refresh fx and amtUSD
  [START_REFRESH_FX]: startRefreshFx,
  [REFRESH_FX_SUCCESS]: refreshFxSuccess,
  [REFRESH_FX_FAILURE]: refreshFxFailure,
  [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
  [REFRESH_AMT_USD]: refreshAmtUSD,
  // del
  [OPEN_DEL_USER_ACCESS_MODAL]: openDelMeetingAndTrainingReviewModal,
  [DEL_USER_ACCESS_CHANGE_INPUT]: delMeetingAndTrainingReviewChangeInput,
  [START_DEL_USER_ACCESS]: startDelMeetingAndTrainingReview,
  [DEL_USER_ACCESS_SUCCESS]: delMeetingAndTrainingReviewSuccess,
  [DEL_USER_ACCESS_FAILURE]: delMeetingAndTrainingReviewFailure,
  [INIT_DEL_USER_ACCESS_ACTION_STATUS]:
    initDelMeetingAndTrainingReviewActionStatus,
  [RESET_DEL_USER_ACCESS_MODAL]: resetDelMeetingAndTrainingReviewModal,

  // cop
  [OPEN_COP_USER_ACCESS_MODAL]: openCopMeetingAndTrainingReviewModal,
  [COP_USER_ACCESS_CHANGE_INPUT]: copMeetingAndTrainingReviewChangeInput,
  [START_COP_USER_ACCESS]: startCopMeetingAndTrainingReview,
  [COP_USER_ACCESS_SUCCESS]: copMeetingAndTrainingReviewSuccess,
  [COP_USER_ACCESS_FAILURE]: copMeetingAndTrainingReviewFailure,
  [INIT_COP_USER_ACCESS_ACTION_STATUS]:
    initCopMeetingAndTrainingReviewActionStatus,
  [RESET_COP_USER_ACCESS_MODAL]: resetCopMeetingAndTrainingReviewModal,

  // Load User Account
  // [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  [LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS]: loadRestrictedRuleListSuccess,
  [LOAD_RESTRICTED_SECURITIES_RULE_FAILURE]: loadRestrictedRuleListFailure,

  [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,

  [OPEN_OPERATION_RECORD_DIALOG]: openOperationRecordDialog,
  [START_QUERY_OPERATION_RECORD]: startQueryOperationRecord,
  [QUERY_OPERATION_RECORD_SUCESS]: queryOperationRecordSuccess,
  [QUERY_OPERATION_RECORD_FAILURE]: queryOperationRecordFailure,
  [INIT_QUERY_OPERATION_RECORD_STATUS]: initQueryOperationRecordStatus,
  [RESET_OPERATION_RECORD_DIALOG]: resetOperationRecordDialog,
  // detail
  [SHOW_DETAIL_RCD]: showDetailRcd,
  [RESET_DETAIL_RCD]: resetDetailRcd,

  [LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS]: loadInstituteUserAccountsSuccess,
  [LOAD_INSTITUTE_USER_ACCOUNT_FAILURE]: loadInstituteUserAccountsFailure,
});

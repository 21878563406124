import { httpClient } from "../../../../net/httpClient";
import UrlConfig from "../../../../net/UrlConfig";

import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_USER_ACCESS_INFO,
  LOAD_USER_ACCESS_INFO_SUCCESS,
  LOAD_USER_ACCESS_INFO_FAILURE,
  INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
  // select
  SELECT_USER_ACCESS_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
  OPEN_ADD_OR_EDIT_EVENT_MODAL,
  ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
  START_ADD_OR_EDIT_USER_ACCESS,
  ADD_OR_EDIT_USER_ACCESS_SUCCESS,
  ADD_OR_EDIT_USER_ACCESS_FAILURE,
  INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
  RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_USER_ACCESS_MODAL,
  DEL_USER_ACCESS_CHANGE_INPUT,
  START_DEL_USER_ACCESS,
  DEL_USER_ACCESS_SUCCESS,
  DEL_USER_ACCESS_FAILURE,
  INIT_DEL_USER_ACCESS_ACTION_STATUS,
  RESET_DEL_USER_ACCESS_MODAL,

  // COP
  OPEN_COP_USER_ACCESS_MODAL,
  COP_USER_ACCESS_CHANGE_INPUT,
  START_COP_USER_ACCESS,
  COP_USER_ACCESS_SUCCESS,
  COP_USER_ACCESS_FAILURE,
  INIT_COP_USER_ACCESS_ACTION_STATUS,
  RESET_COP_USER_ACCESS_MODAL,
  LOAD_TRAINING_SUB_INDIVIDUAL_SUCCESS,
  LOAD_TRAINING_SUB_INDIVIDUAL_FAILURE,
  LOAD_RESTRICTED_SECURITIES_PINPOINT_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_PINPOINT_FAILURE,
  LOAD_RESTRICTED_SECURITIES_PERSONAL_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_PERSONAL_FAILURE,
  OPEN_FINISH_TASK_MODAL,
  OPEN_OPERATION_RECORD_DIALOG,
  START_QUERY_OPERATION_RECORD,
  QUERY_OPERATION_RECORD_SUCESS,
  QUERY_OPERATION_RECORD_FAILURE,
  INIT_QUERY_OPERATION_RECORD_STATUS,
  RESET_OPERATION_RECORD_DIALOG,
  // detail
  SHOW_DETAIL_RCD,
  RESET_DETAIL_RCD,
  OPEN_RESTRICTED_RULE_DIALOG,
  CLOSE_RESTRICTED_RULE_DIALOG,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./MeetingAndTrainingCalenderConstants";

export function openRestrictedRuleDialog(mode, detail) {
  return {
    type: OPEN_RESTRICTED_RULE_DIALOG,
    payload: {
      mode,
      detail,
    },
  };
}

export function closeRestrictedRuleDialog(mode) {
  return {
    type: CLOSE_RESTRICTED_RULE_DIALOG,
    payload: {
      mode,
    },
  };
}
// query
export function onQueryInputChange({ name, value }) {
  return {
    type: ON_QUERY_INPUT_CHANGE,
    payload: {
      name,
      value,
    },
  };
}
function startLoadMeetingAndTrainingCalenderInfo() {
  return {
    type: START_LOAD_USER_ACCESS_INFO,
  };
}
export function loadMeetingAndTrainingCalenderInfo(params) {
  return function (dispatch) {
    dispatch(startLoadMeetingAndTrainingCalenderInfo());
    httpClient
      //   .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO_SUB, params)
      .get(UrlConfig.LOAD_MEETING_AND_TRAINING_CALENDER_LIST_BROKER, params)
      .then((resp) => dispatch(loadMeetingAndTrainingCalenderInfoSuccess(resp)))
      .catch((err) => dispatch(loadMeetingAndTrainingCalenderInfoFailure(err)));
  };
}
function loadMeetingAndTrainingCalenderInfoSuccess(resp) {
  return {
    type: LOAD_USER_ACCESS_INFO_SUCCESS,
    payload: resp,
  };
}
function loadMeetingAndTrainingCalenderInfoFailure(err) {
  return {
    type: LOAD_USER_ACCESS_INFO_FAILURE,
    payload: err,
  };
}
export function initLoadMeetingAndTrainingCalenderInfoActionStatus() {
  return {
    type: INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
  };
}

// select
export function selectMeetingAndTrainingCalenderInfo(array) {
  return {
    type: SELECT_USER_ACCESS_INFO,
    payload: array,
  };
}

// add or edit
export function openAddOrEditMeetingAndTrainingCalenderModal(mode, data) {
  return {
    type: OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
    payload: {
      mode,
      data,
    },
  };
}
export function addOrEditMeetingAndTrainingCalenderChangeInput({
  name,
  value,
}) {
  return {
    type: ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startAddOrEditMeetingAndTrainingCalender() {
  return {
    type: START_ADD_OR_EDIT_USER_ACCESS,
  };
}
export function addOrEditMeetingAndTrainingCalender(params) {
  return function (dispatch) {
    dispatch(startAddOrEditMeetingAndTrainingCalender());
    httpClient
      .post(UrlConfig.LOAD_MEETING_AND_TRAINING_CALENDER_UPDATE, params)
      .then((resp) =>
        dispatch(addOrEditMeetingAndTrainingCalenderSuccess(resp))
      )
      .catch((err) =>
        dispatch(addOrEditMeetingAndTrainingCalenderFailure(err))
      );
  };
}
function addOrEditMeetingAndTrainingCalenderSuccess(resp) {
  return {
    type: ADD_OR_EDIT_USER_ACCESS_SUCCESS,
    payload: resp,
  };
}
function addOrEditMeetingAndTrainingCalenderFailure(err) {
  return {
    type: ADD_OR_EDIT_USER_ACCESS_FAILURE,
    payload: err,
  };
}
export function initAddOrEditMeetingAndTrainingCalenderActionStatus() {
  return {
    type: INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
  };
}
export function resetAddOrEditMeetingAndTrainingCalenderModal() {
  return {
    type: RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
  };
}

// refresh fx and amtUSD
function startRefreshFx() {
  return {
    type: START_REFRESH_FX,
  };
}
export function refreshFx({ currInvDate, currCcy }) {
  if (!currInvDate || !currCcy) {
    return function (dispatch) {
      dispatch(refreshFxSuccess(null));
    };
  } else {
    const params = {
      fromCurrencyCode: "USD",
      toCurrencyCode: currCcy,
      specifiedDateStr: currInvDate,
    };
    return function (dispatch) {
      dispatch(startRefreshFx());
      httpClient
        .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
        .then((resp) => dispatch(refreshFxSuccess(resp)))
        .catch((err) => dispatch(refreshFxFailure(err)));
    };
  }
}
function refreshFxSuccess(resp) {
  return {
    type: REFRESH_FX_SUCCESS,
    payload: resp,
  };
}
function refreshFxFailure(err) {
  return {
    type: REFRESH_FX_FAILURE,
    payload: err,
  };
}
export function initRefreshFxActionStatus() {
  return {
    type: INIT_REFRESH_FX_ACTION_STATUS,
  };
}
export function refreshAmtUSD({ currAmount, currFx }) {
  let amtUSD = "";
  if (!!currAmount && !!currFx) {
    amtUSD = currAmount / currFx;
    amtUSD = Math.round(amtUSD * 100) / 100;
  }
  if (isNaN(amtUSD)) {
    amtUSD = "";
  }
  return {
    type: REFRESH_AMT_USD,
    payload: amtUSD,
  };
}

// del
export function openDelMeetingAndTrainingCalenderModal(info, mode) {
  return {
    type: OPEN_DEL_USER_ACCESS_MODAL,
    payload: {
      info,
      mode,
    },
  };
}
export function delMeetingAndTrainingCalenderChangeInput({ name, value }) {
  return {
    type: DEL_USER_ACCESS_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startDelMeetingAndTrainingCalender() {
  return {
    type: START_DEL_USER_ACCESS,
  };
}
export function delMeetingAndTrainingCalender(params) {
  return function (dispatch) {
    dispatch(startDelMeetingAndTrainingCalender());
    httpClient
      .get(UrlConfig.UPDATED_NOTE, params)
      .then((resp) => dispatch(delMeetingAndTrainingCalenderSuccess(resp)))
      .catch((err) => dispatch(delMeetingAndTrainingCalenderFailure(err)));
  };
}
function delMeetingAndTrainingCalenderSuccess(resp) {
  return {
    type: DEL_USER_ACCESS_SUCCESS,
    payload: resp,
  };
}
function delMeetingAndTrainingCalenderFailure(err) {
  return {
    type: DEL_USER_ACCESS_FAILURE,
    payload: err,
  };
}
export function initDelMeetingAndTrainingCalenderActionStatus() {
  return {
    type: INIT_DEL_USER_ACCESS_ACTION_STATUS,
  };
}
export function resetDelMeetingAndTrainingCalenderModal() {
  return {
    type: RESET_DEL_USER_ACCESS_MODAL,
  };
}

//cop
export function openCopMeetingAndTrainingCalenderModal(info) {
  return {
    type: OPEN_COP_USER_ACCESS_MODAL,
    payload: info,
  };
}
export function copMeetingAndTrainingCalenderChangeInput({ name, value }) {
  return {
    type: COP_USER_ACCESS_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startCopMeetingAndTrainingCalender() {
  return {
    type: START_COP_USER_ACCESS,
  };
}
export function copMeetingAndTrainingCalender(params) {
  return function (dispatch) {
    dispatch(startCopMeetingAndTrainingCalender());
    httpClient
      .get(UrlConfig.COP_ALL_USER_ACCESS, params)
      .then((resp) => dispatch(copMeetingAndTrainingCalenderSuccess(resp)))
      .catch((err) => dispatch(copMeetingAndTrainingCalenderFailure(err)));
  };
}
function copMeetingAndTrainingCalenderSuccess(resp) {
  return {
    type: COP_USER_ACCESS_SUCCESS,
    payload: resp,
  };
}
function copMeetingAndTrainingCalenderFailure(err) {
  return {
    type: COP_USER_ACCESS_FAILURE,
    payload: err,
  };
}
export function initCopMeetingAndTrainingCalenderActionStatus() {
  return {
    type: INIT_COP_USER_ACCESS_ACTION_STATUS,
  };
}
export function resetCopMeetingAndTrainingCalenderModal() {
  return {
    type: RESET_COP_USER_ACCESS_MODAL,
  };
}

export function loadTrainingSubIndividualList(params) {
  return function (dispatch) {
    httpClient
      .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO_SUB_FORIN, params)
      .then((resp) => dispatch(loadTrainingSubIndividualListSuccess(resp)))
      .catch((err) => dispatch(loadTrainingSubIndividualListFailure(err)));
  };
}
function loadTrainingSubIndividualListSuccess(resp) {
  return {
    type: LOAD_TRAINING_SUB_INDIVIDUAL_SUCCESS,
    payload: resp,
  };
}
function loadTrainingSubIndividualListFailure(err) {
  return {
    type: LOAD_TRAINING_SUB_INDIVIDUAL_FAILURE,
    payload: err,
  };
}

export function loadRestrictedPinPointList(params) {
  return function (dispatch) {
    httpClient
      .get(UrlConfig.LOAD_MEETING_AND_TRAINING_CALENDER_PINPOINT, params)
      .then((resp) => dispatch(loadRestrictedPinPointListSuccess(resp)))
      .catch((err) => dispatch(loadRestrictedPinPointListFailure(err)));
  };
}
function loadRestrictedPinPointListSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITIES_PINPOINT_SUCCESS,
    payload: resp,
  };
}
function loadRestrictedPinPointListFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITIES_PINPOINT_FAILURE,
    payload: err,
  };
}

export function loadRestrictedPersonalList(params) {
  return function (dispatch) {
    httpClient
      .get(UrlConfig.LOAD_MEETING_AND_TRAINING_CALENDER_PERSONAL, params)
      .then((resp) => dispatch(loadRestrictedPersonalListSuccess(resp)))
      .catch((err) => dispatch(loadRestrictedPersonalListFailure(err)));
  };
}
function loadRestrictedPersonalListSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITIES_PERSONAL_SUCCESS,
    payload: resp,
  };
}
function loadRestrictedPersonalListFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITIES_PERSONAL_FAILURE,
    payload: err,
  };
}

export function openFinishTaskModal(task) {
  const { broker, subject } = task;
  return openOperationRecordDialogAndQuery(broker, subject);
  // return {
  //     type: OPEN_FINISH_TASK_MODAL,
  //     payload: task,
  // };
}

// query page
function openOperationRecordDialog(queryParams) {
  return {
    type: OPEN_OPERATION_RECORD_DIALOG,
    payload: queryParams,
  };
}
function startQueryOperationRecord() {
  return {
    type: START_QUERY_OPERATION_RECORD,
  };
}
function openOperationRecordDialogAndQuery(broker, subject) {
  const queryParams = {
    broker: broker,
    subject: subject,
  };

  return function (dispatch) {
    dispatch(openOperationRecordDialog(queryParams));
    dispatch(startQueryOperationRecord());
    httpClient
      .get(UrlConfig.LOAD_MEETING_AND_TRAINING_CALENDER_DETAILE, queryParams)
      .then((resp) => dispatch(queryOperationRecordSuccess(resp)))
      .catch((err) => dispatch(queryOperationRecordFailure(err)));
  };
}
function queryOperationRecordSuccess(resp) {
  return {
    type: QUERY_OPERATION_RECORD_SUCESS,
    payload: resp,
  };
}
function queryOperationRecordFailure(err) {
  return {
    type: QUERY_OPERATION_RECORD_FAILURE,
    payload: err,
  };
}
function initQueryOperationRecordStatus() {
  return {
    type: INIT_QUERY_OPERATION_RECORD_STATUS,
  };
}
function resetOperationRecordDialog() {
  return {
    type: RESET_OPERATION_RECORD_DIALOG,
  };
}

export function doOperationRecordAction(params) {
  const { action, queryFields, customColumns, detail } = params;
  if (action === "OPEN_AND_QUERY") {
    return openOperationRecordDialogAndQuery(queryFields, customColumns);
  } else if (action === "INIT_QUERY") {
    return initQueryOperationRecordStatus();
  } else if (action === "RESET_QUERY") {
    return resetOperationRecordDialog();
  } else if (action === "SHOW_DETAIL") {
    return showDetailRcd(detail);
  } else if (action === "RESET_DETAIL") {
    return resetDetailRcd();
  }
}

// detail page
function showDetailRcd(detail) {
  return {
    type: SHOW_DETAIL_RCD,
    payload: detail,
  };
}
function resetDetailRcd() {
  return {
    type: RESET_DETAIL_RCD,
  };
}

export function loadInstituteUserAccounts(params) {
  return function (dispatch) {
    httpClient
      .get(UrlConfig.QUERY_INSTITUTE_USER_ACCOUNTS, params)
      .then((resp) => dispatch(loadInstituteUserAccountsSuccess(resp)))
      .catch((err) => dispatch(loadInstituteUserAccountsFailure(err)));
  };
}
function loadInstituteUserAccountsSuccess(resp) {
  return {
    type: LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
    payload: resp,
  };
}
function loadInstituteUserAccountsFailure(err) {
  return {
    type: LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
    payload: err,
  };
}

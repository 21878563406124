import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import moment from 'moment';

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { TRADE_APPROVAL_STATUS_CODE } from '../../../../../utils/PersonalTradeApprovalStatus';
import { TRADE_REQUEST_STATUS_CODE } from '../../../../../utils/PersonalTradeRequestStatus';
import PreviewTradeAttachRenderer from '../../../../renderer/PreviewTradeAttachRenderer';
import '../../ApprovalRequest.css';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';
class PersonalTradeGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.personalTradeGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "multiple",
      context: { componentParent: this },
      frameworkComponents: {
        previewTradeAttachRenderer: PreviewTradeAttachRenderer,
      },
      rowClassRules: {
        // 'UnProcessedReport': function(params) {
        //   return !params.data.isAbandoned;
        // },
        'not-upload-proof': function(params) {
          const tradeRequest = params.data;
          return !tradeRequest.isAbandoned &&
          tradeRequest.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED &&
          (tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED || tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.PENDING_FINAL_APPROVAL) &&
          moment().diff(moment(tradeRequest.tradeDateLabel), 'days') > tradeRequest.finishInDays && 
          (!tradeRequest.actualQty && tradeRequest.actualQty !== 0 ||  tradeRequest.actualQty !== 0 && !(tradeRequest.attachment));
        },
      },
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var personalTradeList = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectPersonalTradeRequest(personalTradeList);
  }

  getRowNodeId = data => {
    return data.tradeId;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'personal-trade-list-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  render() {
    const {
      personalTradeArray,
      className = 'grid-wrapper',
      loadPersonalTradeActionStatus,
    } = this.props;

    const isLoading = loadPersonalTradeActionStatus === ACTION_STATUS.LOGINING;
    if (loadPersonalTradeActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadPersonalTradeActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={personalTradeArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default PersonalTradeGrid;
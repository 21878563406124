import React, {Component} from 'react';

import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { SIDE_TYPE_CODE } from '../../../../utils/SideTypeUtil';
import { PRICE_TYPE_CODE } from '../../../../utils/PriceTypeUtil';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import PersonalTradeOperateMode from '../../../../utils/PersonalTradeOperateMode';
import DataEditMode from '../../../../utils/DataEditMode';
import { Select } from 'antd';
import moment from 'moment';

import {
  // Load Personal Trade
  START_LOAD_PERSONAL_TRADE,
  LOAD_PERSONAL_TRADE_SUCCESS,
  LOAD_PERSONAL_TRADE_FAILURE,
  INIT_LOAD_PERSONAL_TRADE_ACTION_STATUS,
  QUERY_PERSONAL_TRADE_INPUT_CHANGE,

  // Select Personal Trade Request
  SELECT_PERSONAL_TRADE_REQUEST,

  // Open/Reset Personal Trade Feedback Modal Dialog
  PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_RESET,

  // Personal Trade Feedback Edit Change Input
  PERSONAL_TRADE_FEEDBACK_EDIT_CHANGE_INPUT,

  // Approve Personal Trade
  START_APPROVE_PERSONAL_TRADE,
  APPROVE_PERSONAL_TRADE_SUCCESS,
  APPROVE_PERSONAL_TRADE_FAILURE,
  INIT_APPROVE_PERSONAL_TRADE_ACTION_STATUS,

  // Final Approve Personal Trade
  START_FINAL_APPROVE_PERSONAL_TRADE,
  FINAL_APPROVE_PERSONAL_TRADE_SUCCESS,
  FINAL_APPROVE_PERSONAL_TRADE_FAILURE,
  INIT_FINAL_APPROVE_PERSONAL_TRADE_ACTION_STATUS,

  // Reject Personal Trade
  START_REJECT_PERSONAL_TRADE,
  REJECT_PERSONAL_TRADE_SUCCESS,
  REJECT_PERSONAL_TRADE_FAILURE,
  INIT_REJECT_PERSONAL_TRADE_ACTION_STATUS,

  // Feedback Personal Trade
  START_FEEDBACK_PERSONAL_TRADE,
  FEEDBACK_PERSONAL_TRADE_SUCCESS,
  FEEDBACK_PERSONAL_TRADE_FAILURE,
  INIT_FEEDBACK_PERSONAL_TRADE_ACTION_STATUS,
  
  // Open/Reset Personal Trade Operation Record Modal Dialog
  PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_RESET,

  // Load Personal Trade Operation Record
  START_LOAD_PERSONAL_TRADE_OPERATION_RECORD,
  LOAD_PERSONAL_TRADE_OPERATION_RECORD_SUCCESS,
  LOAD_PERSONAL_TRADE_OPERATION_RECORD_FAILURE,
  INIT_LOAD_PERSONAL_TRADE_OPERATION_RECORD_ACTION_STATUS,

  // Open/Reset Personal Trade Request Edit Modal Dialog
  PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_RESET,

  // Personal Trade Request Edit Change Input
  PERSONAL_TRADE_REQUEST_EDIT_CHANGE_INPUT,

  // Add Personal Trade Request
  START_ADD_PERSONAL_TRADE_REQUEST,
  ADD_PERSONAL_TRADE_REQUEST_SUCCESS,
  ADD_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_ADD_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Edit Personal Trade Request
  START_EDIT_PERSONAL_TRADE_REQUEST,
  EDIT_PERSONAL_TRADE_REQUEST_SUCCESS,
  EDIT_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_EDIT_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Batch Delete Personal Trade Request
  START_BATCH_DELETE_PERSONAL_TRADE_REQUEST,
  BATCH_DELETE_PERSONAL_TRADE_REQUEST_SUCCESS,
  BATCH_DELETE_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_BATCH_DELETE_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Copy Personal Trade Request
  START_COPY_PERSONAL_TRADE_REQUEST,
  COPY_PERSONAL_TRADE_REQUEST_SUCCESS,
  COPY_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_COPY_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Load BBG Tickers
  START_LOAD_BBG_TICKERS,
  LOAD_BBG_TICKERS_SUCCESS,
  LOAD_BBG_TICKERS_FAILURE,
  INIT_LOAD_BBG_TICKERS_ACTION_STATUS,

  // Clear bbgTickerArray bbgTickerOptions
  CLEAR_BBG_TICKERS,

  // BBG Ticker Select Changed
  BBG_TICKER_SELECT_CHANGED,

  // Load Personal Trade Field History
  START_LOAD_TRADE_FIELD_HISTORY,
  LOAD_TRADE_FIELD_HISTORY_SUCCESS,
  LOAD_TRADE_FIELD_HISTORY_FAILURE,
  INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS,

  // Load Exchange Code
  START_LOAD_EXCHANGE_CODE,
  LOAD_EXCHANGE_CODE_SUCCESS,
  LOAD_EXCHANGE_CODE_FAILURE,
  INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS,

  // Clear exchageCodeArray exchageCodeOptions
  CLEAR_EXCHANGE_CODES,

  // Exchange Code Select Changed
  EXCHANGE_CODE_SELECT_CHANGED,

  // Open/Reset Personal Trade Request Recognize Modal Dialog
  PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_RESET,

  // Recognize Personal Trade Request
  START_RECOGNIZE_PERSONAL_TRADE_REQUEST,
  RECOGNIZE_PERSONAL_TRADE_REQUEST_SUCCESS,
  RECOGNIZE_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_RECOGNIZE_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Open/Reset Personal Trade Request Operate Modal Dialog
  PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_RESET,

  // Open/Reset Monthly Report Modal Dialog
  MONTHLY_REPORT_MODAL_DIALOG_OPEN,
  MONTHLY_REPORT_MODAL_DIALOG_RESET,

  // Load Monthly Report
  START_LOAD_MONTHLY_REPORT,
  LOAD_MONTHLY_REPORT_SUCCESS,
  LOAD_MONTHLY_REPORT_FAILURE,
  INIT_LOAD_MONTHLY_REPORT_ACTION_STATUS,

  // Send PA Dealing Apply Monthly Report Email
  START_SEND_PA_DEALING_APPLY_REPORT_EMAIL,
  SEND_PA_DEALING_APPLY_REPORT_EMAIL_SUCCESS,
  SEND_PA_DEALING_APPLY_REPORT_EMAIL_FAILURE,
  INIT_SEND_PA_DEALING_APPLY_REPORT_EMAIL_ACTION_STATUS,

  // 30天持仓相关预检查
  START_QUERY_30_DAY_HOLDING_CHECK_INFO,
  QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS,
  QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE,
  RESET_30_DAY_HOLDING_CHECK_INFO,
  START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,

} from './personalTradeConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,

} from '../../home/homeConstants';

const Option = Select.Option;

const initialState = {
  personalTradeGridColumns: GridColumnMap.personalTradeGridColumns,

  personalTradeQueryParam: {
    employee: undefined,
    tradeDateStart:  moment(),
    tradeDateEnd:  moment(),

  },

  // Personal Trade
  personalTradeArray: [],

  // PersonalTrade Feedback
  personalTradeFeedbackControlModal: {
    isOpened: false,
    respErrMsg: '',
    fields: {
      id: '',
      feedback: '',
    }
  },

  // Selected Personal Trade
  selectedPersonalTradeArray: [],

  // Load Personal Trade Action Status
  loadPersonalTradeActionStatus: ACTION_STATUS.READY,

  // Approve Personal Trade Action Status
  approvePersonalTradeActionStatus: ACTION_STATUS.READY,

  // Final Approve Personal Trade Action Status
  finalApprovePersonalTradeActionStatus: ACTION_STATUS.READY,

  // Reject Personal Trade Action Status
  rejectPersonalTradeActionStatus: ACTION_STATUS.READY,

  // Feedback Personal Trade Action Status
  feedbackPersonalTradeActionStatus: ACTION_STATUS.READY,

  // Personal Trade Operation Record Modal Dialog
  personalTradeOpRecordControlModal: {
    isOpened: false,
  },

  personalTradeOpRecordGridColumns: GridColumnMap.personalTradeOpRecordGridColumns,

  // Load Personal Trade Operation Record Action Status
  loadPersonalTradeOperationRecordActionStatus: ACTION_STATUS.READY,
  personalTradeOperationRecordArray: [],

  // Personal Trade Request Edit Control Modal
  personalTradeRequestEditControlModal: {
    isOpened: false,
    editMode: DataEditMode.NEW,
    respErrMsg: '',
    fields: {
      tradeId: '',
      employee: '',
      accountGuid: '',
      securityId: '',
      bbgTicker: '',
      secName: '',
      stockMarket: '',
      side: SIDE_TYPE_CODE.BUY,
      priceType: PRICE_TYPE_CODE.MARKET,
      price: '',
      applyQty: '',
      actualQty: '',
      currency: '',
      tradeDate: '',
      holdingDays: '',
      remark: '',
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
      finishInDays: 2,
      isPassive: false,
    }
  },

  // Add/Edit/Delete Personal Trade Request Action Status
  addPersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  editPersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  batchDeletePersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  copyPersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  batchDeletePersonalTradeRequestErrMsg: '',

  // Security Ticker Data
  securityTickerData: null,
  bbgTickerArray: [],
  bbgTickerOptions: [],
  securityTickerArray: [],

  // Load BBG Tickers Action Status
  loadBbgTickersActionStatus: ACTION_STATUS.READY,

  // Load Personal Trade Field History
  loadTradeFieldHistoryActionStatus: ACTION_STATUS.READY,
  personalTradeFieldHistory: {
    accountNameList: [],
    brokerAccountList: [],
    accountNoList: [],
    // Option
    accountNameOptions: [],
    brokerAccountOptions: [],
    accountNoOptions: [],
  },

  // Load Exchange Code
  loadExchangeCodeActionStatus: ACTION_STATUS.READY,
  exchageCodeArray: [],
  exchageCodeOptions: [],

  // Load User Account
  userAccountArray: [],
  userAccountOptions: [],

  // Personal Trade Request Recognize Modal Dialog
  personalTradeRequestRecognizeControlModal: {
    isOpened: false,
    personalTradeRequestIdList: [],
    respErrMsg: '',
  },

  // Personal Trade Request Recognize Grid Columns
  personalTradeRecognizeGridColumns: GridColumnMap.personalTradeRecognizeGridColumns,

  // Recognize Personal Trade Request
  recognizePersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  recognizePersonalTradeRequestErrMsg: '',
  recognizePersonalTradeResultArray: [],

  // 附件操作URL
  tradeAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_PERSONAL_TRADE_ATTACHMENT,
  tradeAttachmentPreviewUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PREVIEW_PERSONAL_TRADE_ATTACHMENT,
  tradeAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_PERSONAL_TRADE_ATTACHMENT,

  // Personal Trade Request Operate Modal Dialog
  personalTradeRequestOperateControlModal: {
    isOpened: false,
    operateMode: PersonalTradeOperateMode.APPROVE,
    isNonCompliance: false,
    personalTradeRequestIdList: [],
    respErrMsg: '',
  },

  // Open/Reset Monthly Report Modal Dialog
  monthlyReportControlModal: {
    isOpened: false,
  },

  // Monthly Report Result
  monthlyReportResult: null,

  // Load Monthly Report Action Status
  loadMonthlyReportActionStatus: ACTION_STATUS.READY,

  // Send PA Dealing Apply Monthly Report Email Action Status
  sendPADealingApplyMonthlyReportEmailActionStatus: ACTION_STATUS.READY,
  sendPADealingApplyMonthlyReportEmailErrMsg: '',

  // PA Dealing Daily Report操作URL
  exportPADealingDailyReportUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.EXPORT_PA_DEALING_DAILY_REPORT,

  // 30天持仓相关预检查
  resultOf30DayHoldingCheckInfo: {
    allQuantity: '',
    in30DaysBuyQty: '',
    approveWaitSellQty: '',
    submittedNotAbandonedQty: '',
    availableForSellQty: '',
  }
}

// Load  Personal Trade
function startLoadPersonalTrade(state) {
  return {
    ...state,
    loadPersonalTradeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalTradeSuccess(state, resp) {
  return {
    ...state,
    loadPersonalTradeActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeArray: resp.data,
  };
}

function loadPersonalTradeFailure(state, err) {
  return {
    ...state,
    loadPersonalTradeActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPersonalTradeActionStatus(state) {
  return {
    ...state,
    loadPersonalTradeActionStatus: ACTION_STATUS.READY,
  };
}

function queryPersonalTradeInputChange(state, {name, value}) {
  return {
    ...state,
    personalTradeQueryParam: {
      ...state.personalTradeQueryParam,
      [name]: value,
    }
  }
}


// Select Personal Trade Request
function selectPersonalTradeRequest(state, payload) {
  return {
    ...state,
    selectedPersonalTradeArray: payload,
  };
}

// Open/Reset Personal Trade Feedback Modal Dialog
function openPersonalTradeFeedbackModalDialog(state, {personalTrade}) {
  return {
    ...state,
    personalTradeFeedbackControlModal: {
      ...state.personalTradeFeedbackControlModal,
      isOpened: true,
      fields: {
        ...state.personalTradeFeedbackControlModal.fields,
        id: personalTrade.tradeId,
        feedback: personalTrade.feedback,
      }
    },
  };
}

function resetPersonalTradeFeedbackModalDialog(state) {
  return {
    ...state,
    personalTradeFeedbackControlModal: initialState.personalTradeFeedbackControlModal,
  };
}

// Personal Trade Feedback Edit Change Input
function personalTradeFeedbackEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.personalTradeFeedbackControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    personalTradeFeedbackControlModal: {
      ...state.personalTradeFeedbackControlModal,
      fields: updatedFields,
    },
  };
}

// Approve Personal Trade
function startApprovePersonalTrade(state) {
  return {
    ...state,
    approvePersonalTradeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function approvePersonalTradeSuccess(state, resp) {
  return {
    ...state,
    approvePersonalTradeActionStatus: ACTION_STATUS.SUCCESS,
    selectedPersonalTradeArray: [],
  };
}

function approvePersonalTradeFailure(state, err) {
  return {
    ...state,
    approvePersonalTradeActionStatus: ACTION_STATUS.ERROR,
    personalTradeRequestOperateControlModal: {
      ...state.personalTradeRequestOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initApprovePersonalTradeActionStatus(state) {
  return {
    ...state,
    approvePersonalTradeActionStatus: ACTION_STATUS.READY,
  };
}

// Final Approve Personal Trade
function startFinalApprovePersonalTrade(state) {
  return {
    ...state,
    finalApprovePersonalTradeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function finalApprovePersonalTradeSuccess(state, resp) {
  return {
    ...state,
    finalApprovePersonalTradeActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function finalApprovePersonalTradeFailure(state, err) {
  return {
    ...state,
    finalApprovePersonalTradeActionStatus: ACTION_STATUS.ERROR,
    personalTradeRequestOperateControlModal: {
      ...state.personalTradeRequestOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initFinalApprovePersonalTradeActionStatus(state) {
  return {
    ...state,
    finalApprovePersonalTradeActionStatus: ACTION_STATUS.READY,
  };
}

// Reject Personal Trade
function startRejectPersonalTrade(state) {
  return {
    ...state,
    rejectPersonalTradeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function rejectPersonalTradeSuccess(state, resp) {
  return {
    ...state,
    rejectPersonalTradeActionStatus: ACTION_STATUS.SUCCESS,
    selectedPersonalTradeArray: [],
  };
}

function rejectPersonalTradeFailure(state, err) {
  return {
    ...state,
    rejectPersonalTradeActionStatus: ACTION_STATUS.ERROR,
    personalTradeRequestOperateControlModal: {
      ...state.personalTradeRequestOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initRejectPersonalTradeActionStatus(state) {
  return {
    ...state,
    rejectPersonalTradeActionStatus: ACTION_STATUS.READY,
  };
}

// Feedback Personal Trade
function startFeedbackPersonalTrade(state) {
  return {
    ...state,
    feedbackPersonalTradeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function feedbackPersonalTradeSuccess(state, resp) {
  return {
    ...state,
    feedbackPersonalTradeActionStatus: ACTION_STATUS.SUCCESS,
    selectedPersonalTradeArray: [],
  };
}

function feedbackPersonalTradeFailure(state, err) {
  return {
    ...state,
    feedbackPersonalTradeActionStatus: ACTION_STATUS.ERROR,
    respErrMsg: err.respMessage,
  };
}

function initFeedbackPersonalTradeActionStatus(state) {
  return {
    ...state,
    feedbackPersonalTradeActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Personal Trade Operation Record Modal Dialog
function openPersonalTradeOperationRecordModalDialog(state) {
  return {
    ...state,
    personalTradeOpRecordControlModal: {
      ...state.personalTradeOpRecordControlModal,
      isOpened: true,
    },
  };
}

function resetPersonalTradeOperationRecordModalDialog(state) {
  return {
    ...state,
    personalTradeOpRecordControlModal: initialState.personalTradeOpRecordControlModal,
  };
}

// Load Personal Trade Operation Record
function startLoadPersonalTradeOperationRecord(state) {
  return {
    ...state,
    loadPersonalTradeOperationRecordActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalTradeOperationRecordSuccess(state, resp) {
  return {
    ...state,
    loadPersonalTradeOperationRecordActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeOperationRecordArray: resp.data,
  };
}

function loadPersonalTradeOperationRecordFailure(state, err) {
  return {
    ...state,
    loadPersonalTradeOperationRecordActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPersonalTradeOperationRecordActionStatus(state) {
  return {
    ...state,
    loadPersonalTradeOperationRecordActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Personal Trade Request Edit Modal Dialog
function openPersonalTradeRequestEditModalDialog(state, {editMode, tradeRequest}) {
  if (editMode === DataEditMode.NEW) {
    return {
      ...state,
      personalTradeRequestEditControlModal: {
        ...state.personalTradeRequestEditControlModal,
        isOpened: true,
        editMode,
        fields: {
          ...state.personalTradeRequestEditControlModal.fields,
          tradeDate: moment().format('YYYY-MM-DD'),
          isPassive: 'false'
        }
      },
    };
  } 
  
  if (editMode === DataEditMode.MODIFY) {
    const tradeAttachmentDownloadUrl = state.tradeAttachmentDownloadUrl;

    const fileList = [];
    const attachment = tradeRequest.attachment;
    const attachmentOriginName = tradeRequest.attachmentOriginName;

    if (attachment && attachmentOriginName) {
      const file = {
        uid: '-1',
        name: attachmentOriginName,
        status: 'done',
        url: tradeAttachmentDownloadUrl + "/" + attachment,
        response: {
          respCode: "S0001",
          data: {
            attachment,
            attachmentOriginName,
          }
        }
      };
      fileList.push(file);
    }

    return {
      ...state,
      personalTradeRequestEditControlModal: {
        ...state.personalTradeRequestEditControlModal,
        isOpened: true,
        editMode,
        fields: {
          ...state.personalTradeRequestEditControlModal.fields,
          tradeId: tradeRequest.tradeId,
          employee: tradeRequest.employee,
          accountGuid: tradeRequest.accountGuid,
          securityId: tradeRequest.securityId,
          bbgTicker: tradeRequest.bbgTicker,
          secName: tradeRequest.secName,
          stockMarket: tradeRequest.stockMarket,
          side: tradeRequest.side,
          priceType: tradeRequest.priceType,
          price: tradeRequest.price,
          applyQty: tradeRequest.applyQty,
          actualQty: tradeRequest.actualQty,
          currency: tradeRequest.currency,
          tradeDate: tradeRequest.tradeDate,
          holdingDays: tradeRequest.holdingDays,
          remark: tradeRequest.remark,
          attachment: tradeRequest.attachment,
          attachmentOriginName: tradeRequest.attachmentOriginName,
          fileList,
          finishInDays: tradeRequest.finishInDays,
          category: tradeRequest.category,
          isPassive: tradeRequest.isPassive ? 'true' : 'false'
        }
      },
    };
  }

  if (editMode === DataEditMode.COPY) {
    return {
      ...state,
      personalTradeRequestEditControlModal: {
        ...state.personalTradeRequestEditControlModal,
        isOpened: true,
        editMode,
        fields: {
          ...state.personalTradeRequestEditControlModal.fields,
          tradeId: tradeRequest.tradeId,
          employee: tradeRequest.employee,
          accountGuid: tradeRequest.accountGuid,
          securityId: tradeRequest.securityId,
          bbgTicker: tradeRequest.bbgTicker,
          secName: tradeRequest.secName,
          stockMarket: tradeRequest.stockMarket,
          side: tradeRequest.side,
          priceType: tradeRequest.priceType,
          price: tradeRequest.price,
          applyQty: tradeRequest.applyQty,
          actualQty: tradeRequest.actualQty,
          currency: tradeRequest.currency,
          tradeDate: moment().format('YYYY-MM-DD'),
          holdingDays: tradeRequest.holdingDays,
          remark: '',
          attachment: '',
          attachmentOriginName: '',
          finishInDays: tradeRequest.finishInDays,
          category: tradeRequest.category,
          isPassive: tradeRequest.isPassive ? 'true' : 'false'
        }
      },
    };
  }
}

function resetPersonalTradeRequestEditModalDialog(state) {
  return {
    ...state,
    personalTradeRequestEditControlModal: initialState.personalTradeRequestEditControlModal,
  };
}

// Personal Trade Request Edit Change Input
function personalTradeRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.personalTradeRequestEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// Add Personal Trade Request
function startAddPersonalTradeRequest(state) {
  return {
    ...state,
    addPersonalTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function addPersonalTradeRequestSuccess(state, resp) {
  return {
    ...state,
    addPersonalTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      respErrMsg: '',
    },
  };
}

function addPersonalTradeRequestFailure(state, err) {
  return {
    ...state,
    addPersonalTradeRequestActionStatus: ACTION_STATUS.ERROR,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      respErrMsg: err.respErrMsg,
    },
  };
}

function initAddPersonalTradeRequestActionStatus(state) {
  return {
    ...state,
    addPersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Edit Personal Trade Request
function startEditPersonalTradeRequest(state) {
  return {
    ...state,
    editPersonalTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function editPersonalTradeRequestSuccess(state, resp) {
  return {
    ...state,
    editPersonalTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      respErrMsg: '',
    },
  };
}

function editPersonalTradeRequestFailure(state, err) {
  return {
    ...state,
    editPersonalTradeRequestActionStatus: ACTION_STATUS.ERROR,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      respErrMsg: err.respErrMsg,
    },
  };
}

function initEditPersonalTradeRequestActionStatus(state) {
  return {
    ...state,
    editPersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Personal Trade Request
function startBatchDeletePersonalTradeRequest(state) {
  return {
    ...state,
    batchDeletePersonalTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeletePersonalTradeRequestSuccess(state, resp) {
  return {
    ...state,
    batchDeletePersonalTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeletePersonalTradeRequestFailure(state, err) {
  return {
    ...state,
    batchDeletePersonalTradeRequestActionStatus: ACTION_STATUS.ERROR,
    batchDeletePersonalTradeRequestErrMsg: err.respErrMsg,
  };
}

function initBatchDeletePersonalTradeRequestActionStatus(state) {
  return {
    ...state,
    batchDeletePersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Copy Personal Trade Request
function startCopyPersonalTradeRequest(state) {
  return {
    ...state,
    copyPersonalTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function copyPersonalTradeRequestSuccess(state, resp) {
  return {
    ...state,
    copyPersonalTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      respErrMsg: '',
    },
  };
}

function copyPersonalTradeRequestFailure(state, err) {
  return {
    ...state,
    copyPersonalTradeRequestActionStatus: ACTION_STATUS.ERROR,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      respErrMsg: err.respErrMsg,
    },
  };
}

function initCopyPersonalTradeRequestActionStatus(state) {
  return {
    ...state,
    copyPersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  let securityTickerArray = securityTickerData.securityTickerList;

  return {
    ...state,
    securityTickerData,
    securityTickerArray,
  };
}

// =================================================================
// Load BBG Tickers
function startLoadBBGTickers(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadBbgTickersSuccess(state, resp) {
  const bbgTickerArray = resp.data;

  const bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);
  
  return {
    ...state,
    bbgTickerArray,
    bbgTickerOptions,
    loadBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadBbgTickersFailure(state, err) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadBbgTickersActionStatus(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.READY,
  };
}


// Clear bbgTickerArray bbgTickerOptions
function clearBbgTickers(state) {
  return {
    ...state,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      fields: {
        ...state.personalTradeRequestEditControlModal.fields,
        securityId: '',
        secName: '',
        currency: '',
        stockMarket: '',
      },
    },
    bbgTickerArray: [],
    bbgTickerOptions: [],
  };
}

// BBG Ticker Select Changed
function bbgTickerSelectChanged(state, bbgTicker) {
  const securityTickerArray = state.securityTickerArray;
  console.log('securityTickerArray.length is:' + securityTickerArray.length)
  let securityId = '';
  let securityName = '';
  let currency = '';
  let stockMarket = '';

  const fields = state.personalTradeRequestEditControlModal.fields;
  securityName = securityName === '' ? fields.secName : securityName;
  currency = currency === '' ? fields.currency : currency;
  stockMarket = stockMarket === '' ? fields.stockMarket : stockMarket;

  if (!!bbgTicker) {
    const filterSecurityTickerArray = securityTickerArray.filter(securityTicker => securityTicker.bbgTicker === bbgTicker);
    if (filterSecurityTickerArray.length > 0) {
      const securityTicker = filterSecurityTickerArray[0];
      securityId = securityTicker.securityId;
      securityName = securityTicker.securityName;
      currency = securityTicker.currency;
      stockMarket = securityTicker.exchangeCode;
    }
  } 

  return {
    ...state,
    personalTradeRequestEditControlModal: {
      ...state.personalTradeRequestEditControlModal,
      fields: {
        ...state.personalTradeRequestEditControlModal.fields,
        securityId,
        secName: securityName,
        currency,
        stockMarket,
      },
    },
  };
}

// Load Personal Trade Field History
function startLoadPersonalTradeFieldHistory(state) {
  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalTradeFieldHistorySuccess(state, resp) {
  const accountNameList = resp.data.accountNameList;
  const brokerAccountList = resp.data.brokerAccountList;
  const accountNoList = resp.data.accountNoList;

  // Option
  let accountNameOptions = accountNameList.map(accountName => <Option key={accountName}>{accountName}</Option>);
  let brokerAccountOptions = brokerAccountList.map(brokerAccount => <Option key={brokerAccount}>{brokerAccount}</Option>);
  let accountNoOptions = accountNoList.map(accountNo => <Option key={accountNo}>{accountNo}</Option>);

  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeFieldHistory: {
      accountNameList,
      brokerAccountList,
      accountNoList,
      // Option
      accountNameOptions,
      brokerAccountOptions,
      accountNoOptions,
    },
  };
}

function loadPersonalTradeFieldHistoryFailure(state, err) {
  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.ERROR,
    personalTradeFieldHistory: initialState.personalTradeFieldHistory,
  };
}

function initLoadPersonalTradeFieldHistoryActionStatus(state) {
  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.READY,
  };
}

// Load Exchange Code
function startLoadExchangeCode(state) {
  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadExchangeCodeSuccess(state, resp) {
  const exchageCodeArray = resp.data;

  const exchageCodeOptions = exchageCodeArray.map(exchangeCode => <Option key={exchangeCode}>{exchangeCode}</Option>);

  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.SUCCESS,
    exchageCodeArray,
    exchageCodeOptions,
  };
}

function loadExchangeCodeFailure(state, err) {
  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadExchangeCodeActionStatus(state) {
  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.READY,
  };
}

// Clear exchageCodeArray exchageCodeOptions
function clearExchangeCodes(state) {
  return {
    ...state,
    exchageCodeArray: [],
    exchageCodeOptions: [],
  };
}

// Exchange Code Select Changed
function exchangeCodeSelectChanged(state, exchangeCode) {
  return {
    ...state,
  };
}


// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}


// Open/Reset Personal Trade Request Recognize Modal Dialog
function openPersonalTradeRequestRecognizeModalDialog(state, { personalTradeRequestIdList }) {
  return {
    ...state,
    personalTradeRequestRecognizeControlModal: {
      ...state.personalTradeRequestRecognizeControlModal,
      isOpened: true,
      personalTradeRequestIdList,
    },
  };
}

function resetPersonalTradeRequestRecognizeModalDialog(state) {
  return {
    ...state,
    personalTradeRequestRecognizeControlModal: initialState.personalTradeRequestRecognizeControlModal,
  };
}


// Recognize Personal Trade Request
function startRecognizePersonalTradeRequest(state) {
  return {
    ...state,
    recognizePersonalTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function recognizePersonalTradeRequestSuccess(state, resp) {
  return {
    ...state,
    recognizePersonalTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
    recognizePersonalTradeResultArray: resp.data,
  };
}

function recognizePersonalTradeRequestFailure(state, err) {
  return {
    ...state,
    recognizePersonalTradeRequestActionStatus: ACTION_STATUS.ERROR,
    recognizePersonalTradeRequestErrMsg: err.respErrMsg,
  };
}

function initRecognizePersonalTradeRequestActionStatus(state) {
  return {
    ...state,
    recognizePersonalTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Personal Trade Request Operate Modal Dialog
function openPersonalTradeRequestOperateModalDialog(state, {operateMode, isNonCompliance, personalTradeRequestIdList}) {
  return {
    ...state,
    personalTradeRequestOperateControlModal: {
      ...state.personalTradeRequestOperateControlModal,
      isOpened: true,
      operateMode,
      isNonCompliance,
      personalTradeRequestIdList,
    },
  };
}

function resetPersonalTradeRequestOperateModalDialog(state) {
  return {
    ...state,
    personalTradeRequestOperateControlModal: initialState.personalTradeRequestOperateControlModal,
  };
}

// Open/Reset Monthly Report Modal Dialog
function openMonthlyReportModalDialog(state) {
  return {
    ...state,
    monthlyReportControlModal: {
      ...state.monthlyReportControlModal,
      isOpened: true,
    },
  };
}

function resetMonthlyReportModalDialog(state) {
  return {
    ...state,
    monthlyReportControlModal: initialState.monthlyReportControlModal,
  };
}

// Load Monthly Report
function startLoadMonthlyReport(state) {
  return {
    ...state,
    loadMonthlyReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadMonthlyReportSuccess(state, resp) {
  return {
    ...state,
    loadMonthlyReportActionStatus: ACTION_STATUS.SUCCESS,
    monthlyReportResult: resp.data,
  };
}

function loadMonthlyReportFailure(state, err) {
  return {
    ...state,
    loadMonthlyReportActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadMonthlyReportActionStatus(state) {
  return {
    ...state,
    loadMonthlyReportActionStatus: ACTION_STATUS.READY,
  };
}

// Send PA Dealing Apply Monthly Report Email
function startSendPADealingApplyMonthlyReportEmail(state) {
  return {
    ...state,
    sendPADealingApplyMonthlyReportEmailActionStatus: ACTION_STATUS.LOGINING,
  };
}

function sendPADealingApplyMonthlyReportEmailSuccess(state, resp) {
  return {
    ...state,
    sendPADealingApplyMonthlyReportEmailActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function sendPADealingApplyMonthlyReportEmailFailure(state, err) {
  return {
    ...state,
    sendPADealingApplyMonthlyReportEmailActionStatus: ACTION_STATUS.ERROR,
    sendPADealingApplyMonthlyReportEmailErrMsg: err.respMessage,
  };
}

function initSendPADealingApplyMonthlyReportEmailActionStatus(state) {
  return {
    ...state,
    sendPADealingApplyMonthlyReportEmailActionStatus: ACTION_STATUS.READY,
  };
}



// 30天持仓相关预检查
function startQuery30DayHoldingCheckInfo(state) {
  return {
    ...state,
    query30DayHoldingCheckInfoStatus: ACTION_STATUS.LOGINING,
  };
}
function query30DayHoldingCheckInfoSuccess(state, resp) {
  if(!!resp && !!resp.data && !!resp.data.resultOf30DaysHolding && resp.data.resultOf30DaysHolding.length > 0) {
    const {
      allQuantity,
      in30DaysBuyQty,
      approveWaitSellQty,
      submittedNotAbandonedQty,
    } = resp.data.resultOf30DaysHolding[0];
    let availableForSellQty = '';
    if(allQuantity!== undefined && allQuantity !== null && allQuantity !== '' && !isNaN(allQuantity)
        && in30DaysBuyQty!== undefined && in30DaysBuyQty !== null && in30DaysBuyQty !== '' && !isNaN(in30DaysBuyQty)
        && approveWaitSellQty!== undefined && approveWaitSellQty !== null && approveWaitSellQty !== '' && !isNaN(approveWaitSellQty)
        && submittedNotAbandonedQty!== undefined && submittedNotAbandonedQty !== null && submittedNotAbandonedQty !== '' && !isNaN(submittedNotAbandonedQty)
    ) {
            availableForSellQty = allQuantity - in30DaysBuyQty - approveWaitSellQty - submittedNotAbandonedQty;  
    }

    return {
      ...state,
      query30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      resultOf30DayHoldingCheckInfo: {
        allQuantity,
        in30DaysBuyQty,
        approveWaitSellQty,
        submittedNotAbandonedQty,
        availableForSellQty,
      }
    };
  } else {
    return {
      ...state,
      query30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      resultOf30DayHoldingCheckInfo: {
        ...initialState.resultOf30DayHoldingCheckInfo,
      }
    };
  }
}
function query30DayHoldingCheckInfoFailure(state, err) {
  return {
    ...state,
    query30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
    resultOf30DayHoldingCheckInfo: {
      ...initialState.resultOf30DayHoldingCheckInfo,
    }
  };
}
function reset30DayHoldingCheckInfo(state) {
  return {
    ...state,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
    resultOf30DayHoldingCheckInfo: {
      ...initialState.resultOf30DayHoldingCheckInfo,
    }
  };
}
function startTrigger30DayHoldingCheckInfoStatus(state) {
  return {
    ...state,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.LOGINING,
  };
}
function stopTrigger30DayHoldingCheckInfoStatus(state) {
  return {
    ...state,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
  };
}


export default createReducer(initialState, {
  // Load Personal Trade
  [START_LOAD_PERSONAL_TRADE]: startLoadPersonalTrade,
  [LOAD_PERSONAL_TRADE_SUCCESS]: loadPersonalTradeSuccess,
  [LOAD_PERSONAL_TRADE_FAILURE]: loadPersonalTradeFailure,
  [INIT_LOAD_PERSONAL_TRADE_ACTION_STATUS]: initLoadPersonalTradeActionStatus,
  [QUERY_PERSONAL_TRADE_INPUT_CHANGE]: queryPersonalTradeInputChange,
  
  // Select Personal Trade Request
  [SELECT_PERSONAL_TRADE_REQUEST]: selectPersonalTradeRequest,

  // Open/Reset Personal Trade Feedback Modal Dialog
  [PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_OPEN]: openPersonalTradeFeedbackModalDialog,
  [PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_RESET]: resetPersonalTradeFeedbackModalDialog,

  // Personal Trade Feedback Edit Change Input
  [PERSONAL_TRADE_FEEDBACK_EDIT_CHANGE_INPUT]: personalTradeFeedbackEditChangeInput,

  // Approve Personal Trade
  [START_APPROVE_PERSONAL_TRADE]: startApprovePersonalTrade,
  [APPROVE_PERSONAL_TRADE_SUCCESS]: approvePersonalTradeSuccess,
  [APPROVE_PERSONAL_TRADE_FAILURE]: approvePersonalTradeFailure,
  [INIT_APPROVE_PERSONAL_TRADE_ACTION_STATUS]: initApprovePersonalTradeActionStatus,

  // Final Approve Personal Trade
  [START_FINAL_APPROVE_PERSONAL_TRADE]: startFinalApprovePersonalTrade,
  [FINAL_APPROVE_PERSONAL_TRADE_SUCCESS]: finalApprovePersonalTradeSuccess,
  [FINAL_APPROVE_PERSONAL_TRADE_FAILURE]: finalApprovePersonalTradeFailure,
  [INIT_FINAL_APPROVE_PERSONAL_TRADE_ACTION_STATUS]: initFinalApprovePersonalTradeActionStatus,

  // Reject Personal Trade
  [START_REJECT_PERSONAL_TRADE]: startRejectPersonalTrade,
  [REJECT_PERSONAL_TRADE_SUCCESS]: rejectPersonalTradeSuccess,
  [REJECT_PERSONAL_TRADE_FAILURE]: rejectPersonalTradeFailure,
  [INIT_REJECT_PERSONAL_TRADE_ACTION_STATUS]: initRejectPersonalTradeActionStatus,

  // Feedback Personal Trade
  [START_FEEDBACK_PERSONAL_TRADE]: startFeedbackPersonalTrade,
  [FEEDBACK_PERSONAL_TRADE_SUCCESS]: feedbackPersonalTradeSuccess,
  [FEEDBACK_PERSONAL_TRADE_FAILURE]: feedbackPersonalTradeFailure,
  [INIT_FEEDBACK_PERSONAL_TRADE_ACTION_STATUS]: initFeedbackPersonalTradeActionStatus,
  
  // Open/Reset Personal Trade Operation Record Modal Dialog
  [PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_OPEN]: openPersonalTradeOperationRecordModalDialog,
  [PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_RESET]: resetPersonalTradeOperationRecordModalDialog,

  // Load Personal Trade Operation Record
  [START_LOAD_PERSONAL_TRADE_OPERATION_RECORD]: startLoadPersonalTradeOperationRecord,
  [LOAD_PERSONAL_TRADE_OPERATION_RECORD_SUCCESS]: loadPersonalTradeOperationRecordSuccess,
  [LOAD_PERSONAL_TRADE_OPERATION_RECORD_FAILURE]: loadPersonalTradeOperationRecordFailure,
  [INIT_LOAD_PERSONAL_TRADE_OPERATION_RECORD_ACTION_STATUS]: initLoadPersonalTradeOperationRecordActionStatus,

  // Open/Reset Personal Trade Request Edit Modal Dialog
  [PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_OPEN]: openPersonalTradeRequestEditModalDialog,
  [PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_RESET]: resetPersonalTradeRequestEditModalDialog,

  // Personal Trade Request Edit Change Input
  [PERSONAL_TRADE_REQUEST_EDIT_CHANGE_INPUT]: personalTradeRequestEditChangeInput,

  // Add Personal Trade Request
  [START_ADD_PERSONAL_TRADE_REQUEST]: startAddPersonalTradeRequest,
  [ADD_PERSONAL_TRADE_REQUEST_SUCCESS]: addPersonalTradeRequestSuccess,
  [ADD_PERSONAL_TRADE_REQUEST_FAILURE]: addPersonalTradeRequestFailure,
  [INIT_ADD_PERSONAL_TRADE_REQUEST_ACTION_STATUS]: initAddPersonalTradeRequestActionStatus,

  // Edit Personal Trade Request
  [START_EDIT_PERSONAL_TRADE_REQUEST]: startEditPersonalTradeRequest,
  [EDIT_PERSONAL_TRADE_REQUEST_SUCCESS]: editPersonalTradeRequestSuccess,
  [EDIT_PERSONAL_TRADE_REQUEST_FAILURE]: editPersonalTradeRequestFailure,
  [INIT_EDIT_PERSONAL_TRADE_REQUEST_ACTION_STATUS]: initEditPersonalTradeRequestActionStatus,

  // Batch Delete Personal Trade Request
  [START_BATCH_DELETE_PERSONAL_TRADE_REQUEST]: startBatchDeletePersonalTradeRequest,
  [BATCH_DELETE_PERSONAL_TRADE_REQUEST_SUCCESS]: batchDeletePersonalTradeRequestSuccess,
  [BATCH_DELETE_PERSONAL_TRADE_REQUEST_FAILURE]: batchDeletePersonalTradeRequestFailure,
  [INIT_BATCH_DELETE_PERSONAL_TRADE_REQUEST_ACTION_STATUS]: initBatchDeletePersonalTradeRequestActionStatus,

  // Copy Personal Trade Request
  [START_COPY_PERSONAL_TRADE_REQUEST]: startCopyPersonalTradeRequest,
  [COPY_PERSONAL_TRADE_REQUEST_SUCCESS]: copyPersonalTradeRequestSuccess,
  [COPY_PERSONAL_TRADE_REQUEST_FAILURE]: copyPersonalTradeRequestFailure,
  [INIT_COPY_PERSONAL_TRADE_REQUEST_ACTION_STATUS]: initCopyPersonalTradeRequestActionStatus,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,

  // Load BBG Tickers
  [START_LOAD_BBG_TICKERS]: startLoadBBGTickers,
  [LOAD_BBG_TICKERS_SUCCESS]: loadBbgTickersSuccess,
  [LOAD_BBG_TICKERS_FAILURE]: loadBbgTickersFailure,
  [INIT_LOAD_BBG_TICKERS_ACTION_STATUS]: initLoadBbgTickersActionStatus,

  // Clear bbgTickerArray bbgTickerOptions
  [CLEAR_BBG_TICKERS]: clearBbgTickers,

  // BBG Ticker Select Changed
  [BBG_TICKER_SELECT_CHANGED]: bbgTickerSelectChanged,

  // Load Personal Trade Field History
  [START_LOAD_TRADE_FIELD_HISTORY]: startLoadPersonalTradeFieldHistory,
  [LOAD_TRADE_FIELD_HISTORY_SUCCESS]: loadPersonalTradeFieldHistorySuccess,
  [LOAD_TRADE_FIELD_HISTORY_FAILURE]: loadPersonalTradeFieldHistoryFailure,
  [INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS]: initLoadPersonalTradeFieldHistoryActionStatus,

  // Load Exchange Code
  [START_LOAD_EXCHANGE_CODE]: startLoadExchangeCode,
  [LOAD_EXCHANGE_CODE_SUCCESS]: loadExchangeCodeSuccess,
  [LOAD_EXCHANGE_CODE_FAILURE]: loadExchangeCodeFailure,
  [INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS]: initLoadExchangeCodeActionStatus,

  // Clear exchageCodeArray exchageCodeOptions
  [CLEAR_EXCHANGE_CODES]: clearExchangeCodes,

  // Exchange Code Select Changed
  [EXCHANGE_CODE_SELECT_CHANGED]: exchangeCodeSelectChanged,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  // Open/Reset Personal Trade Request Recognize Modal Dialog
  [PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_OPEN]: openPersonalTradeRequestRecognizeModalDialog,
  [PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_RESET]: resetPersonalTradeRequestRecognizeModalDialog,

  // Recognize Personal Trade Request
  [START_RECOGNIZE_PERSONAL_TRADE_REQUEST]: startRecognizePersonalTradeRequest,
  [RECOGNIZE_PERSONAL_TRADE_REQUEST_SUCCESS]: recognizePersonalTradeRequestSuccess,
  [RECOGNIZE_PERSONAL_TRADE_REQUEST_FAILURE]: recognizePersonalTradeRequestFailure,
  [INIT_RECOGNIZE_PERSONAL_TRADE_REQUEST_ACTION_STATUS]: initRecognizePersonalTradeRequestActionStatus,

  // Open/Reset Personal Trade Request Operate Modal Dialog
  [PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_OPEN]: openPersonalTradeRequestOperateModalDialog,
  [PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_RESET]: resetPersonalTradeRequestOperateModalDialog,

  // Open/Reset Monthly Report Modal Dialog
  [MONTHLY_REPORT_MODAL_DIALOG_OPEN]: openMonthlyReportModalDialog,
  [MONTHLY_REPORT_MODAL_DIALOG_RESET]: resetMonthlyReportModalDialog,

  // Load Monthly Report
  [START_LOAD_MONTHLY_REPORT]: startLoadMonthlyReport,
  [LOAD_MONTHLY_REPORT_SUCCESS]: loadMonthlyReportSuccess,
  [LOAD_MONTHLY_REPORT_FAILURE]: loadMonthlyReportFailure,
  [INIT_LOAD_MONTHLY_REPORT_ACTION_STATUS]: initLoadMonthlyReportActionStatus,

  // Send PA Dealing Apply Monthly Report Email
  [START_SEND_PA_DEALING_APPLY_REPORT_EMAIL]: startSendPADealingApplyMonthlyReportEmail,
  [SEND_PA_DEALING_APPLY_REPORT_EMAIL_SUCCESS]: sendPADealingApplyMonthlyReportEmailSuccess,
  [SEND_PA_DEALING_APPLY_REPORT_EMAIL_FAILURE]: sendPADealingApplyMonthlyReportEmailFailure,
  [INIT_SEND_PA_DEALING_APPLY_REPORT_EMAIL_ACTION_STATUS]: initSendPADealingApplyMonthlyReportEmailActionStatus,


  // 30天持仓相关预检查
  [START_QUERY_30_DAY_HOLDING_CHECK_INFO]: startQuery30DayHoldingCheckInfo,
  [QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS]: query30DayHoldingCheckInfoSuccess,
  [QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE]: query30DayHoldingCheckInfoFailure,
  [RESET_30_DAY_HOLDING_CHECK_INFO]: reset30DayHoldingCheckInfo,
  [START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS]: startTrigger30DayHoldingCheckInfoStatus,
  [STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS]: stopTrigger30DayHoldingCheckInfoStatus,

});
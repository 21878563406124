import { httpClient } from "../../../../net/httpClient";
import UrlConfig from "../../../../net/UrlConfig";

import {
  // query
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_BROKER_REVIEW_INFO,
  LOAD_BROKER_REVIEW_INFO_SUCCESS,
  LOAD_BROKER_REVIEW_INFO_FAILURE,
  INIT_LOAD_BROKER_REVIEW_INFO_ACTION_STATUS,
  // select
  SELECT_BROKER_REVIEW_INFO,
  // add or edit
  OPEN_ADD_OR_EDIT_BROKER_REVIEW_MODAL,
  ADD_OR_EDIT_BROKER_REVIEW_CHANGE_INPUT,
  START_ADD_OR_EDIT_BROKER_REVIEW,
  ADD_OR_EDIT_BROKER_REVIEW_SUCCESS,
  ADD_OR_EDIT_BROKER_REVIEW_FAILURE,
  INIT_ADD_OR_EDIT_BROKER_REVIEW_ACTION_STATUS,
  RESET_ADD_OR_EDIT_BROKER_REVIEW_MODAL,
  // refresh fx and amtUSD
  START_REFRESH_FX,
  REFRESH_FX_SUCCESS,
  REFRESH_FX_FAILURE,
  INIT_REFRESH_FX_ACTION_STATUS,
  REFRESH_AMT_USD,
  // del
  OPEN_DEL_BROKER_REVIEW_MODAL,
  DEL_BROKER_REVIEW_CHANGE_INPUT,
  START_DEL_BROKER_REVIEW,
  DEL_BROKER_REVIEW_SUCCESS,
  DEL_BROKER_REVIEW_FAILURE,
  INIT_DEL_BROKER_REVIEW_ACTION_STATUS,
  RESET_DEL_BROKER_REVIEW_MODAL,

  // COP
  OPEN_COP_BROKER_REVIEW_MODAL,
  COP_BROKER_REVIEW_CHANGE_INPUT,
  START_COP_BROKER_REVIEW,
  COP_BROKER_REVIEW_SUCCESS,
  COP_BROKER_REVIEW_FAILURE,
  INIT_COP_BROKER_REVIEW_ACTION_STATUS,
  RESET_COP_BROKER_REVIEW_MODAL,
  OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  RESTRICTED_RULE_INPUT_CHANGE,
  START_LOAD_BROKER_REVIEW_INDIVIDUAL,
  LOAD_BROKER_REVIEW_INDIVIDUAL_SUCCESS,
  LOAD_BROKER_REVIEW_INDIVIDUAL_FAILURE,
  START_LOAD_BROKER_REVIEW_PURPOSE,
  LOAD_BROKER_REVIEW_PURPOSE_SUCCESS,
  LOAD_BROKER_REVIEW_PURPOSE_FAILURE,
  LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
  LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
} from "./BrokerReviewConstants";

// query
export function onQueryInputChange({ name, value }) {
  return {
    type: ON_QUERY_INPUT_CHANGE,
    payload: {
      name,
      value,
    },
  };
}
function startLoadBrokerReviewInfo() {
  return {
    type: START_LOAD_BROKER_REVIEW_INFO,
  };
}
export function loadBrokerReviewInfo(params) {
  return function (dispatch) {
    dispatch(startLoadBrokerReviewInfo());
    httpClient
      .post(UrlConfig.LOAD_BROKER_REVIEW_INFO, params)
      .then((resp) => dispatch(loadBrokerReviewInfoSuccess(resp)))
      .catch((err) => dispatch(loadBrokerReviewInfoFailure(err)));
  };
}
function loadBrokerReviewInfoSuccess(resp) {
  return {
    type: LOAD_BROKER_REVIEW_INFO_SUCCESS,
    payload: resp,
  };
}
function loadBrokerReviewInfoFailure(err) {
  return {
    type: LOAD_BROKER_REVIEW_INFO_FAILURE,
    payload: err,
  };
}
export function initLoadBrokerReviewInfoActionStatus() {
  return {
    type: INIT_LOAD_BROKER_REVIEW_INFO_ACTION_STATUS,
  };
}

// select
export function selectBrokerReviewInfo(array) {
  return {
    type: SELECT_BROKER_REVIEW_INFO,
    payload: array,
  };
}

// add or edit
export function openAddOrEditBrokerReviewModal(mode, data) {
  return {
    type: OPEN_ADD_OR_EDIT_BROKER_REVIEW_MODAL,
    payload: {
      mode,
      data,
    },
  };
}
export function addOrEditBrokerReviewChangeInput({ name, value }) {
  return {
    type: ADD_OR_EDIT_BROKER_REVIEW_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startAddOrEditBrokerReview() {
  return {
    type: START_ADD_OR_EDIT_BROKER_REVIEW,
  };
}
export function addOrEditBrokerReview(params) {
  return function (dispatch) {
    dispatch(startAddOrEditBrokerReview());
    httpClient
      .post(UrlConfig.ADD_OR_EDIT_BROKER_REVIEW, params)
      .then((resp) => dispatch(addOrEditBrokerReviewSuccess(resp)))
      .catch((err) => dispatch(addOrEditBrokerReviewFailure(err)));
  };
}
function addOrEditBrokerReviewSuccess(resp) {
  return {
    type: ADD_OR_EDIT_BROKER_REVIEW_SUCCESS,
    payload: resp,
  };
}
function addOrEditBrokerReviewFailure(err) {
  return {
    type: ADD_OR_EDIT_BROKER_REVIEW_FAILURE,
    payload: err,
  };
}
export function initAddOrEditBrokerReviewActionStatus() {
  return {
    type: INIT_ADD_OR_EDIT_BROKER_REVIEW_ACTION_STATUS,
  };
}
export function resetAddOrEditBrokerReviewModal() {
  return {
    type: RESET_ADD_OR_EDIT_BROKER_REVIEW_MODAL,
  };
}

// refresh fx and amtUSD
function startRefreshFx() {
  return {
    type: START_REFRESH_FX,
  };
}
export function refreshFx({ currInvDate, currCcy }) {
  if (!currInvDate || !currCcy) {
    return function (dispatch) {
      dispatch(refreshFxSuccess(null));
    };
  } else {
    const params = {
      fromCurrencyCode: "USD",
      toCurrencyCode: currCcy,
      specifiedDateStr: currInvDate,
    };
    return function (dispatch) {
      dispatch(startRefreshFx());
      httpClient
        .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
        .then((resp) => dispatch(refreshFxSuccess(resp)))
        .catch((err) => dispatch(refreshFxFailure(err)));
    };
  }
}
function refreshFxSuccess(resp) {
  return {
    type: REFRESH_FX_SUCCESS,
    payload: resp,
  };
}
function refreshFxFailure(err) {
  return {
    type: REFRESH_FX_FAILURE,
    payload: err,
  };
}
export function initRefreshFxActionStatus() {
  return {
    type: INIT_REFRESH_FX_ACTION_STATUS,
  };
}
export function refreshAmtUSD({ currAmount, currFx }) {
  let amtUSD = "";
  if (!!currAmount && !!currFx) {
    amtUSD = currAmount / currFx;
    amtUSD = Math.round(amtUSD * 100) / 100;
  }
  if (isNaN(amtUSD)) {
    amtUSD = "";
  }
  return {
    type: REFRESH_AMT_USD,
    payload: amtUSD,
  };
}

// del
export function openDelBrokerReviewModal(info, mode, idList) {
  return {
    type: OPEN_DEL_BROKER_REVIEW_MODAL,
    payload: {
      info,
      mode,
      idList,
    },
  };
}
export function delBrokerReviewChangeInput({ name, value }) {
  return {
    type: DEL_BROKER_REVIEW_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startDelBrokerReview() {
  return {
    type: START_DEL_BROKER_REVIEW,
  };
}
export function delBrokerReview(params) {
  return function (dispatch) {
    dispatch(startDelBrokerReview());
    httpClient
      .get(UrlConfig.DEL_BROKER_REVIEW, params)
      .then((resp) => dispatch(delBrokerReviewSuccess(resp)))
      .catch((err) => dispatch(delBrokerReviewFailure(err)));
  };
}
function delBrokerReviewSuccess(resp) {
  return {
    type: DEL_BROKER_REVIEW_SUCCESS,
    payload: resp,
  };
}
function delBrokerReviewFailure(err) {
  return {
    type: DEL_BROKER_REVIEW_FAILURE,
    payload: err,
  };
}
export function initDelBrokerReviewActionStatus() {
  return {
    type: INIT_DEL_BROKER_REVIEW_ACTION_STATUS,
  };
}
export function resetDelBrokerReviewModal() {
  return {
    type: RESET_DEL_BROKER_REVIEW_MODAL,
  };
}

//cop
export function openCopBrokerReviewModal(info) {
  return {
    type: OPEN_COP_BROKER_REVIEW_MODAL,
    payload: info,
  };
}
export function copBrokerReviewChangeInput({ name, value }) {
  return {
    type: COP_BROKER_REVIEW_CHANGE_INPUT,
    payload: {
      name,
      value,
    },
  };
}
function startCopBrokerReview() {
  return {
    type: START_COP_BROKER_REVIEW,
  };
}
export function copBrokerReview(params) {
  return function (dispatch) {
    dispatch(startCopBrokerReview());
    httpClient
      .get(UrlConfig.COP_ALL_BROKER_REVIEW, params)
      .then((resp) => dispatch(copBrokerReviewSuccess(resp)))
      .catch((err) => dispatch(copBrokerReviewFailure(err)));
  };
}
function copBrokerReviewSuccess(resp) {
  return {
    type: COP_BROKER_REVIEW_SUCCESS,
    payload: resp,
  };
}
function copBrokerReviewFailure(err) {
  return {
    type: COP_BROKER_REVIEW_FAILURE,
    payload: err,
  };
}
export function initCopBrokerReviewActionStatus() {
  return {
    type: INIT_COP_BROKER_REVIEW_ACTION_STATUS,
  };
}
export function resetCopBrokerReviewModal() {
  return {
    type: RESET_COP_BROKER_REVIEW_MODAL,
  };
}

export function openRestrictedRuleFundBookDialog() {
  return {
    type: OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  };
}

export function closeRestrictedRuleFundBookDialog() {
  return {
    type: CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  };
}
export function restrictedRuleEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_RULE_INPUT_CHANGE,
    payload: {
      name,
      value,
    },
  };
}

function startLoadBrokerReviewIndividual() {
    return {
      type: START_LOAD_BROKER_REVIEW_INDIVIDUAL,
    };
  }

export function loadBrokerReviewIndividualList(params) {
  return function (dispatch) {
    dispatch(startLoadBrokerReviewIndividual());
    httpClient
      .post(UrlConfig.LOAD_BROKER_REVIEW_SUMMARY_INFO, params)
      .then((resp) => dispatch(loadBrokerReviewIndividualListSuccess(resp)))
      .catch((err) => dispatch(loadBrokerReviewIndividualListFailure(err)));
  };
}
function loadBrokerReviewIndividualListSuccess(resp) {
  return {
    type: LOAD_BROKER_REVIEW_INDIVIDUAL_SUCCESS,
    payload: resp,
  };
}
function loadBrokerReviewIndividualListFailure(err) {
  return {
    type: LOAD_BROKER_REVIEW_INDIVIDUAL_FAILURE,
    payload: err,
  };
}

function startLoadBrokerReviewPurpose() {
    return {
      type: START_LOAD_BROKER_REVIEW_PURPOSE,
    };
  }


export function loadBrokerReviewPurposeList(params) {
  return function (dispatch) {
    dispatch(startLoadBrokerReviewPurpose());
    httpClient
      .post(UrlConfig.LOAD_BROKER_REVIEW_SUMMARY_INFO, params)
      .then((resp) => dispatch(loadBrokerReviewPurposeListSuccess(resp)))
      .catch((err) => dispatch(loadBrokerReviewPurposeListFailure(err)));
  };
}
function loadBrokerReviewPurposeListSuccess(resp) {
  return {
    type: LOAD_BROKER_REVIEW_PURPOSE_SUCCESS,
    payload: resp,
  };
}
function loadBrokerReviewPurposeListFailure(err) {
  return {
    type: LOAD_BROKER_REVIEW_PURPOSE_FAILURE,
    payload: err,
  };
}

export function loadInstituteUserAccounts(params) {
  return function (dispatch) {
    httpClient
      .get(UrlConfig.QUERY_INSTITUTE_USER_ACCOUNTS, params)
      .then((resp) => dispatch(loadInstituteUserAccountsSuccess(resp)))
      .catch((err) => dispatch(loadInstituteUserAccountsFailure(err)));
  };
}
function loadInstituteUserAccountsSuccess(resp) {
  return {
    type: LOAD_INSTITUTE_USER_ACCOUNT_SUCCESS,
    payload: resp,
  };
}
function loadInstituteUserAccountsFailure(err) {
  return {
    type: LOAD_INSTITUTE_USER_ACCOUNT_FAILURE,
    payload: err,
  };
}

import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload,InputNumber, Icon,Checkbox ,Tag,Progress  } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../../net/UrlConfig';

class MeetingAndTrainingGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
            // {
            //     field: 'id',
            //     headerName: "ID",
            //     cellClass: 'non-number',
            //     width: 80,
            //     cellRenderer: 'agGroupCellRenderer',
            //     // headerCheckboxSelection: true,
            //     // headerCheckboxSelectionFilteredOnly: true,
            //     // checkboxSelection: true
            // },
            {
                field: 'title',
                headerName: "Title*",
                cellClass: 'non-number',
                width: 300,
                cellRenderer: 'agGroupCellRenderer',
            },
            {
                field: 'starttime',
                headerName: "Start Time",
                cellClass: 'non-number',
                width: 120,
            },
            {
                field: 'endtime',
                headerName: "End Time",
                cellClass: 'non-number',
                width: 120,
            },
            {
                field: 'type',
                headerName: "Type",
                cellClass: 'non-number',
                width: 200,
            },


            // {
            //     headerName: "进度",
               
            //     cellRenderer: (node) => {
            //         let content = '';
            //         // text = Math.random() * 100;
            //         // const width = '120px';
            //         // const processWidth = `${(text / 100) * 120}px`;
            //         // const processColor = text > 60 ? text > 90 ? "red" : "orange" : "lime";
            //         // content += "<div style={{'fontSize': '9px', position: 'relative', width: width}}>";
            //         // content += "   <Tag color='geekblue' style={";
            //         // content += "     {";
            //         // content += "                 position: 'relative',";
            //         // content += "                   width: '120px',";
            //         // content += "                   padding: '0px',";
            //         // content += "                   textAlign: 'center'";
            //         // content += "               }}>";
            //         // content += "                  <div style={";
            //         // content += "     {";
            //         // content += "                      position: 'absolute',";
            //         // // content += "                      backgroundColor: `${processColor}`,";
            //         // content += "                      backgroundColor: orange,";
            //         // content += "                      height: '20px',";
            //         // content += "                      opacity: 0.3,";
            //         // // content += "                     width: processWidth
            //         // content += "                     width: 50";
            //         // content += "                  }}></div>";
            //         // // content += "                  <span className='marginLR5'>{(text ? text.toFixed(2) : 0) + '%'}</span></Tag></div> ";
            //         // content += "                  <span className='marginLR5'>{50+ '%'}</span></Tag></div> ";




            //         // content += '  <Progress />';
            //         return content;
            //     }
                
            // },

            {
                field: 'category',
                headerName: "Category",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'comperename',
                headerName: "Coach",
                cellClass: 'non-number',
                width: 150,
            },
            
            {
                field: 'desc',
                headerName: "Desc*",
                cellClass: 'non-number',
                width: 320,
            },
            {
                field: 'requiredattendees',
                headerName: "required attendees",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'optionalattendees',
                headerName: "optional attendees",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'score',
                headerName: "Credit",
                cellClass: 'non-number',
                width: 75,
            },
            {
                field: 'occurence',
                headerName: "Total Occurence",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'progress',
                headerName: "Progress(%)",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'isnotice',
                headerName: "Email notification",
                cellClass: 'non-number',
                width: 150,
                cellStyle: this.dataColorCellStyle,
            },


            {
                field: 'speaker',
                headerName: "Speaker",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'broker',
                headerName: "Broker",
                cellClass: 'non-number',
                width: 150,
            },



            {
                field: 'state',
                headerName: "State",
                cellClass: 'non-number',
                width: 100,
            },

        ],
        defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 50
        },
            rowSelection: "multiple",
            context: { componentParent: this },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            rowClassRules: {
                'MarkBackgroundGreen': function(params) {
                    // if(params.data.status === 'New') {
                        return params.data.state === 'New';
                    // } alertFlag  limitFlag  1 表示出警

                },
                'MarkBackgroundYellow': function(params) {
                    // if(params.data.status === 'New') {
                        return params.data.state === 'Add';
                    // } alertFlag  limitFlag  1 表示出警

                },
                
                
                
            },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                        {field: 'title', headerName: 'title', cellClass: 'non-number', width: 150},
                        {field: 'starttime', headerName: 'Start Time', cellClass: 'non-number', width: 150},
                        {field: 'endtime', headerName: 'End Time', cellClass: 'non-number', width: 150},
                        {field: 'state', headerName: 'State', cellClass: 'non-number', width: 120},
                        //{field: 'targetdate', headerName: 'Target Date', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                        // {field: 'score', headerName: 'Credit', cellClass: 'non-number', width: 100},
                        // {field: 'enddate', headerName: 'End Date', cellClass: 'non-number', width: 250},
                        // {field: 'personlncharge', headerName: 'PersonInCharge', cellClass: 'non-number', width: 150},
                        // {field: 'status', headerName: 'Status', cellClass: 'non-number', width: 120},
                        // {field: 'remarks', headerName: 'remarks', cellClass: 'non-number', width: 200},
                        // {field: 'comments', headerName: 'comments', cellClass: 'non-number', width: 200},
                        // {field: 'modifiedTime', headerName: 'Last update date', cellClass: 'non-number', width: 150},
                        // {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectMeetingAndTrainingInfo(cptRcdDataArray);
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.detialList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;"> M&T Detail</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },
        };
    }
    meetingAndTrainingDownloadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL
    downloadMeetingAndTrainingCellRenderer = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }

        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;

    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridApi.closeToolPanel();
        // this.gridApi.sizeColumnsToFit();
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let meetingAndTrainingInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectMeetingAndTrainingInfo(meetingAndTrainingInfoArray);
    }
    dataColorCellStyle(param) {
        const status = param.value;
        if(status === 'false'|| status===null) {
            // return {color: 'green'};
            return { background: '#fcf800 '};
        }
    }
    render() {

        const {
            // query
            loadMeetingAndTrainingInfoActionStatus,
            loadMeetingAndTrainingInfoResp,
        } = this.props;


        const isLoading = loadMeetingAndTrainingInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadMeetingAndTrainingInfoArray = [];
        if(!!loadMeetingAndTrainingInfoResp && !!loadMeetingAndTrainingInfoResp.data) {
            loadMeetingAndTrainingInfoArray = loadMeetingAndTrainingInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadMeetingAndTrainingInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            sideBar={true}
                            resizable={true}
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onDataSelectionChanged}
                        />
                    )}
            </div>
            
        );
    }
}

export default MeetingAndTrainingGrid;
